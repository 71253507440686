<template>
  <div class="login-page auth-page__holder">
    <!-- <header class="auth-page__header">
      <div class="container">
        <div class="flex flex--between flex--v-center">
          <router-link :to="logoLink" class="main-nav__logo-holder-mobile" area-label="logo">
              <logo color="dark"/>
          </router-link>
          <div v-if="lead === 'alte-leipziger'" >
            <span class="mobile-hide">{{ $t('register.not_have_an_account') }} </span>
            <router-link to="/register?lead=alte-leipziger"
                          class="link">{{ $t('register.register') }}
            </router-link>
          </div>
        </div>
      </div>
    </header> -->

    <div v-if="lead === 'alte-leipziger'" class="auth-page__logo">
      <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/ALH1.png">
    </div>
    <div v-else class="login-page__logo">
      <a :href="clientLink" target="_blank">
        <img class="cd-logo" src="@/assets/images/CyberDirekt_claim-white.svg"/>
      </a>
    </div>

    <div class="container login-container">
      <form action="#"
            novalidate
            @submit.prevent="logIn"
            class="login-form">
            
        <div class="login-top">
          <ButtonComponent
          btnStyle="link-accent"
          behavior="button"
          :icon="{name: 'arrow-left', width: 24}"
          @click.native="historyGoBack"
          type="button"
          >Zurück</ButtonComponent
        >
        </div>

        <div class="auth-page__row" :class="{ 'has-error': $v.form.email.$error }">
          <label class="input__holder form-group form-group--icon-l">
            <uiIcon name="mail" width="20" class="icon icon-l"></uiIcon>
            <b-form-input type="email" name="email" class="input" v-model="form.email" :placeholder="$t('common.email')" required></b-form-input>
          </label>
        </div>

        <div class="auth-page__row" :class="{ 'has-error': $v.form.password.$error }">
          <label class="input__holder form-group form-group--icon-l form-group--icon-r">
            <uiIcon name="lock" width="20" class="icon icon-l"></uiIcon>
            <b-form-input :type="!showPass ? 'password' : 'text' " id="password" class="form-trol" v-model="form.password" placeholder="Passwort" required></b-form-input>
            <uiIcon name="eye" width="17" class="icon icon-r" :class="{'icon-primary': showPass}" @click.native="() => {showPass = !showPass}"></uiIcon>
          </label>
        </div>
        
        <div class="auth-page__buttons-holder">
          <ButtonComponent
            btnStyle="accent"
            behavior="button"
            class="submit-btn"
            :class="{ 'btn--pending': logInPending }"
            type="submit"
            :disabled="$v.form.$error || logInPending"
            >
            <icon name="sync"
                  class="btn__pending-icom"
                  color="white"
                  :rotate="logInPending"></icon>
            {{ $t('common.sign_in') }}
          </ButtonComponent>
        </div>
        <div class="auth-page__forgot-password">
          
          <ButtonComponent
            btnStyle="link-primary"
            behavior="internalLink"
            link="/reset-password"
            type="button"
            @click.native.prevent="showForgotForm"
            >{{ $t('common.forgot_password') }}?</ButtonComponent>
        </div>
      </form>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import Logo from '@/components/logo/Logo.vue';
  import Icon from '@/components/icon/Icon.vue';
  import MainFooter from '@/components/footer/MainFooter.vue';
  import { COMPANY_NAME } from '@/constants';
  import customLogoLink from '../helpers/customLogoLink'
  import ButtonComponent from "@/components/ui/ButtonComponent.vue";
  import uiIcon from '@/components/ui/Icon'

  export default {
    name: 'login',
    components: {
      Logo,
      Icon,
      MainFooter,
      ButtonComponent,
      uiIcon
    },
    created() {
      if(this.$route.query.lead && this.$route.query.lead === 'alte-leipziger') {
        this.lead = this.$route.query.lead
      }
    },
    mounted() {
      this.forceGerman();
    },
    data() {
      return {
        lead: null,
        form: {
          email: '',
          password: '',
        },
        title: 'page.login_title',
        companyName: COMPANY_NAME,
        logInPending: false,        
        showPass: false
      };
    },
     computed: {
      logoLink () {
        return customLogoLink()
      },

      clientLink () {
        return process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE
      }
    },
    metaInfo() {
      return {
        title: this.$t(this.title, { name: COMPANY_NAME }),
      }
    },
    validations: {
      form: {
        email: { required },
        password: { required },
      }
    },
    methods: {
      async forceGerman() {
        await this.$store.dispatch('CHANGE_LANGUAGE', 'de');
      },
      async logIn() {
        this.$v.form.$touch();

        if (this.$v.form.$error || this.logInPending) return;
        this.$v.form.$reset();
        this.logInPending = true;

        const { success } = await this.$store.dispatch('LOG_IN', this.form);
        
        await this.$store.dispatch('GET_INITIAL_DATA');
        
        this.logInPending = false;

        if (success) {
          if (this.$route.query.next) {
            this.$router.push(this.$route.query.next).catch((err) => {
              console.error('router err: ', err)
            })
          } else {
            this.$router.push('/');
          }
        } else {
          this.$toasted.show(this.$t('login.failed'), { type: 'error' });
        }
      },
      showForgotForm() {
      },

      historyGoBack() {
        window?.history.go(-1)
      }
    },
    watch: { 
     '$route.query.lead': {
        handler: function(val) {
          if ( val === 'alte-leipziger' && !this.lead) {
             this.lead = 'alte-leipziger'
           } else {
             this.lead = null;
           }
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/form.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.auth-page__holder {
  display: flex;
  flex-direction: column;
}

.cd-logo {
  margin-bottom: 3.5rem;
}

.login-page {
  background: #15192d;
  padding-top: 68px;

  &__logo {
    max-width: 420px;
    margin: 0 auto;
  }

  .login-container {
    max-width: 478px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}


.login-form {
  background: #fff;
  border-radius: 0.5rem;
  color: #454545;
  padding: 3rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }

  .login-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;

    ::v-deep button {
      svg {
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;

    &--icon-l {
      position: relative;

      .icon-l {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;

        ::v-deep svg {
          color: $orangeTone;
        }
      }

      .form-control {
        padding-left: 2.75rem;
      }
    }

    &--icon-r {
      position: relative;

      .icon-r {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        ::v-deep svg {
          color: #454545;
        }
      }

      .form-control {
        padding-right: 2.75rem;
      }
    }

    .icon-primary {
      ::v-deep svg {
        color: $primary;
      }
    }
  }

  .form-control {
    border: 1px solid #ced9dfe5;
    background: #CED9DF33;
    height: 40px;
    font-size:  0.9rem;
  }

  .input-group-text {
    background: #CED9DF33;
    border-right: none;
    padding-right: 0;
  }

  .input-group-prepend {
    margin-right: 0;

    & + .form-control {
      border-left: none;
    }
  }

  .submit-btn {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: 100%;
  }

  &__bottom-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.has-error {
  .form-control {
    border: 2px solid $red;
  }
}

.b-footer-2 {
  margin-top: auto;
}
</style>
