<template>
  <step-broker :step="step" v-if="isBroker"></step-broker>
  <step-common :step="step" v-else></step-common>
</template>

<script>
  import StepBroker from './Step1b-Broker.vue';
  import StepCommon from './Step1b-Common.vue';
  import isStepAllowed from '../mixins/isStepAllowed.js'

  export default {
    name: 'Step2',
    components: {
      StepBroker,
      StepCommon
    },
    data() {
      return {
        selectedRevenueIndex: 0,
        contactFormData: {
          name: '',
          phone: '',
          email: '',
          legalCheck: false
        },
        modalSuccess: false,
        showLeaveModal: false
      }
    },
    computed: {
      isBroker() {
        return this.$store.getters.isBroker;
      }
    },
    props: ['step'],
    mixins: [isStepAllowed]
  }
</script>

