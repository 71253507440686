<template>
  <div class="contract-data" :class="{'is-offer': isOffer}">
    <div class="row gap-md mb-4"> 
      <div :class="{'col-12 col-lg-4 mb-3 mb-lg-0': isOffer , 'col-12 col-lg-5 mb-3 mb-lg-0': !isOffer}">
        <h2>Firmendaten</h2>
        <p>{{ companyDataText }}</p>
      </div>
      <div :class="{'col-12 col-lg-8': isOffer, 'col-12 col-lg-7': !isOffer}">
        <div class="row gap-md form-row ">
          <div
            class="cd-control form-group col-12 col-sm-6"
            :class="[getFieldClass('companyName'), {'is-danger': inputs.insurer && inputs.insurer.includes('victor') && inputs.company.length > 60}]">
            <label for="companyName" class="cd-label">Vollst&auml;ndiger Firmenname </label><label v-if="!isOffer">*</label>
            <input
              class="form-control cd-input"
              type="text"
              id="companyName"
              name="companyName"
              v-validate="{ required: !isOffer}"
              v-model="inputs.company"
              placeholder="Muster GmbH &amp; Co. KG"
            />
          </div>
        </div>
        <div class="row form-row mb-4 mb-md-5 two-liner gap-md">
          <div class="cd-control form-group col-12 col-md-6">
            <label for="industry">Branche / Berufsgruppe</label>
            <input class="form-control cd-input" type="text" id="industry" v-model="inputs.industry.l" readonly />
          </div>
          <div class="cd-control col-12 col-md-6 mt-3 mt-md-0" v-if="!isLegal">
            <label for="Nettoumsatz">Nettoumsatz</label>
            <input class="form-control cd-input" type="text" id="Nettoumsatz" :value="inputs.netTurnOver | currency(0)" disabled />
          </div>
        </div>
        <div class="row mb-2 gap-md">
          <div class="col-12">
            <h3>Firmenadresse</h3>
          </div>
        </div>
        <div class="row form-row gap-md">
          <div class="form-group cd-control col-12" :class="getFieldClass('street')">
            <label for="street">Straße (inkl. Hausnummer) </label><label v-if="!isOffer">*</label>
            <input
              class="form-control cd-input"
              type="text"
              id="street"
              name="street"
              v-validate="{ required: !isOffer }"
              v-model="inputs.street"
              placeholder="Musterstrasse 1"
            />
          </div>
        </div>
        <div class="row form-row gap-md">
          <div class="cd-control form-group col-12 col-sm-6" :class="getFieldClass('postal')">
            <label for="postal">PLZ </label><label v-if="!isOffer">*</label>
            <input
              class="form-control cd-input"
              type="text"
              id="postal"
              name="postal"
              v-validate="postalValidation()"
              v-model="inputs.postal"
              @beforeinput="preventInputHandler($event, {numeric: true})"
              :placeholder="postalPlaceholder()"
            />
          </div>
          <div class="cd-control col-12 col-sm-6 mt-3 mt-sm-0" :class="getFieldClass('city')">
            <label for="city">Stadt </label><label v-if="!isOffer">*</label>
            <input
              class="cd-input form-control"
              type="text"
              id="city"
              name="city"
              v-validate="{ required: !isOffer }"
              v-model="inputs.city"
              :placeholder="cityPlaceholder()"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-divider d-print-none"></div>
    <div class="row gap-md">
      <div :class="{'col-12 col-lg-4 mb-1 mb-lg-0': isOffer, 'col-12 col-lg-5 mb-1 mb-lg-0': !isOffer}">
        <h2>Angaben des Zeichnungsberechtigten</h2>
        <p>Bitte geben Sie die Person an, die Hauptentscheider und Ansprechpartner für diesen Vertrag ist.</p>
      </div>
      <div :class="{'col-12 col-lg-8': isOffer, 'col-12 col-lg-7': !isOffer}">
        <div class="row form-row gap-md">
          <div class="cd-control form-group col-12 col-sm-6 ">
            <label for="title">Titel (optional)</label>
            <div class="fields-bucket">
              <input class="cd-input form-control" type="text" id="title" name="title" v-model="inputs.title" placeholder="Dr., Prof., ..." />
            </div>
          </div>
        </div>
        <div class="row form-row gap-md">
          <div class="cd-control form-group col-12 col-sm-6 " :class="getFieldClass('salutation')">
            <label for="salutation">Anrede </label><label v-if="!isOffer">*</label>
            <SelectComponent class="form-control cd-input select-border" name="salutation" id="salutation" v-model="inputs.salutation" v-validate="{ required: !isOffer }">
              <option selected disabled hidden value="">Wählen</option>
              <option value="Frau">Frau</option>
              <option value="Herr">Herr</option>
            </SelectComponent>
          </div>
        </div>
        <div class="row form-row gap-md">
          <div class="cd-control form-group col-12 col-sm-6 " :class="getFieldClass('firstname')">
            <label for="firstname">Vorname </label><label v-if="!isOffer">*</label>
            <input
              class="form-control cd-input"
              type="text"
              id="firstname"
              name="firstname"
              v-validate="{ required: !isOffer, min:'1'}"
              v-model="inputs.firstname"
              placeholder="Ihr Vorname"
            />
          </div>
          <div class="cd-control col-12 col-sm-6 mt-3 mt-sm-0" :class="getFieldClass('lastname')">
            <label for="lastname">Nachname </label><label v-if="!isOffer">*</label>
            <input
              class="form-control cd-input"
              type="text"
              id="lastname"
              name="lastname"
              v-validate="{ required: !isOffer, min:'1'}"
              v-model="inputs.lastname"
              placeholder="Ihr Nachname"
            />
          </div>
        </div>
        <div class="row form-row gap-md">
          <div class="cd-control form-group col-12 col-md-6 " :class="getFieldClass('email')">
            <label for="email">E-Mail </label><label v-if="!isOffer">*</label>
            <input
              class="form-control cd-input"
              type="email"
              id="email"
              name="email"
              v-validate="{ required: !isOffer, min:'1'}"
              v-model="inputs.email"
              :placeholder="emailPlaceholder()"
            />
            <div v-if="isBroker" class="mt-2">
              <small
              >Die korrekte E-Mail-Adresse wird zwingend f&uuml;r die Erstellung des Kundenkontos ben&ouml;tigt
              </small>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0">
            <PhoneForm
                v-model='inputs.phone'
                @numberChange="updatePhoneNumber"
                :required="isPhoneRequired"
                :input-classes="['cd-input']"
                class="cd-control"
              />
          </div>
        </div>
        <div class="row gap-md" v-if="isBroker && !isOffer">
          <div class="col-12">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="notificationCustomer" v-model="inputs.notification.customer" />
              <label class="custom-control-label text-muted check-label" for="notificationCustomer">
                Antragsunterlagen an den Kunden senden
              </label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="notificationBroker" v-model="inputs.notification.broker" />
              <label class="custom-control-label text-muted check-label" for="notificationBroker">
                Antragsunterlagen an mich versenden
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StringHelper from '../StringHelper'
import preventInput from '@/mixins/validation/preventInput'
import { mapGetters } from 'vuex'
import PhoneForm from './shared/PhoneForm.vue'
import SelectComponent from './calculator-v2.0/ui/SelectComponent.vue'

export default {
  name: 'ContractDataForm',
  inject: ['$validator'],
  data(){
    return{
      inputs:  {
        firstname:'',
        lastname:'',
        title:'',
        salutation:'',
        email:'',
        phone:'',
        street:'',
        city:'',
        postal:'',
        company:'',
        netTurnOver:'',
        industry:{
          l:''
        },
        notification:{
          customer: ''
        }
      }
    }
  },
  components:{
    PhoneForm,
    SelectComponent
  },
  mixins: [preventInput],
  props:{
    isOffer:{
      type: Boolean,
      default: false,
      required: false
    },
    isPhoneRequired: {
      type: Boolean,
      default: true,
      required: false
    },
    isCompanyMax60Alerted: {
      type: Boolean,
      default: false
    },
    insurer: {
      type: Object
    }
  },
  created(){
    // pause the validation for offer view as the fields arent mandatory there
    if(this.isOffer){
      this.inputs.firstname = this.offer.firstname;
      this.inputs.lastname = this.offer.lastname;
      this.inputs.title = this.offer.title;
      this.inputs.salutation = this.offer.salutation;
      this.inputs.email = this.offer.email;
      this.inputs.phone = this.offer.phone;
      this.inputs.street = this.offer.street;
      this.inputs.city = this.offer.city;
      this.inputs.postal = this.offer.postal;
      this.inputs.company = this.offer.company;
      this.inputs.netTurnOver = this.offer.netTurnOver;
      this.inputs.industry.l = this.offer.industry.l;
      this.inputs.insurer = this.offer.insurer
  } else {
      this.inputs = this.$store.state.checkout.application.inputs;
  }

  },
  computed: {
    ...mapGetters(['isBroker', 'offer']),
    companyDataText() {
      return StringHelper.get(this.$store, 'STEP_4_COMPANY_DATA')
    },
    isLegal() {
      return this.$route.path.includes('rechtsschutzversicherung')
    },
    countryInterface() {
      return this.$store.state.checkout.application.countryInterface.value
    },
  },
  watch: {
    inputs: {
      handler(val){
        if(this.isOffer) {
          this.$emit('customerDataChange', val)
        }
      }, deep: true
    },
    'inputs.company'(newValue) {
      this.insurer && this.insurer.key.includes('victor') && !this.isCompanyMax60Alerted && newValue.length > 60 ? this.$emit('companyMax60', 'company') : ''
    }
  } ,
  methods: {
    updatePhoneNumber (res) {
      this.inputs.phone = res.number;
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return
      }

      return {
        'is-danger': this.errors.has(fieldName),
        touched: this.fields[fieldName].dirty,
      }
    },
    postalValidation () {
      const map = {
        "DE": "numeric|min:5|max:5",
        "AT": "numeric|min:4|max:4|1st-char-not-0"
      }
      return (!this.isOffer ? 'required|' : '') + map[this.countryInterface]
    },
    postalPlaceholder () {
      const map = {
        "DE": "10115",
        "AT": "4030"
      }
      return map[this.countryInterface]
    },
    cityPlaceholder () {
      const map = {
        "DE": "Berlin",
        "AT": "Wien"
      }
      return map[this.countryInterface]
    },
    emailPlaceholder () {
      const map = {
        "DE": "ansprechpartner@unternehmen.de",
        "AT": "ansprechpartner@unternehmen.at"
      }
      return map[this.countryInterface]
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/form';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.phone-resolver-container {
  border: none;
  padding-top: 0;
  padding-left: 0;
}

h3 {
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
    font-weight: bold;
  }
}

p {
  font-size: 1rem;
}



.contract-details {
  margin-bottom: 64px;
  padding: 16px 0 !important;
  background: $lightGrey;
  border-top: 2px solid $lightGrey3;
  border-bottom: 2px solid $lightGrey3;

  &__item {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 122px;

    + .contract-details__item {
      border-top: 2px solid $lightGrey3;

      @include viewport-large-up {
        border-left: 2px solid $lightGrey3;
        border-top: 0;
      }
    }

    @include viewport-large-up {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.form-group {
  margin-bottom: 0;
}

@include media-breakpoint-down(sm) {
  .form-group + .form-group {
      margin-top: 2rem;
  }
}

.contract-data {
  .form-row + .form-row {
    margin-top: 1rem;
  }

  .cd-label, .cd-control label {
    font-weight: bold;
  }
}

.two-liner {
  align-items: flex-end;
}

.is-offer {
  .form-group {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;

    h2 {
      word-break: break-word;
    }

    .form-group + .form-group {
      margin-top: 2rem;
    }
  }
}
</style>
