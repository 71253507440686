import { render, staticRenderFns } from "./Step1a.vue?vue&type=template&id=452997f0&scoped=true"
import script from "./Step1a.vue?vue&type=script&lang=js"
export * from "./Step1a.vue?vue&type=script&lang=js"
import style0 from "./Step1a.vue?vue&type=style&index=0&id=452997f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452997f0",
  null
  
)

export default component.exports