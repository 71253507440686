import {noop} from 'lodash';
import offerApi from '@/api/offer';
import pick from 'lodash/pick';
import CryptoJS from "crypto-js";


export const MUTATION_TYPES = {
  GET_OFFER_REQUEST: 'GET_CONTRACT_REQUEST',
  GET_OFFER_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_OFFER_FAILURE: 'GET_CONTRACT_FAILURE',

  PATCH_OFFER_REQUEST: 'PATCH_OFFER_REQUEST',
  PATCH_OFFER_SUCCESS: 'PATCH_OFFER_SUCCESS',
  PATCH_OFFER_FAILURE: 'PATCH_OFFER_FAILURE',

  SEND_FURTHER_QUESTIONS_REQUEST: 'SEND_FURTHER_QUESTIONS_REQUEST',
  SEND_FURTHER_QUESTIONS_SUCCESS: 'SEND_FURTHER_QUESTIONS_SUCCESS',
  SEND_FURTHER_QUESTIONS_FAILURE: 'SEND_FURTHER_QUESTIONS_FAILURE'
};

const initState = {
  offer: null,
  showInsuredSumOptions: false
};

const state = Object.assign({}, initState);

const getters = {
  offer: state => state.offer,
  insuredSumOptionsAllowed: state => !!(state.offer?.insuredSumOption1 || state.offer?.insuredSumOption2)
};

const actions = {
  getOffer({ dispatch, commit }, token) {
    let offer = {};

    commit(MUTATION_TYPES.GET_OFFER_REQUEST);

    return offerApi
      .getOffer(token)
      .then( async (payload) => {
        offer = payload.data.offer;

        const update = pick(offer, [
          'netTurnOver',
          'insuredSum',
          'insuredSumOption1',
          'insuredSumOption2',
          'insurer',
          'deductible',
          'paymentPeriod',
          'answersOnline',
          'industry',
          'answers',
          'form',
          'selectedModules',
          'meta',
          'key'
        ]);

        // decrypt prices
        if(typeof offer.prices === 'string') {
          const secretKey = 'NiTr+nyIdZudKB-j5i)MdyKU5IM2aS2x';
          const bytes = CryptoJS.AES.decrypt(offer.prices, secretKey);
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          offer.prices = decryptedData;
        }
        // load option1-2 prices
        if (offer.insuredSumOption1 || offer.insuredSumOption2) {
          const insuredSums = []
          if (offer.insuredSumOption1) {
            insuredSums.push(offer.insuredSumOption1)
          }
          if (offer.insuredSumOption2) {
            insuredSums.push(offer.insuredSumOption2)
          }

          offer.altProducts = {}

          const props = {...offer}
          props.insuredSums = insuredSums

          if (offer.insurer &&  offer.insurer !== 'no-recommendation') {
            // only for selected insurer
            props.insurer = offer.insurer
            const altProducts = await dispatch('CALCULATE_CYBER_PRODUCT_OPTIONS', props)
            offer.altProducts[offer.insurer] = altProducts

          } else if (offer.insurer === 'no-recommendation') {
            offer.selection.forEach(async insurer => {
              props.insurer = insurer
              const altProducts = await dispatch('CALCULATE_CYBER_PRODUCT_OPTIONS', props)
              offer.altProducts[insurer] = altProducts
            })
          }
        }

        if (offer.selection) {
          commit('UPDATE_OFFER_SELECTION', offer.selection);
        }
        // this would really need a check or refactor
        commit('UPDATE_PRICES', offer.prices); // I dont think this actually works, console log is silent
        commit('UPDATE_INPUT_VALUES', update); // I dont think this actually works, console log is silent
      })
      .then(() => {
        commit(MUTATION_TYPES.GET_OFFER_SUCCESS, offer);
        return offer;
      })
      .catch((error) => {
        commit(MUTATION_TYPES.GET_OFFER_FAILURE, error);
        throw error;
      });
  },
  patchOffer({ commit }, { id, patch }) {
    commit(MUTATION_TYPES.PATCH_OFFER_REQUEST);
    // No need to map a new object.
    return offerApi
      .patchOffer(id, patch)
      .then(() => {
        commit(MUTATION_TYPES.PATCH_OFFER_SUCCESS);
      })
      .catch(error => {
        commit(MUTATION_TYPES.PATCH_OFFER_FAILURE, error);
        throw error;
      })
  },
  sendFurtherQuestions({ commit }, data) {
    commit(MUTATION_TYPES.SEND_FURTHER_QUESTIONS_REQUEST);

    return offerApi
      .sendFurtherQuestions(data)
      .then(() => {
        commit(MUTATION_TYPES.SEND_FURTHER_QUESTIONS_SUCCESS);
      })
      .catch(error => {
        commit(MUTATION_TYPES.SEND_FURTHER_QUESTIONS_FAILURE, error);
        throw error;
      })
  }
};

const mutations = {
  [MUTATION_TYPES.GET_OFFER_SUCCESS](state, offer) {
    state.offer = offer;
  },
  [MUTATION_TYPES.PATCH_OFFER_SUCCESS](state) {
  },
  [MUTATION_TYPES.SEND_FURTHER_QUESTIONS_SUCCESS](state) {
  }
};

Object.keys(MUTATION_TYPES)
  .forEach((type) => {
    if (Object.keys(mutations).indexOf(type) === -1) {
      mutations[type] = noop;
    }
  });

export default {
  state,
  getters,
  actions,
  mutations
};
