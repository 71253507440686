<template>
  <div id="app">
    <main-nav></main-nav>
    <div>
      <router-view class="router-view" />
    </div>
    <HeaderNotifications
      ref="notifs"
      @openAvvModal="openAvvModal"
    >
    </HeaderNotifications>
    <simplert></simplert>
    <modal v-if="$store.state.isModalVisible" noBorders modalStyle="big">
    </modal>
  </div>
</template>

<script>
import MainNav from "@/containers/main-nav/MainNav.vue";
import { COMPANY_NAME } from "@/constants";
import modal from "@/components/modal/Modal.vue";
import HeaderNotifications from "@/components/header-notifications/index"

export default {
  name: "App",
  components: {
    MainNav,
    modal,
    HeaderNotifications
  },
  metaInfo: {
    title: "Welcome",
    titleTemplate: `%s | ${COMPANY_NAME}`,
    link: [
      {
        rel: "shortcut icon",
        type: "image/png",
        href: require("@/assets/images/cyberdiekt-favicon.png"),
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/icon?family=Material+Icons",
      },
    ],
  },
  methods: {
    openAvvModal () {
      this.$store.dispatch('OPEN_MODAL', {header: '', componentName: 'Avv', footer: '', modalStyle: 'lg'});
    }
  }
};
</script>

<style>
#app {
  min-height: 87vh;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/3545EB_1_0.eot');
  src: url('~@/assets/fonts/3545EB_1_0.eot?#iefix') format('embedded-opentype'),
  url('~@/assets/fonts/3545EB_1_0.woff2') format('woff2'),
  url('~@/assets/fonts/3545EB_1_0.woff') format('woff'),
  url('~@/assets/fonts/3545EB_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url('~@/assets/fonts/3545EB_0_0.eot');
  src: url('~@/assets/fonts/3545EB_0_0.eot?#iefix') format('embedded-opentype'),
  url('~@/assets/fonts/3545EB_0_0.woff2') format('woff2'),
  url('~@/assets/fonts/3545EB_0_0.woff') format('woff'),
  url('~@/assets/fonts/3545EB_0_0.ttf') format('truetype');
}

</style>
