<template>
  <div class="resume" :class="{'insured-sum-options-show': $store.getters.insuredSumOptionsAllowed && $store.state.offer.showInsuredSumOptions}">
    <div class="resume__card">
      <div class="resume__header">
        Empfehlung
      </div>
      <div class="resume__body resume__rec-logo">
        <img
          v-if="selectedInsurer"
          :src="getInsurerLogoPath(selectedInsurer)"
          :alt="selectedInsurer.label"
          :class="['resume__logo--' + selectedInsurer.key]"
          class="resume__logo"
        />
      </div>
    </div>

    <div class="resume__card">
      <div class="resume__header">
        Details
      </div>
      <div v-if="selectedInsurer" class="resume__body details">
        <div class="details__item">
          <div class="details__title">
            Branche
          </div>
          <div class="details__value">
            {{ inputs.industry.l | spaceSlashes }}
          </div>
        </div>

        <div class="details__item">
          <div class="details__title">
            Jahresumsatz
          </div>
          <div class="details__value">
            <span class="d-inline-flex">
              {{ $store.getters.revenueRange.start | currency(0) }}
            </span> -
            <span class="d-inline-flex">{{ $store.getters.revenueRange.end | currency(0) }}</span>
          </div>
        </div>

        <div class="details__item details__item--v2">
          <div class="details__title-v2">
            <div class="details__title-icon">
              <Icon name="check-bold" :width="15" />
            </div>

            <span>Versicherungssumme:</span>
          </div>
          <div class="details__value-v2">
            {{ offer.insuredSum | currency(0) }}
          </div>
        </div>

        <div class="details__item details__item--v2">
          <div class="details__title-v2">
            <div class="details__title-icon">
              <Icon name="check-bold" :width="15" />
            </div>

            <span>Selbstbehalt:</span>
          </div>
          <div class="details__value-v2">
            {{ this.transformDeductible(this.offer.deductible) | currency(0) }}
            {{ (offer.campaign === 'victor-fvdz' ? ' (50% reduziert)' : '') }}
          </div>
        </div>

        <div
          v-if="$store.getters.insuredSumOptionsAllowed"
          class="details__item form-group filter-option--checkbox filter-option--checkbox-offer"
        >
          <b-form-checkbox
            class="cd-control checkbox-accent control"
            name="insured-sum-options"
            v-model="$store.state.offer.showInsuredSumOptions"
          >
            <template v-if="!$store.state.offer.showInsuredSumOptions">Alternativen einblenden</template>
            <template v-else>Alternativen ausblenden</template>
            <button
                id="alt-info-btn"
                type="button" class="info-btn btn btn-icon"
              >
                <icon name="info" color="white" :width="18" :height="18"></icon>
              </button>
              
              <b-tooltip
                target="alt-info-btn"
                triggers="hover"
              >
                <div>
                  Hier können Sie alternative Versicherungssummen und die dazugehörigen Jahresbruttoprämien sehen.
                </div>
              </b-tooltip>
          </b-form-checkbox>
        </div>
      </div>

      <div v-if="$store.getters.insuredSumOptionsAllowed && $store.state.offer.showInsuredSumOptions" class="resume-card__footer insured-sum-options-v2">
        <template
            v-for="(insuredSumOption, idx) in offer.altProducts[selectedInsurerKey]">
            <div
              :key="`insured-sum-option-${idx}`"
              class="insured-sum-option">
              <span class="text1">{{insuredSumOption.name}}</span>: Versicherungssumme {{insuredSumOption.insuredSum | currency(0)}}
          </div>
        </template>
      </div>
    </div>

    <div class="resume__card">
      <div class="resume__header">
        Preis
      </div>
      <div class="resume__body price">
        <div class="price__item netto">
          <div class="price__value">
            {{ offer.prices[selectedInsurerKey]?.net | currency(2) }}
          </div>
          <div class="price__title">
            {{ paymentPeriod }}, netto <br />
          </div>
        </div>

        <div class="price__item tax">
          <div class="price__value">
            + {{offer.prices[selectedInsurerKey]?.tax | currency(2)}}
          </div>
          <div class="price__title">
            Versicherungssteuer, <span v-if="isAonOffer">11%</span> <span v-else>19%</span>
          </div>
        </div>

        <div class="price__item brutto">
          <div class="price__value">
            {{offer.prices[selectedInsurerKey]?.gross | currency(2)}}
          </div>
          <div class="price__title">
            {{ paymentPeriod }}, brutto
          </div>
        </div>
      </div>

      <div v-if="$store.getters.insuredSumOptionsAllowed && $store.state.offer.showInsuredSumOptions" class="resume-card__footer insured-sum-options-v2">
        <div v-if="offer.altProducts[selectedInsurerKey][0]" class="insured-sum-option">
          <template v-if="offer.altProducts[selectedInsurerKey][0]?.gross">
            <span class="text1">{{ offer.altProducts[selectedInsurerKey][0]?.gross | currency }}</span>&nbsp; {{ paymentPeriod }}, brutto
          </template>
          <template v-else>Versicherungssumme ist nicht standardmäßig verfügbar.</template>
        </div>
        <div v-if="offer.altProducts[selectedInsurerKey][1]" class="insured-sum-option">
          <template v-if="offer.altProducts[selectedInsurerKey][1]?.gross">
            <span class="text1">{{ offer.altProducts[selectedInsurerKey][1]?.gross | currency }}</span>&nbsp; {{ paymentPeriod }}, brutto
          </template>
          <template v-else>Versicherungssumme ist nicht standardmäßig verfügbar.</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon'

export default {
  components: { Icon },

  props: ['selectedInsurer', 'getInsurerLogoPath', 'getReadableProperty', 'transformDeductible', 'details', 'offer', 'selectedInsurerKey', 'paymentPeriod', 'isAonOffer', 'inputs'],

  computed: {
    insuredSumOptions () {
      const options = []

      if (this.offer.insuredSumOption1) {
        options.push(this.offer.insuredSumOption1)
      }

      if(this.offer.insuredSumOption2) {
        options.push(this.offer.insuredSumOption2)
      }

      return options
    }
  },

  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.resume {
  display: flex;
  gap: 0.5rem;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  &.insured-sum-options-show {
    .resume__body {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  &__card {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:first-child {
      .resume__header {
        border-radius: 0.25rem 0.25rem 0 0;
      }

      .resume__body {
        border-radius: 0 0 0.25rem 0.25rem;
      }
    }

    &:not(:first-child) {
      border-radius: 0.25rem;
      border: 2px solid #12658B;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-down(lg) {
        min-width: 300px;
      }

      @include media-breakpoint-down(md) {
        width: 402px;
        min-width: 402px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: 100%;
      }
    }

    &:last-child {
      @include media-breakpoint-down(md) {
        width: fit-content;
        flex-grow: 1;
      }

      @media screen and (max-width: 852px) {
        width: 40%;
      }
    }
  }

  &__card:first-child {
    width: 402px;
    min-width: 402px;

    @include media-breakpoint-down(md) {
      width: 100%;
      min-width: auto;
      margin-left: 410px;
      margin-top: -157px;
    }

    @include media-breakpoint-down(sm) {
      // width: 290px;
      // min-width: 290px;
      margin-left: 298px;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin-left: 0;
      margin-top: 0.275rem;
    }
  }

  &__header {
    padding: 0.75rem 1.5rem;
    background: #185470;
    color: #fff;
    font-weight: 800;
    text-align: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    background: #417e9b;
    color: #fff;
    padding: 1.5rem 2rem;
    height: 100%;

    @include media-breakpoint-down(lg) {
      padding: 1rem 1.25rem;
    }

    &.details {
      gap: 0.75rem;

      .details__item {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;
        margin-bottom: 0;
      }

      .details__title {
        font-weight: 800;

        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }

      .details__title-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 22px;
        width: 22px;
        min-width: 22px;
        border-radius: 20rem;
        background: #fff;
      }

      .details__item--v2 + .details__item--v2 {
        margin-top: -0.25rem;
      }
      .details__title-v2 {
        display: flex;
        align-items: center;
        gap: 1rem;
        hyphens: auto;

        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }

      .details__value {
        width: 130px;
        font-weight: 400;
        display: inline-flex;
        flex-wrap: wrap;
        hyphens: auto;

        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }

      .details__value-v2 {
        width: 130px;
        font-weight: 800;

        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }
    }

    &.price {
      display: flex;
      flex-direction: column;
      gap: 0.675rem;

      .price__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.25rem 1rem;

        @include media-breakpoint-down(sm) {
          justify-content: flex-start;
        }

        &.netto {
          .price__value {
            padding-left: 1.125rem;
          }
        }

        &.brutto {
          border-top: 1px solid rgba(#fff, 0.5);
          padding-top: 0.75rem;
          flex-wrap: wrap;
          gap: 0.25rem 1rem;

          .price__value {
            font-size: 1.85rem;
            line-height: 39.61px;
          }
        }
      }

      .price__value {
        font-weight: 900;
        font-size: 1.2rem;
        line-height: 25.95px;

        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }

      .price__title {
        font-weight: 300;
        width: 190px;
        color: #ebf0f2;
        opacity: 0.8;
        margin-left: auto;
        font-size: 0.85rem;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }
      }
    }
  }

  &__rec-logo {
    background: #ebf0f2;
    padding: 3.25rem 7rem;
    height: fit-content;
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      min-height: auto;
      padding: 2rem;
    }

    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }

  &__logo {
    // max-width: 205px;
    max-width: fit-content;
    width: 100%;

    @include media-breakpoint-down(md) {
      width: 70%;
      margin-right: 1.5rem;
      max-height: 40px;
    }
  }
}


.filter-option--checkbox {
  .custom-checkbox.custom-control ::v-deep {
    width: 100%;
    max-width: 100%;

    .custom-control-input ~ .custom-control-label::before {
      border-color: #fff;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: transparent;
    }

    .custom-control-label {
      font-weight: 800;
      margin-bottom: 0;
    }
    
  }

}

.insured-sum-options-v2 {
  // display: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;

  .insured-sum-option {
    background: rgba(#31A9E0, 0.25);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    font-size: 0.875rem;

    .text1 {
      font-weight: 900;
    }
  }
}

.info-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.5rem;
  padding: 0;
  min-width: fit-content;
}
</style>

