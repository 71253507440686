<template>
  <div id="get_started" :class="[`get_started-step-${activeStep.id}`, {'get_started-hide-cta': !isFordwardVisible}, {'is-www': isWWW}]">
    <HeaderAffiliate v-if="style === 'affiliate'" :logo="logo" :cooperation="cooperation" class="affiliate" :class="`affiliate--${affiliateBroker ? affiliateBroker.agency : ''}`"> </HeaderAffiliate>
    <PrintCover v-if="isOffer"></PrintCover>
    <div class="d-print-none" id="steps">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb-counter-nav">
              <li class="breadcrumb-counter-nav-item" v-for="(step, iSt) in visibleSteps"
                  @click.prevent.stop="changeStep(step, true)"
                  :class="{ active: getActive(step), completed: getCompleted(step) }"
                  :key="iSt">
                <icon name="check-completed"
                      width="14"
                      height="14"
                      color="green">
                </icon>
                <a>
                  <span>{{ step.label }}</span>
                </a>
                <transition name="fade">
                  <div class="multi-steps"
                       :style="getBarLength(step)">
                  </div>
                </transition>
              </li>
            </ul>
          </div>
        </div>
        <!--<div class="row menu-mobile">-->
        <!--{{activeStep.label}}-->
        <!--</div>-->
      </div>
    </div>
    <div class="container--content" :class="[{'container': useContainerClass, 'calc-container': !useContainerClass}, `step-${activeStep.id}`]">
      <transition name="fade" mode="out-in">
        <router-view :step="activeStep" :key="$route.path" class="view-content" :class="{'view-content--touch-device': $isMobileOrTablet}"></router-view>
      </transition>
      <div class="row" id="question_bubble" v-if="activeStep.instruction">
        <div class="col-12">
          {{ activeStep.instruction }}
        </div>
      </div>
    </div>

    <transition name="btns-fade" mode="out-in">
      <div v-if="isCtaVisible()" class="stepper-cta-outer">
        <div class="container container--buttons">
          <div class="row d-print-none" id="stepper_cta" :class="'step-'+activeStep.id">
            <div class="col-6 d-flex justify-content-start align-items-center">
              <button class="btn-style" v-on:click="goBack()"
                      v-if="activeStep.id !== '1a' && activeStep.id !== '1'">
                <icon name="chevron-left" color="secondary"></icon>
                Zur&uuml;ck
              </button>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center">

          <ButtonComponent
            class="submit-btn"
            v-if="isFordwardVisible"
            btn-style="accent"
            behavior="button"
            @click="emitSubmit()">
            Weiter
          </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="container" v-if="showContactBanner" >
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <ContactBanner />
          </div>
        </div>
      </div>
    </transition>

    <div
      class="loading-overlay"
      v-if="isCheckoutLoadingOverlay"
      :class="{ 'loading-overlay--in': isCheckoutLoadingOverlay }"
    >
      <LoaderComponent></LoaderComponent>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import eventBus from '@/components/shared/EventBus.js'
import queryString from 'query-string'
import ButtonComponent from './calculator-v2.0/ui/ButtonComponent.vue'
import Icon from '../Icon.vue'
import HeaderAffiliate from './HeaderAffiliate.vue'
import PrintCover from './calculator-v2.0/parts/PrintCover.vue';
import ContactBanner from './ContactBanner.vue'
import computedInsurers from '../mixins/computedInsurers.js'
import LoaderComponent from './shared/LoaderComponent.vue';

export default {
  name: 'Start',
  components: {
    Icon,
    HeaderAffiliate,
    PrintCover,
    ButtonComponent,
    ContactBanner,
    LoaderComponent
  },
  layout: 'checkout',
  data () {
    return {
      isSmallHeader: false,
      modalSuccess: false,
      useContainerClass: true,
      insurerCount: 0,
      isFordwardDisabled: false,
      inactivityTimer: null,
      isUserActive: true,
    }
  },
  computed: {
    ...mapGetters([
      'scores',
      'isBroker',
      'isWWW',
      'style',
      'affiliateBroker',
      'filteredInsurers',
      'activeStep',
      'isCheckoutLoadingOverlay'
    ]),
    showContactBanner () {
      return this.$store.getters.showContactBanner
    },
    logo () {
      // image source store.js line 296
      if (this.affiliateBroker && this.affiliateBroker.agencyLogo) {
        return this.affiliateBroker.agencyLogo
      }

      return 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/CyberDirekt_claim.svg'
    },
    isOffer () {
      return this.mode === 'offer'
    },
    isLegal () {
      return this.$route.path.includes('rechtsschutzversicherung')
    },
    isAffiliate () {
      return this.style === 'affiliate'
    },
    isFordwardVisible () {
      if (this.isAffiliate && this.activeStep && ['2a', '3a'].indexOf(this.activeStep.id) > -1) {
        return false
      } else if (this.isBroker && this.activeStep && this.activeStep.id === '2a' && this.$route.path.includes('produktauswahl')) {
        return false
      }

      if (this.activeStep.id === '1a') {
        return this.inputs.industry && this.inputs.industry.l
          ? true
          : false
      }

      return true
    },
    cooperation () {
      let affiliate_broker = this.affiliateBroker ? this.affiliateBroker.agency : undefined
      if (affiliate_broker === 'synaxon') {
        return {
          isCooperation: true,
          partners: {
            einsnulleins: require('../assets/img/einsnulleins.png'),
            synaxon: require('../assets/img/synaxon.png')
          }
        }
      }
      else return {}
    },
    inputs () {
      return this.$store.state.checkout.application.inputs
    },
    activeStep: {
      get () {
        return this.$store.state.checkout.application.activeStep
      },
      set (newValue) {
        this.$store.commit('SET_ACTIVE_STEP', newValue)
      }
    },
    steps () {
      const agency = this.affiliateBroker ? this.affiliateBroker.agency : ''
      const affiliateCondition = !this.isAffiliate || (agency === 'hornetsecurity')
      return this.$store.getters
        .filteredSteps(this.isLegal)
        .map((step) => {
          if (affiliateCondition && this.isBroker && step.id === '2a') {
            step.label = 'Marktvergleich'
            step.slug = 'produktvergleich'
            step.path = '/versicherung/2/produktvergleich'
            step.hideCtas = true
          } else if (this.isWWW && step.id === '2a') {
            step.label = 'Marktvergleich'
          }

          return step
        })
    },
    visibleSteps () {
      return this.steps
        .filter((step) => {
          if (step.hidden) {
            return false
          }

          if ((new RegExp(/[a-z]/i)).test(step.id)) {
            return step.id.includes('a')
          }

          return true
        })
    }
  },
  head () {
    return {
      title: this.activeStep.label
    }
  },
  watch: {
    $route: {
      handler () {
        !this.isWWW ? this.fixActiveAndCompletedSteps() : ''
        if (_.isEmpty(this.$route.params)) {
          if (this.isLegal) {
            return this.$router.push('/rechtsschutzversicherung/1/ihr-unternehmen')
          }
          return this.$router.push('/versicherung/1a/ihr-unternehmen/branche')
        }
        this.$emit('updateHead')
        this.useContainerClass = true

        const currentStep = this.steps.find(step => step.path === this.$route.path)
        if (currentStep && currentStep.id !== this.activeStep.id) {
          this.$store.dispatch('UPDATE_ACTIVE_STEP', currentStep)
        }
      },
      deep: true
    },
    insurers (insurers) {
      if (
        this.$route.name === 'produktvergleich'
        && !this.isWWW
        && this.style !== 'affiliate') {
        insurers.length < 4 ?
          this.useContainerClass = true :
          this.useContainerClass = false
      }
    }
  },
  mounted: function () {

    this.isWWW ? this.fixActiveAndCompletedSteps() : ''
    const parent = this
    const urlQuery = queryString.parse(location.search)

    this.defineContainerClass()

    if (_.isEmpty(this.$route.params)) {
      this.changeStep(this.steps[0])
    }
    this.getStepOnLoad()
    // Set timeout is necessary because of execution order
    setTimeout(() => {
      eventBus.$emit('globalIsLoading', false)
      eventBus.$emit('hasHdrVideo', false)
    })

    eventBus.$emit('disableSmallHeader', true)
    eventBus.$on('changeStepForward', currentStep => {
      const currentIndex = this.steps.findIndex(step => step.id === currentStep.id)
      const nextIndex = currentIndex + 1
      parent.changeStep(this.steps[nextIndex])
    })

    eventBus.$on('isLoading', (isLoading) => {
      if (isLoading) {
        this.isFordwardDisabled = true
      } else {
        this.isFordwardDisabled = false
      }
    })

    eventBus.$on('goBack', () => {
      parent.goBack()
    })

    eventBus.$on('insurerCount', (count) => {
      this.defineContainerClass()
      this.insurerCount = count
    })

    eventBus.$on('alertUser', (alert) => {
      parent.$Simplert.open(alert)
    })

    eventBus.$on('stepChangeCompleted', this.getStepOnLoad)
    this.$emit('updateHead')

    if (this.isLegal) {
      this.$set(this.inputs.answers, 'q-roland-1', undefined)
      this.$set(this.inputs.answers, 'q-roland-2', undefined)
    }

    // encode broker data
    if (this.isAffiliate && urlQuery.ref) {
      this.$store.dispatch('DECODE_AFFILIATE_TOKEN', urlQuery.ref.slice(0, -1))
    }
  },
  destroyed: function () {
    eventBus.$emit('disableSmallHeader', false)
    eventBus.$off('stepChangeCompleted')
    eventBus.$off('changeStepForward')
    eventBus.$off('goBack')
    eventBus.$off('insurerCount')
  },
  methods: {
    getCompleted: function (step) {
      return parseInt(this.activeStep.id) > parseInt(step.id)
    },
    getActive: function (step) {
      return parseInt(this.activeStep.id) === parseInt(step.id)
    },
    goBack: function () {
      const currentStepIndex = this.steps.findIndex(step => step.id === this.activeStep.id)

      if (this.$route.name === 'produktvergleich') {
        this.$router.go(-1)
      } else {
        this.changeStep(this.steps[currentStepIndex - 1])
      }
    },
    getBarLength: function (step) {
      const activeStepIdx = this.steps.findIndex((_step) => {
        return _step.id === this.activeStep.id
      })
      const stepIdx = this.steps.findIndex((_step) => {
        return _step.id === step.id
      })

      if (activeStepIdx > stepIdx) {
        return true
      }

      const relativesSteps = this.steps.filter(_s => {
        return parseInt(_s.id) === parseInt(step.id)
      })

      const percent = (relativesSteps.indexOf(this.activeStep) + 1) / relativesSteps.length

      return {
        width: (percent * 100) + '%'
      }
    },
    getStepOnLoad: function () {
      const stepId = this.$route.params.id
      const step = _.find(this.steps, { id: stepId })

      if (!step) {
        return
      }

      this.activeStep = step
    },
    emitSubmit: function () {
      eventBus.$emit('submitStep', this.activeStep.id)
    },
    changeStep: function (step, fromNav) {
      if (fromNav && !step.completed) {
        return
      }

      if (this.isBroker) {
        this.forceResetOffer(step)
      }

      this.activeStep = step
      this.$router.push(step.path)
    },

    forceResetOffer(step) {
      const allSortedSteps = ['1a', '1b', '2a', '2b', '3a', '4a', '5a', '6a']
      let defaultInputs = null

      if (allSortedSteps.indexOf(step.id) < allSortedSteps.indexOf(this.activeStep.id)) {
        try {
          defaultInputs = require(`../../content/step${this.activeStep.id}-default-inputs`)
        } catch (e) {
          console.log(`there is no default file for step ${this.activeStep.id}.`, e)
        }

        this.$store.commit('RESET_ROOT_OFFER')
        this.$store.commit('RESET_OFFER')
        if (defaultInputs) {
          this.$store.commit('RESET_INPUTS_WITH_MAP', defaultInputs)
        }
      }
    },

    isCtaVisible() {
      if (this.activeStep.hideCtas) {
        return false
      } else if (this.activeStep.hideCtasOnMobile && this.$isMobile) {
        return false
      } else {
        return true
      }
    },
    getFieldClass (fieldName) {
      if (!this.fields[fieldName]) {
        return
      }

      return {
        'is-danger': this.errors.has(fieldName),
        'touched': this.fields[fieldName].dirty
      }
    },
    getContainerWidth () {
      if (!this.useContainerClass) {
        return {
          'min-width': (350 + 64 + this.insurerCount * 200) + 'px'
        }
      }
    },
    fixActiveAndCompletedSteps () {
      const exceptions =
          this.$route.path === '/versicherung'
          || this.$route.fullPath.includes('shared')
          || this.$route.name === 'check-list'
      if (!exceptions) {
        const currentStep = this.steps.find(step => step.path === this.$route.path || (this.$route.path.includes('/versicherung/2/') && step.path.includes('/versicherung/2/')))
        if(currentStep) {
          this.activeStep = currentStep
        }

        // clear forward steps
        let moreThanActive = false
        this.steps.forEach((step) => {
          if (moreThanActive) {
            step.completed = false
          }
          if (step.active) {
            step.completed = false
            moreThanActive = true
          }
        })

        // check if not allowed and relocate
        let activeIndex
        let completedIndex = -1
        this.steps.forEach((step,index) => {
          if(step.completed) {
            completedIndex = index
          }
          if(step.active) {
            activeIndex = index
          }
        })
        if (!(activeIndex-1 <= completedIndex)) {
          // after refreshing from the contract page, this keeps redirecting after finishing registration
          // which is bad, so I am blocking it. Not ideal, but do not have better solution at the moment.
          // do not move away from the success page
         if(activeIndex === 6) {
          return
         }

          this.$router.push(this.steps[completedIndex+1])
        }

        // check if 4a (questions step)
        if (this.activeStep.id === '4a') {
          // clear cached answers
          this.$store.state.checkout.application.inputs.answers = {}
        }

        this.activeAndCompletedstepsFixed = true
      }
    },
    defineContainerClass() {
      const insurersQuantity = 4 // breakpoint to switch container
      const showDetailsForOne =
        this.$route.query
        && this.$route.query.insurer
        && (typeof this.$route.query.insurer === 'string' || this.$route.query.insurer.length < insurersQuantity)
      if (
        this.isBroker
        && !this.isOffer
        && !this.isAffiliate
        && this.filteredInsurers.length >= insurersQuantity
        && this.activeStep.id === '2a'
        && this.$route.name !== 'produktauswahl'
        && !showDetailsForOne
      ) {
          this.useContainerClass = false
        } else {
          this.useContainerClass = true
        }
    }
  },

  mixins: [computedInsurers]
}
</script>
<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/tooltip';
@import '../scss/form';
@import '../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../scss/print/start.scss';

$font-color: #fff;
$nav-background: $darkBlueTone;
$stepsNavHeight: 57px;

.no-container {
  padding-left: 32px;
  padding-right: 32px;
}

body {
  counter-reset: section;
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .3s;
}

.fade-enter-active {
  transition-delay: .3s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

.btns-fade-enter-active, .btns-fade-leave-active {
  transition-property: opacity;
  transition-duration: .8s;
}

.btns-fade-enter-active {
  transition-delay: .8s;
}

.btns-fade-enter, .btns-fade-leave-active {
  opacity: 0
}

.submit-btn {
  width: 285px;
}

.container--content {
  width: 100%;
  position: relative;
  //min-height: 500px;
  //max-height: calc(100vh-100px);
  // padding-top: 2rem;
  padding-bottom: 100px;

  &.step-1a {
    padding-bottom: 0;
  }

  &.step-1b {
    padding-bottom: 0;

    @include media-breakpoint-up(sm) {
      padding-bottom: 1.5rem;
    }
  }

  @media screen and (min-width: 1600px) {
    &.calc-container {
      .view-content.insurers-qnty-4,
      .view-content.insurers-qnty-5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    overflow: auto;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.container--buttons {
  width: 100%;
  position: relative;

  @include media-breakpoint-down(sm) {
    overflow: auto;
    position: absolute;
    bottom: 0.5rem;
    height: 45px;
  }
}

#steps {
  background: $nav-background;
  width: 100%;

  @include media-breakpoint-up(md) {
    position: fixed;
    top: 71px;
    z-index: $zIndexSteps;
  }

  .breadcrumb-counter-nav {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    list-style: none;
    background: $nav-background;
    position: relative;

    .breadcrumb-counter-nav-item {
      position: relative;
      flex: 1 0 auto;
      padding: 1rem 1rem;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      counter-increment: section;

      .icon--green {
        display: none;
        position: relative;
        top: -1px;
        left: -3px;
      }

      &:hover {
        cursor: default;

        a {
          cursor: default;
        }
      }

      &.completed:hover {
        cursor: pointer;
        background: #2C3044;

        a {
          cursor: pointer;
        }
      }

      &::before {
        content: counter(section);
        position: relative;
        color: $font-color;
        top: 1px;
        border-radius: 50%;
        padding: 0.2em .7em;
        font-weight: 500;
      }

      $completedBkg: #2C3044;

      &.active {
        background: $completedBkg;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -1rem;
          width: 0;
          height: 0;
          border-top: 1.75rem solid transparent;
          border-bottom: 1.75rem solid transparent;
          border-left: 1rem solid $completedBkg;
          z-index: 100;
        }
      }

      &.completed {
        background: $completedBkg;

        .icon--green {
          display: inline-block;
        }
      }

      a {
        color: $font-color;
        font-size: 0.9rem;
        font-weight: 500;
      }

      div.multi-steps {
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $blueTone;
      }
    }

    .breadcrumb-counter-nav-item.completed:not(.active) {
      &::before {
        content: "";
      }
    }

    @media only screen and (max-width: 52em) {
      // flex-direction: column;
    }
  }

  .menu-mobile {
    display: none;
  }
}

#question_bubble {
  margin-top: $standardGutter;
}
.is-www {  
  #steps {
    @include media-breakpoint-up(md) {
      top: 60px; //for www4
    }
  }

  #stepper_cta {
    &.step-1a {
      top: 0 !important;
    }
  }
}

#stepper_cta {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  z-index: $zIndexStepperCta;

  &.step-1a {
    justify-content: center;
    position: relative;

    .submit-btn {
      position: relative;
      right: 0;
      min-width: 240px;
    }

    @include media-breakpoint-up(sm) {
      .submit-btn {
        // right: -2rem;
      }
    }

    &#stepper_cta {
      left: 0;
      right: 0;
    }
  }

  button {
    padding: 8px 12px;
  }

  button.primary {
    float: right;
  }
}
</style>

<style lang="scss">
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/tooltip';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../scss/print';

// 5b97c42bf9764e7db039557e

.container--full-screen {
  padding-left: 16px;
  padding-right: 16px;
}

.text-primary {
  color: #31a9e0 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fefefe;
  background-color: #31a9e0 !important;
}

#get_started {
  margin-top: $hdrHeight;
  padding-bottom: calc($standardGutter * 2);

  .view-content {
    padding-top: 95px;

    > div {
      h2:first-child {
        text-align: center;
        font-size: 1.5rem;
        color: #000;
        margin-bottom: 40px;
      }
    }

    form#step_form {
      > div {
        padding-bottom: 0;

        h2:first-child {
          font-size: 1.75rem;
          color: #000;
          font-weight: 900;
          line-height: 1.2;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .view-content {
      // overflow: auto;
      padding-top: 0;
      min-width: fit-content;

      form#step_form {
        > div {
          h2:first-child {
            font-size: 1.2rem;
            margin-bottom: $standardGutter;
          }
        }
      }
    }
  }

}

.get_started-step-1a {
  .view-content {
    min-width: 100% !important;
  }

  .stepper-cta-outer {
    overflow: visible;
    margin-bottom: 2.25rem;

    .container--buttons {
      overflow: visible;
    }
    // @include media-breakpoint-up(md) {
    //   margin-bottom: 0;
    // }
  }

  &.get_started-hide-cta {
    .stepper-cta-outer {
      display: none !important;
    }
  }
}

input#step_submit {
  display: none;
}

button {
  border: 1px solid $blueTone;
}

button.main-cta {
  background: $blueTone;
  color: #fff;
}

button.active, button.selected {
  background: #ccc;
}

.rounded-checkbox {
  $neutral-medium: $topDownColor;
  $neutral-light: $greyTone;
  $green: $blueTone;

  @keyframes switch-on-position {
    0% {
      left: 0;
      right: 50%;
    }

    25% {
      left: 0;
      right: 37.5%;
    }

    100% {
      left: 50%;
      right: 0;
    }
  }

  @keyframes switch-off-position {
    0% {
      left: 50%;
      right: 0;
    }

    25% {
      left: 37.5%;
      right: 0;
    }

    100% {
      left: 0%;
      right: 50%;
    }
  }

  @keyframes switch-on-transform {
    0% {
      transform: translate(0) scaleX(1);
    }

    25% {
      transform: translate(0) scaleX(1.33);
    }

    100% {
      transform: translate(100%) scaleX(1);
    }
  }

  @keyframes switch-off-transform {
    0% {
      transform: translate(100%) scaleX(1);
    }

    25% {
      transform: translate(100%) scaleX(1.33);
    }

    100% {
      transform: translate(0) scaleX(1);
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    display: inline-block;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    height: 2em;
    width: 4em;
    font-size: 2em;
    border-radius: 1.5em !important;
    background-color: $neutral-medium;
    border-color: transparent;
    background-clip: padding-box;
    color: $neutral-light;
    vertical-align: middle;
    transition: all 0.25s linear 0.25s;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 50%;
      background-color: white;
      border-radius: 100%;
      border: 0.125em solid transparent;
      background-clip: padding-box;
      z-index: 2;
      transform-origin: right center;
    }

    &::after {
      position: absolute;
      left: 0.675em;
      top: 0;
      line-height: 2;
      font-family: "Ionicons";
      content: "";
      letter-spacing: 1em;
      z-index: 1;
    }

    &:focus {
      color: white;
      border-color: transparent;
      background-color: $neutral-medium;
      outline: none;
    }
  }

  input[type="checkbox"]:checked {
    color: white;
    background-color: $green;
    border-color: transparent;

    &::before {
      transform-origin: left center;
    }
  }

  .field {
    transform: scale(.8);
    cursor: pointer;

    > div {
      align-items: center;
      justify-content: center;
      display: flex;

      label {
        color: $bodyColor;
        line-height: 1;
        font-size: 1.3rem;
        margin-left: $standardGutter;
      }

    }

    display: inline-block;
    margin: 1.5em 0 .4em;

  }

  hr {
    border: none;
    border-bottom: 1px solid $neutral-light;
  }

  label {
    display: block;
    color: $neutral-medium;
  }

  .field.position input[type="checkbox"]::before {
    animation: switch-off-position 0.25s ease-out forwards;
  }

  .field.position input[type="checkbox"]:checked::before {
    animation: switch-on-position 0.25s ease-out forwards;
  }

  .field.transform input[type="checkbox"]::before {
    animation: switch-off-transform 0.25s ease-out forwards;
  }

  .field.transform input[type="checkbox"]:checked::before {
    animation: switch-on-transform 0.25s ease-out forwards;
  }
}

.dropdown-menu {
  right: 0;
}

.dropdown {
  &.open {
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-item {
  &.active {
    background: $lightGrey !important;
  }
}

@include media-breakpoint-only(md) {
  #steps {
    .container {
      .row {
        .col-12 {
          .breadcrumb-counter-nav {
            .breadcrumb-counter-nav-item {
              a {
                span {
                  display: none;
                }
              }
            }

            .breadcrumb-counter-nav-item.active {
              a {
                span {
                  display: inline-block;
                }
              }
            }
          }
        }
      }

      .menu-mobile {
        display: none;
      }
    }
  }
  #steps {
    .menu-mobile {
    }
  }
}

#get_started .container {
  position: relative;
}

@include media-breakpoint-down(sm) {
  #steps {
    .container {
      max-width: none;

      .row {
        .col-12 {
          padding-left: 0;
          padding-right: 0;

          .breadcrumb-counter-nav {
            .breadcrumb-counter-nav-item {
              a {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }

      .menu-mobile {
        font-size: 1.25rem;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        background: #fff;
        box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.21);
      }

    }
  }
}

.simplert {
  .btn {
    white-space: normal;
  }

  p {
    font-size: 1rem;
    color: inherit;
  }
}

.simplert__icon--error {
  border-color: $orangeTone !important;

  div {
    background-color: $orangeTone !important;
  }
}

//temp  probably we want to remove this
.simplert__title:not(:empty) {
  margin-top: $standardGutter;
}

.simplert__body {
  // display: none!important;
  font-size: 1.2rem;
}

.simplert__content {
  border-radius: $borderRadius;
}

.calc-container {
  position: absolute !important;
  min-height: 500px;
  // max-width: 1344px;
  padding: 80px 1rem 100px 1rem;
  // margin: 0 auto;

  @include media-breakpoint-down(sm) {
    position: static !important;
    overflow: auto;
    padding: 2rem 0 100px 1rem;
  }
}

.offer-mode {
  @include media-breakpoint-down(sm) {
    .calc-container {
      // overflow: hidden;
      overflow: visible;
    }
  }

  @include media-breakpoint-between(sm, md) {
    .calc-container {
      overflow: scroll;
    }
  }
}

body:not(.offer-mode) {
  .acc-child {
    .trigger-wrap--no-score {
      left: 0;
    }
  }

  div.filter-row-wrapper.filter-row-wrapper--sticky {
    top: 116px;

    .badge {
      display: none;
    }
  }
}

body.is-affiliate {
  div.filter-row-wrapper.filter-row-wrapper--sticky {
    .insurers .company.insurer .custom-control-label {
      top: 0.5rem;
    }
  }
}

body.is-not-affiliate.broker-mode {
  div.filter-row-wrapper.filter-row-wrapper--sticky {
    .insurers .company.insurer {
      top: 0.5rem;
    }
    .filter-options .cyb-card {
      margin-top: 0.5rem;
    }
  }
}

.loading-overlay {
  position: fixed;
  z-index: $zIndexLoading;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  user-select: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.25s;

  &--in {
    opacity: 1;
  }
}

//table scroll sync
@include media-breakpoint-down(xs) {
  .offer-mode {
    overflow-x: hidden;

    .filter-row-wrapper,
    .acc-child--nested-lvl-0 > .acc-subgroup > .acc-child__body {
      transform: translateX(-1.5rem);
      padding-left: 1.5rem;
      padding-right: 1.2rem;
      margin-right: -1.5rem;
      overflow: auto;
      width: 100vw;
    }

    .acc-table__body-wrap,
    .acc-child__header,
    .acc-child--nested-lvl-0 > .acc-subgroup
    {
      //padding-left: 1.5rem;
      //transform: translateX(-1.5rem);
    }

    .acc-child__row.acc-child--nested-lvl-3 {
      position: sticky;
      left: 0;
      // padding-left: 2.5rem;
      // transform: translateX(-2.5rem);
      // left: 3.5rem;
      // position: sticky;
    }
  }
}
// /table scroll sync
</style>
