<template>
  <div class="progress-circle">
    <template v-if="this.progress !== 'winner'">
      <svg :height="radius * 2" :width="radius * 2">
        <circle
          stroke="#dfe7ea"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset: bgStrokeDashoffset }"
          stroke-width="10"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
        <circle
          class="progress__active"
          :stroke="progressColor"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset: strokeDashoffset }"
          :stroke-width="stroke"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
      </svg>
      <label class="label"> {{ progressValue }}% </label>
    </template>
    <template v-else>
      <img class="win-icon" src="../../../assets/img/tr2-winner-entire.png" alt="100%">
    </template>
  </div>
</template>

<script>
export default {
  props: {
    radius: Number,
    progress: [Number, String],
    stroke: Number,
    strokeColor: String,
    isPrintMode: {
      type: Boolean,
      default () {return false}
    }
  },

  data() {
    return {
      progressValue: this.isPrintMode ? Math.round(this.progress) : 0,
      progressBreakpoints: [0, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
      progressColors: ['#ffff00', '#f0e625', '#e2e135', '#d0dc42', '#bfd74c', '#abd255', '#96cc5b', '#7fc661', '#69c166', '#50bb69', '#38b76d', '#11b36e']
    }
  },

  computed: {
    normalizedRadius() {
      return this.radius - this.stroke * 2
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI
    },
    strokeDashoffset() {
      return (
        this.circumference - (this.progressValue / 100) * this.circumference
      )
    },
    bgStrokeDashoffset() {
      return this.circumference - (100 / 100) * this.circumference
    },
    progressColor() {
      let color = ''
      this.progressBreakpoints.forEach((brPoint, i) => {
        if (this.progressValue >= brPoint && (this.progressValue < this.progressBreakpoints[i + 1]) || this.progressValue === 100) {
          color = this.progressColors[i]
        }
      })
      return color
    },
  },

  watch: {
    progress (newVal) {
      this.progressValue = 0
      if (newVal) {
        if (this.isPrintMode) {
          this.progressValue = Math.round(newVal)
        } else {
          this.simulateProgress()
        }
      }
    }
  },

  mounted() {
    this.progress && !this.isPrintMode ? this.simulateProgress() : ''
  },

  methods: {
    simulateProgress () {
      const interval = setInterval(async () => {
        if (this.progressValue >= Math.round(this.progress)) {
          clearInterval(interval)
          return
        }
        this.progressValue += await 1
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-circle {
  position: relative;
  display: inline-flex;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  margin-bottom: 0;
}

.win-icon {
  width: 100px;
}

</style>
