<template>
  <div class="container">
    <form v-on:submit.prevent.stop="submitForm()" name="step3" id="step_form">
      <div class="row mb-5">
        <div class="col-12" v-if="showLegalNote">
          <div class="alert alert-dark" role="alert">
            <div class="mb-3"><strong>Hinweis</strong></div>
            <div>
              Zur Pr&uuml;fung des zu versichernden Risikos ist es notwendig, dass nachfolgende Fragen
              wahrheitsgem&auml;&szlig;
              und vollst&auml;ndig durch den/die Antragsteller/in (Kunde) beantwortet werden. Es sind auch
              solche Umst&auml;nde
              anzugeben, denen nur geringe Bedeutung beigemessen wird. Der Versicherungsschutz wird gef&auml;hrdet,
              wenn
              unrichtige oder unvollst&auml;ndige Angaben gemacht werden. Bitte beachten Sie den
              gesonderten Hinweis zur
              Belehrung &uuml;ber die Folgen einer Verletzung der vorvertraglichen Anzeigepflicht vor
              Abgabe der
              Vertragserkl&auml;rung <a target="_blank" :href="vvgPdf.link">{{ vvgPdf.name }}</a>.
              <span v-if="!isWWW">Sofern die Fragen durch
              den/die Versicherungsmakler/in f&uuml;r den Kunden beantwortet werden, ist diese/r
              verpflichtet, den
              Kunden auf die vorgenannten Rechtsfolgen hinzuweisen.</span>
            </div>
          </div>
        </div>
        <div class="col-12 questionaire" v-if="questions.length && !showLegalNote">
          <transition name="slide-fade" mode="out-in">
            <template v-for="(question, questionIndex) in questions">

                <div class="question"
                  :key="question.id"
                  v-if="questionIndex === currentQuestion">
                <div class="row">
                  <div class="col-12 col-sm-8">
                    <div class="row" id="question_header">
                      <h2 class="question__title">
                        Frage {{ currentQuestion + 1 }} von {{ questions.length }}
                      </h2>
                    </div>
                    <p class="question__text" v-if="question.title">
                      {{ question.title }}
                      <img class="circle-icon"
                          src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/info.svg"
                          alt="Info"
                          v-if="question.tooltip"
                          @click="openToolTip(question.tooltip)">
                    </p>
                    <p class="question__text" v-if="question.text">
                      {{ question.text }}
                    </p>
                    <ul class="question__list" v-if="question.list">
                      <li v-for="(item, qI) in question.list" :key="qI" :class="{'question__list--sublist':item.subText, 'ml-4':item.subText}">
                        <div v-if="item.text" v-html="$sanitizeHtml(item.text)"></div>
                        <div v-else-if="item.subText" v-html="$sanitizeHtml(item.subText)"></div>
                        <img class="circle-icon"
                            src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/info.svg"
                            alt="Info"
                            v-if="item.tooltip"
                            @click="openToolTip(item.tooltip)">
                      </li>
                    </ul>
                    <div v-if="form" class="form-row">
                      <div class="form-group" v-for="field in form"
                          :key="field.name"
                          :class="getFieldClass(field.name)">
                        <label>{{ field.label }}</label>
                        <input class="form-control"
                              @input="checkQuestion(field.name)"
                              type="text"
                              v-if="field.type === 'text'"
                              :id="field.name"
                              :name="field.name"
                              v-validate="{ required: true }"
                              v-model="inputs.form[question.id][field.name]">
                        <textarea class="form-control"
                                  v-else-if="field.type === 'textarea'"
                                  :id="field.name"
                                  :name="field.name"
                                  v-validate="{ required: true }"
                                  v-model="inputs.form[question.id][field.name]"/>
                        <datepicker v-else-if="field.type === 'datePicker'"
                                    format="dd.MM.yyyy"
                                    input-class="form-control"
                                    :language="de"
                                    :monday-first="true"
                                    calendar-button-icon="fa fa-calendar"
                                    :disabledDates="disabledDates"
                                    v-model="inputs.form[question.id][field.name]"></datepicker>
                        <form class="address-repeater"
                              v-else-if="field.type === 'address-repeater'"
                              autocomplete="off">
                          <div class="row">
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="spName">Unternehmen</label>
                                <input class="form-control sp-input"
                                      type="text"
                                      name="spName"
                                      id="spName"
                                      v-model="serviceProviderString">
                                <typeahead v-model="selectedServiceProvider"
                                          target=".sp-input"
                                          :data="serviceProvider"
                                          :limit="50"
                                          item-key="name">
                                  <template slot="item" slot-scope="props">
                                    <li v-for="(item, idx) in props.items" :key="'item-' + idx">
                                      <a v-html="props.highlight(item)"
                                        role="button"
                                        class="dropdown-item"
                                        @click="props.select(item);spSelected()">
                                      </a>
                                    </li>
                                  </template>
                                </typeahead>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="street">Straße und Hausnummer</label>
                                <input class="form-control"
                                      type="text"
                                      name="street"
                                      id="street"
                                      v-model="formData.street">
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="city">Plz und Ort</label>
                                <input class="form-control"
                                      type="text"
                                      name="city"
                                      id="city"
                                      v-model="formData.city">
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="country">Land</label>
                                <input class="form-control"
                                      type="text"
                                      name="country"
                                      id="country"
                                      v-model="formData.country"/>
                              </div>
                            </div>
                          </div>
                          <button class="btn btn-secondary mb-3"
                                  type="button"
                                  @click="addServiceProvider(field.name)">hinzufügen
                          </button>
                          <table class="table"
                                v-if="inputs.form[question.id][field.name].length > 0">
                            <thead>
                            <tr>
                              <th>Dienstleister</th>
                              <th>Adresse</th>
                              <th class="text-center">Löschen</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(provider, index) in inputs.form[question.id][field.name]"
                                :key="provider.name">
                              <td>{{ provider.name }}</td>
                              <td>{{ provider.street }}<br>{{ provider.city }}<br>{{ provider.country }}
                              </td>
                              <td class="text-center">
                                <button class="btn btn-icon"
                                        type="button"
                                        @click="removeServiceProvider(field.name, index)">
                                  <icon name="cancel" color="secondary"></icon>
                                </button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </form>
                        <form class="subsidiary-repeater"
                        v-else-if="field.type === 'subsidiary-repeater'"
                        autocomplete="off">
                        <div class="row">
                          <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="city">Land</label>
                                <input class="form-control"
                                      type="text"
                                      name="country"
                                      id="country"
                                      v-model="formSubsidiaryData.country">
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="country">Umsatz</label>
                                <input class="form-control"
                                      type="text"
                                      name="revenue"
                                      id="revenue"
                                      v-model="formSubsidiaryData.revenue">
                              </div>
                            </div>
                          </div>
                          <button class="btn btn-secondary mb-3"
                                  type="button"
                                  @click="addSubsidiary(field.name)">hinzufügen
                          </button>
                          <table class="table"
                                v-if="inputs.form[question.id][field.name].length > 0">
                            <thead>
                            <tr>
                              <th>Land</th>
                              <th>Umsatz</th>
                              <th class="text-center">Löschen</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(subsidiary, index) in inputs.form[question.id][field.name]"
                                :key="subsidiary.name">
                              <td>{{ subsidiary.country }}</td>
                              <td>{{ subsidiary.revenue }}</td>
                              <td class="text-center">
                                <button class="btn btn-icon"
                                        type="button"
                                        @click="removeServiceProvider(field.name, index)">
                                  <icon name="cancel" color="secondary"></icon>
                                </button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 d-flex align-items-start justify-content-end"
                      id="desktop_navigator" v-if="questions.length && !isStandaloneForm">
                    <div class="question__answers question__answers--custom text-center d-flex flex-column"
                        v-if="getAnswers(question).length > 1">
                      <button type="button"
                              @click="answerQuestion(answer.value)"
                              :class="{'btn-primary': isSelected(answer.value)}"
                              v-for="answer in getAnswers(question)"
                              :key="answer.value"
                              :disabled="isAnswerButtonDisabled"
                              class="btn btn-secondary mr-2">{{ answer.label }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </transition>
        </div>
      </div>
      <div class="row">

        <div class="col-6">
          <button type="button"
                  class="btn-style"
                  @click="goBack()">
            <icon name="chevron-left" color="secondary"></icon>
            Zurück
          </button>
        </div>
        <div class="col-6 text-right">
          <button type="button"
                  class="btn-style primary"
                  v-if="showLegalNote"
                  @click="checkLegal1()">
            Einverstanden
            <icon name="chevron-right" color="white"></icon>
          </button>
          <button type="button"
                  class="btn-style"
                  v-else-if="form"
                  :disabled="nextDisabled"
                  @click="submitQuestionForm()">
            Weiter
            <icon name="chevron-right" color="secondary"></icon>
          </button>
        </div>
      </div>

      <input type="submit" id="step_submit">
    </form>
    <div id="navigator" style="margin-top: 30px;">
      <div class="question__answers text-center" v-if="questions.length && !isStandaloneForm">
        <button type="button"
                @click="answerQuestion('no')"
                :disabled="isAnswerButtonDisabled"
                class="btn btn-secondary">Nein
        </button>
        <button type="button"
                @click="answerQuestion('yes')"
                :disabled="isAnswerButtonDisabled"
                :class="{'btn-primary': isSelected('yes')}"
                class="btn btn-secondary">Ja
        </button>
      </div>
    </div>
    <transition name="modal">
      <modal v-if="showModal" @close="showModal = false">
        <template slot="body">
          <div v-html="tooltipContent"></div>
        </template>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="showWarningModal" @close="showWarningModal = false" modal-style="mobile">
        <div slot="body">
          <p v-html="warningModalContent"></p>
        </div>
        <button class="btn btn-secondary"
                type="button"
                @click="goForward({skipWarning: true})"
                slot="footer">Weiter
        </button>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="isProblemModal" :isCenteredFooter="true" :size="'sm'" :closeable="false">
        <div slot="body">
          <problem-modal/>
        </div>
        <div slot="footer">
            <button class="btn btn-secondary"
                    type="button"
                    @click="isProblemModal = false">OK
            </button>
        </div>
      </modal>
    </transition>
  </div>
</template>

<script>
import Modal from '../Modal';
import eventBus from '@/components/shared/EventBus.js'
import ProblemModal from './ProblemModal.vue'
import Icon from '../Icon.vue';
import {Typeahead} from 'uiv';
import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { mapGetters } from 'vuex'
  import isStepAllowed from '../mixins/isStepAllowed.js'


export default {
  name: 'Step3',
  data() {
    return {
      isProblemModal: false,
      tooltipContent: '',
      nextDisabled: false,
      showModal: false,
      currentQuestion: 0,
      showWrongAnswerNote: false,
      questions: [],
      de: de,
      formData: {
        name: '',
        street: '',
        city: '',
        country: ''
      },
      formSubsidiaryData: {
        country: '',
        revenue: ''
      },
      selectedServiceProvider: null,
      serviceProviderString: '',
      showWarningModal: false,
      warningModalContent: '',
      isAnswerButtonDisabled: false,
      disabledDates: {
        to: moment()
          .startOf('day')
          .toDate()
      },
      localLegalCheck: true
    };
  },
  computed: {
     ...mapGetters([
      'isWWW',
      'isBroker'
    ]),
    inputs() {
      return this.$store.state.checkout.application.inputs;
    },
    isLegal() {
      return this.$route.path.includes('rechtsschutzversicherung');
    },
    showLegalNote() {
      if (this.isBroker) {
        return !this.$store.state.checkout.application.broker.legalCheck1
      } else if (this.isWWW) {
        if(!this.$store.state.checkout.application.public) {
          return this.localLegalCheck
        }
        return !this.$store.state.checkout.application.public.legalCheck
      } else {
        return false
      }
    },
    insurers() {
      return this.$store.state.checkout.application.insurers;
    },
    serviceProvider() {
      return this.$store.state.checkout.application.serviceProvider;
    },
    vvgPdf() {
      const {files} = this.isLegal
        ? this.$store.state.application.inputs.legal.insurer.files
        : this.insurers.filter((insurer) => {
          return insurer.key === this.inputs.insurer;
        })[0];

      return files.filter(file => file.vvg)[0];
    },
    formStyle() {
      return this.question && this.question.form ? this.question.form.style : undefined;
    },
    isStandaloneForm() {
      return this.question && this.question.form && this.question.form.fields;
    },
    form() {
      const answer = this.question ? this.inputs.answers[`q-${this.question.id}`] : undefined;
      let hasForm;

      if (this.question && this.question.form) {
        if (this.question.form[answer]) {
          hasForm = this.question.form[answer];
        } else if (this.question.form.fields) {
          hasForm = this.question.form.fields;
        }
      }

      if (hasForm) {
        this.inputs.form = this.inputs.form || {};

        if (!this.inputs.form[this.question.id]) {
          this.$set(this.inputs.form, this.question.id, {});
        }

        if (hasForm) {
          hasForm.forEach((field) => {
            if (!this.inputs.form[this.question.id][field.name]) {
              switch (field.type) {
                case 'address-repeater':
                case 'subsidiary-repeater':
                  this.$set(this.inputs.form[this.question.id], field.name, []);
                  break;
                default:
                  this.$set(this.inputs.form[this.question.id], field.name, undefined);
                  break;
              }
            }
          });
        } else {
          this.$set(this.inputs.form, this.question.id, {});
        }
      }

      return hasForm;
    },
    question() {
      return this.questions[this.currentQuestion];
    }
  },
  props: ['step'],
  mounted() {
    eventBus.$emit('stepChangeCompleted');
    eventBus.$emit('disableHdrNext', true);

    eventBus.$on('closeModalTooltip', res => {
      this.showModal = false;
    });
    
    // every time you enter this view, the notification has to show, so we need to
    // reset the vuex, otherwise it will be stored there.
    this.resetLegalNotice();
    this.$store.commit('RESET_ANSWERS')

    this.getQuestions();
    window.scrollTo(0, 0);
  },
  methods: {
    checkQuestion(data) {
      if(data === 'zurich_4_24_2_financial-loss') {
        // Zurich - special risk question conditions
        let inputValue = document.getElementById(data).value
        if(inputValue >= 5000) {
          if(this.nextDisabled != true) {
            this.nextDisabled = true
            this.isProblemModal = true;
            return false
          }
        } else {
          this.nextDisabled = false
        }
      }
    },
    getFieldClass(fieldName) {
      const classes = {};

      if (this.fields[fieldName]) {
        classes['is-danger'] = this.errors.has(fieldName);
        classes['touched'] = this.fields[fieldName].dirty;
      }

      if (this.formStyle && this.formStyle === 'row') {
        classes['col-md-6'] = true;
      }

      return classes;
    },
    openToolTip(_content) {
      this.tooltipContent = _content;
      this.showModal = true;
    },
    goBack() {
      if (this.currentQuestion > 0) {
        this.currentQuestion--;
      } else {
        eventBus.$emit('goBack');
      }

      this.getQuestions();
    },
    goForward({skipWarning} = {}) {
      const questionsLengthBefore = this.questions.length

      this.getQuestions();
      this.warningModalContent = '';
      this.showWarningModal = false;

      if (questionsLengthBefore > this.questions.length) {
        let lastAnsweredIndex
        for (let i = this.questions.length; i > 0; i--) {
          if (Object.keys(this.inputs.answers).some(answer => answer ===  'q-' + this.questions[i-1].id)) {
            lastAnsweredIndex = this.questions.indexOf(this.questions[i-1])
            break
          }
        }
        this.currentQuestion = lastAnsweredIndex + 1
      }

      if (skipWarning && questionsLengthBefore !== this.questions.length && this.currentQuestion <= this.questions.length - 1) {
        // skip & do nothing

      } else if (this.currentQuestion < this.questions.length - 1) {
        this.currentQuestion++;
        window.scrollTo(0, 0);

        setTimeout(() => {
          this.isAnswerButtonDisabled = false;
        }, 1500);

      } else {
        document.getElementById('step_submit')
          .click();
      }
    },
    isForwardDisabled() {
      let currentQuestionId = this.questions[this.currentQuestion].id;
      return this.showWrongAnswerNote || !this.inputs.answers['q-' + currentQuestionId];
    },
    getQuestions() {
      this.questions = this.isLegal
        ? this.$store.state.checkout.application.legal.questions
        : this.$store.getters.filteredQuestions();

      // auto answer question with only one answer
      this.questions.forEach((question) => {
        if (question.answers && question.answers.length === 1) {
          this.$set(this.inputs.answers, `q-${question.id}`, question.answers[0].value);
        }
      });
    },
    answerQuestion(answer) {
      if (this.isAnswerButtonDisabled) return;
      let noCount = 0;

      this.isAnswerButtonDisabled = true;

      this.$set(this.inputs.answers, 'q-' + this.question.id, answer);

      // Allianz - special risk question conditions
      // if already two other questions answered with no then quit the process
      if (this.inputs.insurer === 'allianz') {
        for (let questionNumber = 2; questionNumber <= 8; questionNumber++) {
          if (this.inputs.answers[`q-allianz_${questionNumber}`] === 'no') {
            noCount++;
          }
        }

        if (noCount >= 3) {
          this.showWrongAnswerNote = true;
          this.isAnswerButtonDisabled = false;
          this.isProblemModal = true;
          return false
        }
      }

      if (this.question.deselectModule && this.question.deselectModule[answer] && this.question.deselectModule[answer].length) {
        this.question.deselectModule[answer].forEach((module) => {
        this.$store.commit('DESELECT_MODULE', {
          insurer: this.inputs.insurer,
          module
        });
      });
      }

      if (this.question.expect) {
        if (Array.isArray(this.question.expect)) {
          if (this.question.expect.indexOf(answer) === -1) {
            this.showWrongAnswerNote = this.isBroker && !this.isWWW ? false : true;
            this.isAnswerButtonDisabled = false;
            this.isProblemModal = this.isBroker && !this.isWWW ? false : true;
            if (!this.isBroker && this.isWWW) {
              return false
            } else if (this.isBroker && !this.isWWW) {
              this.wrongAnswerNotice();
            }
          }
        } else {
          if (answer !== this.question.expect) {
            this.showWrongAnswerNote = this.isBroker && !this.isWWW ? false : true;
            this.isAnswerButtonDisabled = false;
            this.isProblemModal = this.isBroker && !this.isWWW ? false : true;
            if (!this.isBroker && this.isWWW) {
              return false
            } else if (this.isBroker && !this.isWWW) {
              this.wrongAnswerNotice();
            }
          }
        }

        this.showWrongAnswerNote = false;
        this.goForward();
      } else if (this.question.form && this.question.form[answer]) {
        this.isAnswerButtonDisabled = false;
      } else if (this.question.warning && this.question.warning[answer]) {
        this.warningModalContent = this.question.warning[answer];
        this.showWarningModal = true;
        this.isAnswerButtonDisabled = false;
      } else {
        this.showWrongAnswerNote = false;
        this.isAnswerButtonDisabled = false;
        this.goForward();
      }
    },
    wrongAnswerNotice() {
      if(this.$toasted) {
        this.$toasted.show(`Mit dieser Angabe erfüllen Sie nicht die IT-Mindestanforderungen des Versicherers. Der Antrag kann trotzdem eingereicht werden und wird vom Versicherer individuell geprüft.`, {
          theme: 'outline',
          type:'error',
          className: 'py-2',
          position: 'bottom-right',
          duration: 6000,
        });
      }
    },
    isSelected(answer) {
      let currentQuestionId = this.questions[this.currentQuestion]?.id;
      return this.inputs.answers['q-' + currentQuestionId] === answer;
    },
    submitForm: function () {
      // check if PMA Broker
      this.step.completed = true;
      return eventBus.$emit('changeStepForward', this.step);
    },
    checkLegal1() {
      if(this.isBroker) {
        this.$store.state.checkout.application.broker.legalCheck1 = true;
      } else if (this.isWWW) {
        if(this.$store.state.checkout.application.public) {
          this.$store.state.checkout.application.public.legalCheck = true;
        } else {
          this.localLegalCheck = false
        }
      } else {
        console.error('couldnt save legal notice was read')
      }
    },
    resetLegalNotice() {
      if(this.isBroker) {
        this.$store.state.checkout.application.broker.legalCheck1 = false;
      } else if (this.isWWW) {
        if(this.$store.state.checkout.application.public) {
          this.$store.state.checkout.application.public.legalCheck = false;
        } else {
          this.localLegalCheck = false
        }
      } else {
        console.error('couldnt reset legal notice')
      }
    },
    submitQuestionForm() {
      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            this.goForward();
          }
        });
    },
    addSubsidiary(fieldName) {
      let {country, revenue} = this.formSubsidiaryData;

      this.inputs.form[this.question.id][fieldName].push({
        country,
        revenue
      });

      this.formSubsidiaryData = {
        country: '',
        revenue: ''
      };
    },
    addServiceProvider(fieldName) {
      let {street, city, country} = this.formData;

      if (typeof this.selectedServiceProvider === 'string') {
        this.inputs.form[this.question.id][fieldName].push({
          name: this.selectedServiceProvider,
          street,
          city,
          country
        });
      } else {
        this.inputs.form[this.question.id][fieldName].push({
          name: this.selectedServiceProvider.name,
          street,
          city,
          country
        });
      }

      this.formData = {
        name: '',
        street: '',
        city: '',
        country: ''
      };
      this.serviceProviderString = '';
      this.selectedServiceProvider = null;
    },
    removeServiceProvider(fieldName, index) {
      this.$delete(this.inputs.form[this.question.id][fieldName], index);
    },
    spSelected() {
      if (this.selectedServiceProvider) {
        this.formData = this.selectedServiceProvider;
      }
    },
    getAnswers(question) {
      return question.answers ? question.answers : [
        {
          label: 'Ja',
          value: 'yes'
        },
        {
          label: 'Nein',
          value: 'no'
        }
      ];
    }
  },
  destroyed: function () {
    eventBus.$off('submitStep');
    eventBus.$off('closeModalTooltip');
    eventBus.$emit('disableHdrNext', false);
  },
  components: {
    ProblemModal,
    Datepicker,
    Modal,
    Icon,
    Typeahead
  },
  mixins: [isStepAllowed]
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.questionaire {
  min-height: 300px;
}

.question {
  display: inline-block;
  margin-right: 10px;
  min-height: 220px;
  width: 100%;

  .button {
    margin-right: 0;
  }

  &__list {
    margin-bottom: 0;

    li {
      position: relative;
      padding-right: 24px;

      .circle-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &__list--sublist {
    list-style-type: circle;
  }

  &__text {
    padding-right: 24px;
    position: relative;
    white-space: pre-line;
    line-height: normal;

    .circle-icon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__answers {
    .btn {
      margin-bottom: 8px;
      width: 107px;
      border-radius: 8px;
      box-shadow: none;
    }

    .btn.btn-primary {
      color: #000;
      border: 0;
    }

    .btn:active {
      $thisColor: #efe9e9;
      background: $thisColor;
    }

    &--custom {
      .btn {
        width: 100%;
        white-space: pre-line;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter {
  transform: translateX(50px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}

.circle-icon {
  width: 18px;
  cursor: pointer;

  &__tooltip {
    position: absolute;
    z-index: $zIndexTooltip;
    top: calc(50% - 15px);
    left: calc(50% + 10px);
  }

  &--with-tooltip {
    cursor: pointer;
  }
}

#navigator {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  display: none;
}

@include media-breakpoint-down(xs) {
  #navigator {
    display: block;
  }

  #desktop_navigator {
    display: none !important;
  }

  .question {
    &__answers {
      display: flex;
      height: 50px;

      .btn {
        margin: 0;
        width: 50%;
        border-radius: 0;
      }
    }
  }
}

button.btn-style:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.form-group {
  width: 100%;

  textarea {
    width: 70%;
    height: 10em;
  }
}

</style>
