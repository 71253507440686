export default function logger(message, obj = null, type = 'info') {
  console.log( 
    `%c${message}`,
    `
        font-size: 1em;
        padding: 0.2em 0.2em;
        margin: 0.1em 0;
        color: ${ type === 'error' ? 'red' : '#007bff'};
        `
  )
  if (obj) {
    console.log(' data -> ', obj)
  }
}
