<template>
  <div class="form-group" :class="{'is-danger': hasError}">
    <label for="Mobiltelefon">Mobiltelefon <span v-if="required"> *</span></label>
    <VueTelInput
      @enter="isMobileNumberTouched = true"
      @input="numberChanged"
      @validate="validatePhone"
      @open="isVtiOpen = true"
      @close="isVtiOpen = false"
      class="form-control vue-tel"
      :class="[{ invalid: isPhoneInvalid }, inputClasses]"
      v-model="numberData"
      id="Mobiltelefon"
      name="Mobiltelefon"
      :defaultCountry="countryInterface || 'DE'"
      :onlyCountries="telOptions.allowedTelCountries"
      :inputOptions="telOptions"
      :validCharactersOnly="true"
      :dropdownOptions="{ showFlags: showFlags }"
      mode="international"
      data-vv-as=" "
      v-validate="phoneValidationType"
    >
      <template v-slot:arrow-icon>
        <span class="vti__dropdown-arrow">{{ isVtiOpen ? '▲' : '▼' }}</span>
      </template>
    </VueTelInput>
    <small v-show="isPhoneInvalid" class="error-note is-danger">
      {{ errors.first('Mobiltelefon') }}
    </small>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'

export default {
  name: 'PhoneForm',
  inject: ['$validator'],

  components: {
    VueTelInput,
  },
  data() {
    return {
      numberData: this.value, // pass the prop value to the v-model
      phoneValidation: {}, // for the validation object
      telOptions: {
        placeholder: 'Telefonnummer',
        allowedTelCountries: ['DE', 'AT', 'CH'],
      },
      isMobileNumberTouched: false,
      isVtiOpen: false,
    }
  },
  props: {
    formId: {
      // used to differentiate instances of comp if theres more of them
      type: String,
      default: 'phone-form',
      required: false,
    },
    value: {
      // initial phone nr from parent
      type: String,
      required: true,
    },
    showFlags: {
      // for validation
      type: Boolean,
      required: false,
      default: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputClasses: {
      type: Array,
      default() {return []}
    }
  },
  created() {
    const _self = this
    // extending the vee-validator
    const bindVueTelValidation = {
      getMessage: (field) => `${field} ist nicht korrekt`, // TODO to the i18
      validate() {
        return new Promise((resolve) => {
          const invalidity = _self.isPhoneInvalid // reading the vu-tel-input invalidity
          if (invalidity) {
            resolve({ valid: false })
          } else {
            resolve({ valid: true })
          }
        })
      },
    }
    this.$validator.extend('bindVueTelValidation', bindVueTelValidation)
  },
  computed: {
    countryInterface() {
      return this.$store.state.checkout.application.countryInterface.value
    },
    phoneValidationType () {
      return this.required ? 'required|bindVueTelValidation' : 'bindVueTelValidation'
    },
    hasError() {
        return this.errors.items.find( el => el.field === 'Mobiltelefon')
    },
    isPhoneInvalid() {
      if (this.isMobileNumberTouched) {
        return this.phoneValidation.valid === false || typeof this.phoneValidation.valid === 'undefined'

      } else {
        return this.phoneValidation.valid === false
      }
    },
  },
  methods: {
    // receiving the event fired anytime the vue-tel-input is used
    numberChanged() {
      this.$emit('numberChange', { number: this.numberData, id: this.formId })
    },
    // receiving the event fired anytime the vue-tel-input validity changes
    validatePhone(val) {
      this.phoneValidation = val
      // pass to the parent
      this.$emit('validate', val)
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      // watching changes in the parent comp triggered be third party
      this.numberData = newVal
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/form';

.error-note {
  position: absolute;
  right: -2px;
  text-align: right;
  color: $redTone;
  top: 20px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #fff;
}
.form-control.vue-tel {
  position:relative;
  display: flex;
  padding: 0px;
}
.form-group {
    position: relative;
}
</style>
