
const exceptions = { 
  list: ['services', 'ownDamage', 'obliegenheit', 'gefahrerhoehung'],
  map: {
    "services": "ownDamage",
    "ownDamage": "services",
    "obliegenheit": "gefahrerhoehung",
    "gefahrerhoehung": "obliegenheit"
  },
  isMerged: {
    services: false,
    ownDamage: false,
    obliegenheit: false,
    gefahrerhoehung: false
  },
  primarySubgroups: ['services', 'obliegenheit']
}

function isInclude (insurer, property, inputs) {
  if (!inputs) {
    return false
  }

  const result = isAvailable(insurer, property) || (isOptional(insurer, property) && isSelected(insurer, property, inputs))
  return result
}

function isAvailable(insurer, key) {
  if (insurer.properties[key]) {
    return insurer.properties[key].state === 'available' && !insurer.properties[key].disabled;
  } else {
    return false;
  }
}

function isOptional(insurer, key) {
  if (insurer.properties[key]) {
    return (insurer.properties[key].state === 'optional' || insurer.properties[key].state === 'optional-preselected') && !insurer.properties[key].disabled;
  } else {
    return false;
  }
}
function isSelected(insurer, key, inputs) {
  if(!inputs) {
    return
  }
  if(inputs.selectedModules[insurer.key] === undefined || inputs.selectedModules[insurer.key] === null) {
    return false
  }
  return inputs.selectedModules[insurer.key].includes(key)
}

function  getPropScore(insurerProperties, key, property) {
  if(insurerProperties[key]) {
    return insurerProperties[key][property] || null
  }
  return null
}

function   addScoreToKey (insProps, key, score, nestingLvl) {
  switch(nestingLvl) {
    case 'module':
      insProps.groups.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          subgroup.modules.forEach((module) => {
            module.key === key ? module.score = score : ''
            return
          })
        })
      })
      break
    case 'subgroup':
      insProps.groups.forEach((group, groupIdx) => {
        group.subgroups.forEach((subgroup, subgroupIdx) => {
          if (subgroup.key === key) {
            subgroup.sumScore = score
            let benchmark
            if (exceptions.list.includes(subgroup.key) && !exceptions.primarySubgroups.includes(subgroup.key)) {
              const primarySubgroup = exceptions.map[subgroup.key]
              benchmark = insProps.groups[groupIdx].subgroups.find((item) => item.key === primarySubgroup).benchmark
            } else {
              benchmark = insProps.groups[groupIdx].subgroups[subgroupIdx].benchmark
            }
            subgroup.precentageScore = calcPrecentageScore(score, benchmark)
          }
          return
        })
      })
      break
    case 'group':
      insProps.groups.forEach((group) => {
        group.key === key ? group.sumScore = score : ''
        return
      })
      break
  }
  // insProps ????
}

function  calcPrecentageScore(sumScore, benchmark) {
  return benchmark === undefined ? undefined : sumScore === benchmark ? 'winner' : sumScore / benchmark * 100
}

// EXPORT
export default {

  calculateScores(insurers, insuranceProperties2_0, overallBenchmark, inputs, updatedInsurerPrices) {
    const insurersWithScores = []
    // filter the insurers to the ones that were returneds from the API
    insurers = insurers.filter((insurer) => {
      return !!updatedInsurerPrices[insurer.key]
    })

    insurers.forEach((insurer) => {
      const insurProps = JSON.parse(JSON.stringify(insuranceProperties2_0)) // 
      insurProps.insurer = insurer // 

      let allGroupsSum = 0

      if(insurProps.groups.length === 0) { // 
        return
      } //

      insurProps.groups.forEach((group) => {
        let groupSum = 0

        group.subgroups.forEach((subgroup) => {
          let subgroupSum = 0

          if (exceptions.list.includes(subgroup.key)) { //
            const pairedSubgroup = group.subgroups.find(item => item.key === exceptions.map[subgroup.key]) //

            subgroup.modules.forEach((module) => {
              if (module.submodules) {
                module.submodules.forEach((submodule) => {
                  let submoduleScore = 0
                  isInclude(insurer, submodule.key, inputs) ? submoduleScore = getPropScore(insurer.properties, submodule.key, 'score') : 0 // 
                  subgroupSum += submoduleScore
                })
              }
              subgroupSum += isInclude(insurer, module.key, inputs) ? Number(getPropScore(insurer.properties, module.key, 'score')) : 0 // 
            })
            pairedSubgroup.modules.forEach((module) => {
              if (module.submodules) {
                module.submodules.forEach((submodule) => {
                  let submoduleScore = 0
                  isInclude(insurer, submodule.key, inputs) ? submoduleScore = getPropScore(insurer.properties, submodule.key, 'score') : 0 //
                  subgroupSum += submoduleScore
                })
              }
              subgroupSum += isInclude(insurer, module.key, inputs) ? Number(getPropScore(insurer.properties, module.key, 'score')) : 0 //
            })
            groupSum += !exceptions.isMerged[subgroup.key] ? Number(subgroupSum) : 0
            exceptions.isMerged[subgroup.key] = true
            exceptions.isMerged[exceptions.map[subgroup.key]] = true
          } else {
            subgroup.modules.forEach((module) => {
              if (module.submodules) {
                module.submodules.forEach((submodule) => {
                  let submoduleScore = 0
                  isInclude(insurer, submodule.key, inputs) ? submoduleScore = getPropScore(insurer.properties, submodule.key, 'score') : 0 //
                  subgroupSum += submoduleScore
                })
              }
              subgroupSum += isInclude(insurer, module.key, inputs) ? Number(getPropScore(insurer.properties, module.key, 'score')) : 0 //
            })
            groupSum += Number(subgroupSum)
          }
          addScoreToKey(insurProps, subgroup.key, subgroupSum, 'subgroup') //
        })

        allGroupsSum += Number(groupSum)
        addScoreToKey(insurProps, group.key, groupSum, 'group') //
      })
      insurProps.sumScore = allGroupsSum
      const defTotalBenchmark = overallBenchmark;
      insurProps.precentageScore = calcPrecentageScore(allGroupsSum, insurProps.benchmark || defTotalBenchmark)

      Object.keys(exceptions.isMerged).forEach(key => {
        exceptions.isMerged[key] = false
      })
      // saving the price here for later sorting purposes
      insurProps.grossPrice = updatedInsurerPrices[insurer.key].gross

      insurersWithScores.push(insurProps)
    })
    
    return insurersWithScores
  }
}