<template>
  <div class="auth-page__holder">
    <header class="auth-page__header">
      <div class="container">
        <div class="flex flex--between flex--v-center">
          <router-link :to="logoLink" class="main-nav__logo-holder-mobile" area-label="logo">
            <logo color="dark"/>
          </router-link>
          <div>
            <span class="mobile-hide">{{ $t('register.already_have_an_account') }}</span>
            <router-link to="/login?lead=alte-leipziger" class="link">
                {{ $t('register.sign_in') }}
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <div class="auth-page__logo" v-if="lead === 'alte-leipziger'">
      <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/ALH1.png">
    </div>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-6 mb-5">
          <div class="auth-page__form">
            <h1 class="auth-page__title">{{ title }}</h1>
            <p class="auth-page__sub-title">{{ $t('register.for_registration_enter_your_details_below') }}</p>
            <form action="#"
                  novalidate
                  @submit.prevent="register">

              <div class="auth-page__row" :class="{ 'has-error': $v.form.salutation.$error }">
                  <label for="salutation" class="input__holder">
                    <select class="input" name="salutation" id="salutation" v-model="form.salutation">
                      <option value="Herr">{{ $t('common.herr') }}</option>
                      <option value="Frau">{{ $t('common.frau') }}</option>
                    </select>  
                    <span class="input__label" :class="{ 'input__label--active': !!form.salutation }">
                    {{ $t('common.salutation') }} 
                    </span>          
                  </label>
              </div>

              <div class="auth-page__row" :class="{ 'has-error': $v.form.firstname.$error }">
                <label class="input__holder">
                  <input type="text" v-model="form.firstname" name="firstname" class="input" />
                  <span
                    class="input__label"
                    :class="{ 'input__label--active': !!form.firstname }"
                  >{{ $t('common.first_name') }}</span>
                </label>
              </div>

              <div class="auth-page__row" :class="{ 'has-error': $v.form.lastname.$error }">
                <label class="input__holder">
                  <input type="text" v-model="form.lastname" name="lastname" class="input" />
                  <span
                    class="input__label"
                    :class="{ 'input__label--active': !!form.lastname }"
                  >{{ $t('common.last_name') }}</span>
                </label>
              </div>

              <div class="auth-page__row" :class="{ 'has-error': $v.form.companyName.$error }">
                <label class="input__holder">
                  <input type="text" v-model="form.companyName" name="companyName" class="input" />
                  <span
                    class="input__label"
                    :class="{ 'input__label--active': !!form.companyName }"
                  >{{ $t('common.company_name') }}</span>
                </label>
              </div>
              <div v-if="lead === 'alte-leipziger'">
                <!-- datepick -->
                <div class="auth-page__row" :class="{ 'has-error': $v.form.startDate.$error }">
                  <div class="input__holder">
                    <date-picker
                      ref="datepicker"
                      format="dd.MM.yyyy"
                      input-class="input"
                      :language="de"
                      :disabled="false"
                      :monday-first="true"
                      v-model="form.startDate">
                    </date-picker>
                    <span
                        @click="openDatepicker()"
                        class="input__label"
                        :class="{ 'input__label--active': !!form.startDate }"
                      >{{ $t('common.startDate') }}
                    </span>
                  </div>
                </div>

                <!-- policy number -->
                <div class="auth-page__row" :class="{ 'has-error': $v.form.policyNumber.$error }">
                  <label class="input__holder">
                    <span
                      class="input__label"
                      :class="{ 'input__label--active': !!form.policyNumber }"
                    >{{ $t('common.policyNumber') }}</span>
                      <input-facade 
                        id="test"
                        class="input"
                        v-model="form.policyNumber"
                        :mask="['##-###-### ###']"
                        :masked="true"
                        name="register" 
                        type="text" />
                  </label>
                </div>
              </div>

              <div class="auth-page__row" :class="{ 'has-error': $v.form.email.$error }">
                <label class="input__holder">
                  <input type="email" v-model="form.email" name="email" class="input" />
                  <span
                    class="input__label"
                    :class="{ 'input__label--active': !!form.email }"
                  >{{ $t('common.email') }}</span>
                </label>
              </div>

              <div class="auth-page__row" :class="{ 'has-error': $v.form.password.$error }">
                <label class="input__holder">
                  <input type="password" v-model="form.password" name="password" class="input" />
                  <span
                    class="input__label"
                    :class="{ 'input__label--active': !!form.password }"
                  >{{ $t('common.password') }}</span>
                </label>
                <password-complexity :password="form.password"
                                    @score="scoreChanged"/>
              </div>

              <div class="auth-page__row" :class="{ 'has-error': $v.form.confirmPassword.$error }">
                <label class="input__holder">
                  <input
                    name="confirm-password"
                    type="password"
                    class="input"
                    v-model="form.confirmPassword"
                    id="confirm-password"
                  />
                  <span
                    class="input__label"
                    :class="{ 'input__label--active': !!form.confirmPassword }"
                  >{{ $t('common.confirm_password') }}</span>
                </label>
              </div>

              <div :class="{ 'has-error': $v.form.dataProtectionPolicyAccepted.$error }">
              <div class="checkbox">
                  <input name="accept_data-policy"
                        type="checkbox"
                        class="checkbox__input"
                        v-model="form.dataProtectionPolicyAccepted"
                        id="accept_data-policy"/>
                  <label for="accept_data-policy"
                        class="checkbox__label"
                        v-html="$t('common.privacy_policy_checkbox')">
                  </label>
                </div>
              </div>

              <div :class="{ 'has-error': $v.form.customerOf.$error }">
              <div class="checkbox">
                  <input name="customer-of"
                        type="checkbox"
                        class="checkbox__input"
                        v-model="form.customerOf"
                        id="customer-of"/>
                  <label for="customer-of"
                        class="checkbox__label"
                        v-html="$t('common.confirm_to_be_customer_of',{name:'Alte Leipziger'})">
                  </label>
                </div>
              </div>

              <div :class="{ 'has-error': $v.form.agb.$error }">
              <div class="checkbox">
                  <input name="agb"
                        type="checkbox"
                        class="checkbox__input"
                        v-model="form.agb"
                        id="agb"/>
                  <label for="agb"
                        class="checkbox__label"
                        v-html="$t('common.agb')">
                  </label>
                </div>
              </div>

              <div class="auth-page__buttons-holder">
                <button class="btn btn-primary btn--lg btn--block btn--uppercase"
                        :class="{ 'btn--pending': logInPending }"
                        type="submit"
                        :disabled="$v.form.$error || logInPending">
                  <icon name="sync"
                        class="btn__pending-icom"
                        color="white"
                        :rotate="logInPending"></icon>
                  {{ $t('register.register') }}
                </button>
              </div>
              <div class="text--center">
                {{ $t('register.already_have_an_account') }}
                <router-link to="/login?lead=alte-leipziger"
                            class="link">{{ $t('register.sign_in') }}</router-link>
              </div>
            </form>
          </div>
        </div>
         <div class="col-xs-12 col-sm-6 mb-5" ref="videoWrapper">
          <video-player class="video shadow-1"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true">
          </video-player>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
  import { required, email, minLength, sameAs, helpers } from "vuelidate/lib/validators";
  import {videoPlayer} from 'vue-video-player'
  import Logo from '@/components/logo/Logo.vue';
  import Icon from '@/components/icon/Icon.vue';
  import MainFooter from '@/components/footer/MainFooter.vue';
  import { COMPANY_NAME, MINIMUM_PASSWORD_LENGTH } from '@/constants';
  import PasswordComplexity from '@/components/password-complexity/PasswordComplexity.vue';
  import DatePicker from 'vuejs-datepicker';
  import {en, de} from 'vuejs-datepicker/dist/locale'
  import customLogoLink from '../helpers/customLogoLink'
  import {throttle} from 'lodash';
  import 'video.js/dist/video-js.css'

  import { InputFacade, facade, filter } from 'vue-input-facade'



  const policyNumberRegex =  helpers.regex('numeric', /^(\d{2}-\d{3}-\d{3} \d{3})$/)

  export default {
    name: 'register',
    components: {
      InputFacade,
      Logo,
      Icon,
      PasswordComplexity,
      MainFooter,
      DatePicker,
      videoPlayer
    },
    beforeRouteEnter(to, from, next) {
      if(to.query.lead === 'alte-leipziger') {
        next()
      } else {
        next ('/login')
      }
    },
    mounted() {
      if (this.$route.query.lead && this.$route.query.lead === 'alte-leipziger') {
        this.lead = this.$route.query.lead
      }
      window.addEventListener('resize', this.onResize);
      this.onResize();
    },
    data() {
      return {
        en: en,
        de: de,
        lead: null,
        form: {
          salutation: null,
          firstname: '',
          lastname: '',
          companyName: '',
          policyNumber: '',
          startDate: '',
          email: '',
          password: '',
          confirmPassword: '',
          dataProtectionPolicyAccepted: false,
          customerOf: false,
          agb: false,
        },
        title: this.$t('page.register_title', { name: COMPANY_NAME }),
        logInPending: false,
        passwordScore: null,
        playerOptions: {
          width: '360',
          responsive: true,
          autoplay: false,
          muted: false,
          language: 'de',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/videos/Die+Registrierung+Cyber+Portal_Update+2023.mp4'
          }],
          poster: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/poster-video-alte-leipziger.png'
        }
      };
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    computed:{
      logoLink () {
        return customLogoLink();
      }
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
    validations: {
      form: {
        salutation: { required },
        firstname: { required },
        lastname: { required },
        companyName: { required },
        startDate: { required },
        policyNumber: { required, policyNumberRegex },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
        },
        confirmPassword: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
          sameAsPassword: sameAs('password'),
        },
        dataProtectionPolicyAccepted: {
          isChecked(value) { return value === true}
        },
        customerOf: {
          isChecked(value) { return value === true}
        },
        agb: {
          isChecked(value) { return value === true}
        },
      },
    },
    methods: {
      onResize: throttle(function() {
        this.playerOptions.width = '' + (this.$refs.videoWrapper.clientWidth - 20);
      }, 100),
      openDatepicker () {
        this.$refs.datepicker.$children[0].$emit('showCalendar')
      },
      async register() {
        this.$v.$touch();

        if (this.$v.$error || this.logInPending) return;

        this.$v.$reset();
        this.logInPending = true;

        if (this.passwordScore < 2) {
          this.logInPending = false;
          return this.$toasted.show(this.$t('change_password.password_too_weak'), { type: 'error' });
        }
        this.form.lead = this.lead || null;
        const { success, message = 'Something went wrong' } = await this.$store.dispatch('REGISTER', this.form);
        this.logInPending = false;

        if (success) {
          const { success, message } = await this.$store.dispatch('LOG_IN', this.form);

          if ( success ) {
            this.$router.push('/#/');
          } else {
            this.$toasted.show(message, { type: 'error' });
          }
        } else {
          this.$toasted.show(message, { type: 'error' });
        }
      },
      scoreChanged(score) {
        this.passwordScore = score;
      },
    },
  }
</script>

<style lang="scss" scoped>
.shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

</style>
