<template>
  <div class="row">
    <div class="col-12">
      <div class="info">
        <div class="info__col-1">
          <div class="info__broker-logo">
            <OfferLogo :offer="offer" />
          </div>

          <ButtonComponent btn-style="accent" class="info__help-btn" @click.native="showRuckfrageModal"
            >Ich habe eine Rückfrage</ButtonComponent
          >
        </div>
        <div class="info__text">
          <p class="company mb-1" v-if="offer.company">{{ offer.company }}</p>
          <div class="company-title font-weight-bold mb-3">
            <template v-if="insurerSelected">
              Unverbindliches Angebot zur Cyber-Versicherung
            </template>
            <template v-else>
              Marktvergleich Cyber-Versicherung
            </template>
          </div>

          <p class="mb-1">{{ getSalutation() }}</p>

          <template v-if="!insurerSelected">
            <p>{{ reasonNoRecommendation() }}</p>
            <p>{{ commentNoRecommendation() }}</p>
          </template>

          <template v-else>
            <p v-if="offer.reason">{{ offer.reason }}</p>
            <p v-if="offer.comment">{{ offer.comment }}</p>
          </template>

          <p>
            Viele Gr&uuml;&szlig;e<br />
            {{ offer.broker.firstname }} {{ offer.broker.lastname }}
          </p>
        </div>
      </div>

      <OfferResume v-if="insurerSelected" class="resume mb-4" :selectedInsurer="selectedInsurer" :getInsurerLogoPath="getInsurerLogoPath" :getReadableProperty="getReadableProperty" :transformDeductible="transformDeductible" :offer="offer" :selectedInsurerKey="selectedInsurerKey" :paymentPeriod="paymentPeriod" :isAonOffer="isAonOffer" :inputs="inputs" />

    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue'
import OfferResume from './OfferResume.vue'
import OfferLogo from './OfferLogo.vue'
import StringHelper from '@/checkout/src/StringHelper'

export default {
  components: {ButtonComponent, OfferResume, OfferLogo},
  props: {
    offer: Object,
    selectedInsurer: Object,
    getSalutation: Function,
    transformDeductible: Function,
    getInsurerLogoPath: Function,
    getReadableProperty: Function,
    insurerSelected: Boolean,
    paymentPeriod: String,
    selectedInsurerKey: String,
    isAonOffer: Boolean,
    inputs: Object
  },

  methods: {
    showRuckfrageModal () {
      this.$emit('showRuckfrageModal')
    },

    reasonNoRecommendation() {
      return StringHelper.get(
        this.$store,
        'OFFER_REASON_NO_RECOMMENDATION',
        true
      )
    },

    commentNoRecommendation() {
      return StringHelper.get(
        this.$store,
        'OFFER_COMMENT_NO_RECOMMENDATION',
        true
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/form.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.info {
  position: relative;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2.5rem;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }

  &__col-1 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 402px;
    min-width: 402px;

    @include media-breakpoint-down(md) {
      flex-direction: row;
      width: 100%;
      min-width: 100%;
    }

    @include media-breakpoint-down(xs) {
      flex-direction: column;

    }
  }

  &__broker-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    background: $greyBlueDark;
    height: 100%;
    padding: 2rem;

    @include media-breakpoint-down(md) {
      width: 402px;
      min-width: 402px;
      height: 160px;
    }

    @include media-breakpoint-down(sm) {
      width: 290px;
      min-width: 290px;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      min-width: 100%;
    }
  }

  &__help-btn {
    font-size: 1rem;
    height: fit-content;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__text {
    padding: 3.5rem;
    background: $greyBlueDark;
    border-radius: 0.25rem;

    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }

    @include media-breakpoint-down(xs) {
      padding: 2rem 1.5rem;
    }
  }
}

.company-title {
  font-size: 1.125rem;
}

.col-1st {
  font-weight: bold;
  min-width: calc(402px - 48px);

  @include media-breakpoint-down(md) {
   min-width: calc(200px - 24px);
   width: calc(200px - 24px);
  }

  @include media-breakpoint-down(sm) {
    width: calc(160px - 24px);
  }
}

.row-simple {
  display: flex;
  width: 100%;
}

.col-simple {
  width: 100%;

  @include media-breakpoint-down(md) {
    min-width: 332px;
  }
}

.col-details {
  .detail {
    display: flex;

    .label {
      width: 170px;
      margin-right: 0.5rem;
    }

    .value {
      font-weight: bold;
    }
  }

  .detail + .detail {
    margin-top: 1rem;
  }

  svg {
    margin-right: 1rem;
  }
}

.prices {
  .price + .price {
    margin-top: 1.5rem;
  }
  .price {
    .value {
      font-size: 1.75rem;
      font-weight: bold;
    }
  }
}

// old:
.contract-details {
  padding: 0;

  &__item {
    padding: 0px 28px;
    min-height: 200px;
    border-top: 0;
    flex: 1 auto;

    @include media-breakpoint-down(xs) {
      width: 33.333%;
    }

    + .contract-details__item {
      @include media-breakpoint-down(xs) {
        border-left: 2px solid #fff;
      }
    }

    &:first-child {
      margin-left: -28px;

      .contract-detail__header {
        margin-left: -28px;
        margin-right: -56px;

        @include media-breakpoint-down(xs) {
          margin-right: -28px;
        }
      }
    }

    &:nth-child(2) {
      .contract-detail__header {
        margin-left: -56px;
        margin-right: -56px;

        @include media-breakpoint-down(xs) {
          margin-left: -28px;
          margin-right: -28px;
        }
      }
    }

    &:last-child {
      margin-right: -28px;

      .contract-detail__header {
        margin-left: -56px;
        margin-right: -28px;

        @include media-breakpoint-down(xs) {
          margin-left: -28px;
          margin-right: -28px;
        }
      }
    }
  }
}
</style>
