<template>
  <div v-if="notifications.length" class="header-notif-overlay">
    <div class="header-notifications">
      <div v-for="(notif, i) in notifications" :key="i" class="header-notification" :style="{'background-color': notif.bgColor}">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="header-notif">
                <span class="header-notif-text" v-html="notif.text">
                </span>
                <button class="header-notif-cta" type="button" @click="handleBtnClick(i)">
                  {{ notif.btn.title }}
                </button>
                <button v-if="notif.denyBtn" class="header-notif-cta" type="button" @click="deny">
                  {{ notif.denyBtn.title }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {

    computed: {
      user () {
        return this.$store.state.account.user
      },
      avvChecked () {
        return this.$store.state.account.user.orderProcessingChecked || false
      },
      notifications () {
        const array = []
        // is being taken care of through the modal component now.
        // if (this.user.role === 'policyHolder' && (!this.avvChecked || this.avvChecked === undefined)) {
        //   array.push({
        //     bgColor: '#b4b4b4',
        //     text: 'Bitte akzeptieren Sie unseren neuen Auftragsdatenverarbeitungsvertrag (AVV). Sollten Sie dies ablehnen ist es leider nicht möglich unsere Präventionstrainings zu nutzen. Für Rückfragen stehen wir Ihnen jederzeit gerne per Mail an <a href="mailto:info@cyberdirekt.de" class="link-primary">info@cyberdirekt.de</a> oder telefonisch unter <a href="tel:03040366036" class="link-primary">030/40366036</a> zur Verfügung. ',
        //     btn: {
        //       title: 'Jetzt durchlesen',
        //       clickEmitter: 'openAvvModal'
        //     },
        //     denyBtn: {
        //       title: 'Jetzt Abmelden',
        //       clickEmitter: 'logout'
        //     }
        //   })
        // }
        return array
      }
    },

    mounted() {
      // this.initNotifications()
    },

    methods: {
      handleBtnClick(index) {
        this.$emit(this.notifications[index].btn.clickEmitter)
      },

      deny() {
        this.notifications = []
        this.$store.dispatch('LOG_OUT')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/variables';
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  .header-notif-overlay {
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background: #000;
      z-index: 1005;
      opacity: 0.5;
    }
  }

  .header-notifications {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1005;
  }

  .header-notification {
      z-index: 1;
      left: 0;
      right: 0;
      top: 62px;
      line-height: 30px;
      background: $secondary;
      color: #fff;
      line-height: 1.4;
      border-bottom: 1px solid #fff;
      margin-bottom: 1rem;
      padding: 0.675rem 1rem;

      @include media-breakpoint-up(md) {
        top: 68px;
      }

      @include media-breakpoint-up(lg) {
        top: 77px;
      }
  }

  .header-notification + .header-notification {
    margin-top: -17px;
  }

  .header-notif-cta {
      padding: 4px 8px;
      // margin-left: 8px;
      background: none;
      color: #fff;
      border-radius: 3px;
      border: 2px solid rgba(255, 255, 255, .8);
      font-weight: bold;
      line-height: 1;
      cursor: pointer;

      & + & {
        margin-left: 0.75rem;
      }
  }
  .header-notif-text {
    margin-right: 0.5rem;
  }

  .header-notif {
    @include media-breakpoint-down(sm) {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }
</style>
