<template>
  <div :class="{'is-offer': isOffer}" >
    <table class="print-scores" :class="{'print-scores--price-perform-winner': isPricePerformWinner()}">
    <tbody v-if="isScoreVisible">
      <tr>
        <td class="first-col-td">
          <div class="first-col td-col">
            <div class="td-col__content scores-info">
              <b>Rating</b>
              <div class="text-sm mt-1">
                <p>
                  Informationen zu der Methodik unseres Leistungsvergleiches* finden Sie <ButtonComponent
                  btnStyle="link-primary"
                  btn-size="xs"
                  font-size="0.9rem"
                  behavior="externalLink"
                  :link="`${CLIENT_BASE}methodik-vergleich`"
                >
                  hier</ButtonComponent>.
                </p>
              </div>
            </div>
          </div>
        </td>
        <td
          v-for="(insurer, insIndex) in insurers"
          :key="insurer.key"
          :class="`td--${insurers.length}`"
          class="td">
          <div
            class="td-col td-col-content"
            :class="{'td-col--disabled': insurer.disabled}">
            <ProgressRing
                :isPrintMode="true"
                :radius="70"
                :progress="insurersWithScores[insIndex] ? insurersWithScores[insIndex].precentageScore : null"
                :stroke="16"
              ></ProgressRing>
              <!-- <ProgressRing
                :radius="70"
                :progress="generateRandomScore(80, 100)"
                :stroke="16"
              ></ProgressRing> -->
            </div>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
import ProgressRing from '../block/ProgressRing.vue'
import agencyKey from '../../../mixins/agencyKey';
import ButtonComponent from '../ui/ButtonComponent.vue';

export default {
  components: {
    ProgressRing,
    ButtonComponent,
  },

  props: {
    insurers: Array,
    getPriceToolTip: Function,
    isPricePerformWinner: Function,
    isInsurerPricePerformWinner: Function,
    isLoading: Boolean,
    isOffer: Boolean,
    mode: String
  },

  computed: {
    isScoreVisible: {
      get() {
        return this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', newValue)
      }
    },
    isScoreNotVisible: {
      get() {
        return !this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', !newValue)
      }
    },
    insurersWithScores() {
      return this.$store.getters.getInsurersWithScoresSorted
    },
    CLIENT_BASE() {
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE;
      return CLIENT_BASE.replace(/\/?$/, '/')
    }
  },

  methods: {
    generateRandomScore(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  },

  mixins: [agencyKey]
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.statistics-table.printable-properties {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 4px;
}

.print-scores {
  // width: 100vh;
  width: 99.3%;
  margin-bottom: 0;

  tr {
    // width: 100%;
    width: 100vh;
  }
  tr th {
    &:not(:last-child) {
      padding: 2px 6px 0 0;
    }

    &.price {
      min-width: 155px;
      max-width: 155px;

      &--disabled {
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }

    &.price.th-col.th-col--3 {
      // width: 17.5%;
    }
  }

  .first-col-td {
    // width: 100%;
  }

  .first-col-wrapper {
    width: 355px;
    max-width: 355px;
    min-width: 355px;
    // width: 30%;
    // width: 280px;
    margin-right: 4px;

  }

  .first-col.td-col {
    margin-right: 4px;
  }

  .td {
    &--1, &--2, &--3, &--4 {
      width: 17.5%;
    }
  }

  .td-col {
    &__content {
      padding: 32px 10px;
      background: #ebf0f2;
      border-radius: 0.25rem;
    }

    &--disabled {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }

  .td-col-content {
    display: flex;
    justify-content: center;
    // width: 17.5%;
    // min-width: 155px;
  }

  .th-col:not(:last-child) {
    padding: 2px 6px 0 0;
  }

  .th-col--1:not(:last-child) {
    width: 17.5%;
  }

  .th-col-content {
    padding: 0.5rem 0.25rem;
    text-align: center;
    border-radius: 0.25rem;
    color: #fff;
    background: #12658b;

  }
  .first-col.th-col {
    padding: 0.5rem 10px;
    font-weight: normal;
    background: #12658b;
    color: #fff;
    border-radius: 0.25rem;

  }
}

.is-offer {
  .print-scores {
    // width: 100%;

    tr {
      width: 100%;
    }
  }
}

.scores-info {
  hyphens: manual;
}

.price-perform-winner {
  position: relative;

  &::before {
    content: 'Preis-Leistungs-Sieger';
    font-weight: normal;
    color: #000;
    text-align: center;
    position: absolute;
    top: -1.5rem;
    left: 0;
    right: 0;
  }

  .th-col-content {
    background: #417E9B;
  }
}
</style>
