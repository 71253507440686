<template>
  <div class="cover-wrapper">
    <div class="header">
      <HeaderLogo :offer="offer" />
    </div>
    <div class="cover">
      <div class="cover__main">
        <div class="cover__company">
          <div class="company mb-1" v-if="offer.company">{{ offer.company }}</div>
        </div>
        <div class="cover__title">
          <template v-if="selectedInsurer">
            Unverbindliches Angebot zur Cyber-Versicherung
          </template>
          <template v-else>
            Marktvergleich Cyber-Versicherung
          </template>
        </div>
        <div class="cover__text">
          <div class="mb-1">{{ getSalutation() }}</div>
          <div v-if="offer.reason">{{ offer.reason }}</div>
          <div v-if="offer.comment">{{ offer.comment }}</div>
        </div>
        <div class="cover__bottom">     
            Viele Gr&uuml;&szlig;e <b><br />
            {{ offer.broker.firstname }} {{ offer.broker.lastname }}</b>
        </div>
        <OfferDetailsCard
          :isOffer="mode === 'offer'"
          :inputs="inputs"
          :revenueRange="revenueRange"
          :isPrint="true"
        ></OfferDetailsCard>
      </div>
    </div>
    <!-- hardcoded fix for regular checkout -->
    <!-- <div class="bottom-filler">
    </div> --> 
  </div>
</template>

<script>
import OfferDetailsCard from '../../offer/DetailsCard'
import HeaderLogo from './HeaderLogo.vue'

export default {
  components: {
    OfferDetailsCard,
    HeaderLogo
  },

  props: {
    mode: String,
    offer: Object,
    selectedInsurer: Object,
    getSalutation: Function,
    transformDeductible: Function,
    getInsurerLogoPath: Function,
    getReadableProperty: Function,
    insurerSelected: Boolean,
    paymentPeriod: String,
    selectedInsurerKey: String
  },

  data() {
    return {
      details: [
        {
          label: 'Deckungssumme',
          value: this.$options.filters.currency(this.offer.insuredSum, 0)
        },
        {
          label: 'Selbstbehalt',
          value:
            this.$options.filters.currency(
              this.transformDeductible(this.offer.deductible), 0
            ) +
            (this.offer.campaign === 'victor-fvdz' ? ' (50% reduziert)' : '')
        },
        {
          key: '24/7-incident-hotline',
          label: '24/7 Incident Hotline'
        },
        {
          key: 'kostenlose-onlineschulungen-fur-mitarbeiter',
          label: 'Online-Schulungen für Mitarbeitende'
        }
      ]
    }
  },

  computed: {
    inputs () {
      return this.$store.state.checkout.application.inputs
    },
    revenueRange () {
      return this.$store.getters.revenueRange
    },
  }
}
</script>

<style lang="scss" scoped>
.cover-wrapper {
  .bottom-filler {
    position: absolute;
    top: 27cm;
    left: 0;
    width: 88%;
    height: 588px;
    background: #ebf0f2;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 5mm;
      height: 100%;
      background: #f26722;
    }
  }
}

.header {
  position: absolute;
  background: #ebf0f2;
  z-index: 800;

  .logo {
    width: 150px;
    padding: 0.75rem 0;
    margin-top: 2.25rem;
    margin-left: 3rem;
  }
}
.cover {
  position: relative;
  padding-top: 15cm;
  padding-left: 3rem;
  background: #ebf0f2;
  height: calc(100vh - 0.5cm);
  width: 80%;
  page-break-after: always;
  z-index: 15;

  &__main {
    // height: 100%;
    width: 72.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 5mm;
      height: 70%;
      background: #31a9e0;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 5mm;
      height: 30%;
      background: #F26722;
    }
  }
  
  &__company {
    font-size: 1.25rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
  }

  &__text {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  &__bottom {    
    font-size: 1.15rem;
  }
}

header {
  // margin-left: 1rem;
}
</style>
