export default {
  computed: {
    // TODO: remove if unused - 'zurich-basis' tariff was disabled
    insurers() {
      let filter
      let checkingTarif = 'zurich-basis'

      if (
        this.$route.query &&
        this.$route.query.insurer &&
        this.$route.query.insurer.length
      ) {
        filter = this.$route.query.insurer
      } else if (
        this.mode === 'offer' &&
        this.$store.state.checkout.application.offer.selectedInsurer &&
        this.$store.state.checkout.application.offer.selectedInsurer.length
      ) {
        filter = this.$store.state.checkout.application.offer.selectedInsurer
      }

      let filteredAndSortedInsurers

      if (
        filter &&
        filter.length &&
        this.$route.query.insurer == checkingTarif
      ) {
        return this.$store.getters.filteredInsurers.filter(function (e) {
          return e.key == checkingTarif
        })
      } else if (filter && filter.length) {

        const results = this.$store.getters.filteredInsurers.filter((insurer) => filter.indexOf(insurer.key) > -1)
        return results
      }
      // this is here to cover the edge case where there are no insurers in the selection array
      // by default offer.selection should never be empty, but it seems we have these cases
      if (this.isOffer && this.offerSettings.selection.length === 0) {
          const defaultInsurers = this.$store.getters.filteredInsurers.filter(
            (insurer) => insurer.key === this.offerSettings.insurer
          )
          filteredAndSortedInsurers = defaultInsurers
      }

      return filteredAndSortedInsurers || this.$store.getters.filteredInsurers
    }
  }
}
