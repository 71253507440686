<template>
  <div
    class="offer"
    :class="[{ 'overflow-hidden': showRuckfrageModal || showModal, 'offer--insurer-selected': insurerSelected }, `offer-view-${currentView}`]"
  >

    <div class="offer-frame">
      <div class="offer-frame__title offer-frame__title--left">
        Kundenansicht
      </div>
      <div class="offer-frame__title">
        Kundenansicht
      </div>
    </div>

<div class="pt-5" v-if="!offer">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-warning">
            <icon name="warning" color="brown"></icon>
            Das Angebot ist nicht mehr verfügbar. Bitte kontaktieren Sie Ihren
            Makler.
          </div>
        </div>
      </div>
    </div>

    <div class="header-outer d-none d-print-block">
      <div class="header">
          <OfferLogo :offer="offer" />
      </div>
    </div>

    <div class="container-xxl page-break-after d-print-none offer-header" v-if="offer">
      <div class="row" v-if="success">
        <div
          class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-2 offset-lg-5 mb-3"
        >
          <img
            src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_green.svg"
          />
        </div>
        <div class="col-12 text-center">
          <h2>Vielen Dank.</h2>
          <p>
            Ihre Angaben wurden an {{ offer.broker.firstname }}
            {{ offer.broker.lastname }} weitergeleitet.
          </p>
        </div>
      </div>
      <OfferHeader
        v-if="offer && !success"
        :offer="offer"
        :selectedInsurer="selectedInsurer"
        :getSalutation="getSalutation"
        :getInsurerLogoPath="getInsurerLogoPath"
        :insurerSelected="insurerSelected"
        :transformDeductible="transformDeductible"
        :getReadableProperty="getReadableProperty"
        :paymentPeriod="paymentPeriod"
        :selectedInsurerKey="selectedInsurerKey"
        :isAonOffer="isAonOffer"
        :inputs="inputs"
        @showRuckfrageModal="showRuckfrageModal = true"
      />
      <div v-if="!success" class="row title-tabs-row d-print-none">
        <div class="col-12 title-tabs">
        
          <template v-if="!insurerSelected">
            <div class="title" v-if="currentView === 'marketcomparison'">
              Leistungsübersicht
            </div>

            <ButtonComponent
                v-else-if="!specialOffer"
                :btn-style="'primary'"
                @click.native="currentView = 'marketcomparison'"
                class="title"
                >Leistungsübersicht</ButtonComponent
              >
          </template>

          <div class="toolbar d-print-none" :class="{'toolbar--ins-selected': insurerSelected}">
            
            <ButtonComponent
              v-if="insurerSelected && currentView === 'submition'"
                @click.native="!isDisabled ? send() : ''"
                type="button"
                :disabled="isDisabled"
                class="next-step"
                btn-style="accent-border"
                behavior="button"
                >Antrag für mich einreichen</ButtonComponent>

            <ButtonComponent
              class="next-step"
              v-else-if="insurerSelected"
              btn-style="accent-border"
              @mouseover.native="onQuestionsWarning(true)"
              @mouseleave.native="onQuestionsWarning(false)"
              @click.native="handleNextStep"
              >
                <template v-if="currentView === 'questions'">Zu den Vertragsdaten</template>
                <template v-else>Risikofragen beantworten</template>
              </ButtonComponent>

            <template v-if="insurerSelected && currentView !== 'marketcomparison'">
              <ButtonComponent
                  :btn-style="'primary'"
                  @click.native="handlePrevStep"
                  >
                <template v-if="currentView === 'submition'">Zurück zu den Risikofragen</template>
                <template v-else>Zurück zur Leistungsübersicht</template>
              </ButtonComponent>
            </template>
            <ButtonComponent
              v-if="!insurerSelected"
              :btn-style="currentView === 'questions' ? 'dark-blue' : 'primary'"
              @click.native="currentView = 'questions'"
            >
              <span v-if="!insurerSelected">Riskofragen vergleichen</span>
              <span v-else>Risikofragen &#38; Vertragsdaten</span>
            </ButtonComponent>
            <ButtonComponent
              :btn-style="currentView === 'documents' ? 'dark-blue' : 'primary'"
              @click.native="currentView = 'documents'"
            >
              Versicherungs&shy;bedingungen
            </ButtonComponent>

            <ButtonComponent
              class="download-pdf d-print-none"
              :link="downloadLink"
              behavior="externalLink"
              :icon="{
                name: 'document',
                position: 'left',
                width: 24,
                height: 24
              }"
            >
              <span>Download als PDF</span>
            </ButtonComponent>
  
            <!-- <ButtonComponent
              v-if="insurerSelected"
              btn-style="accent"
              @click.native="send()"
              @mouseover.native="onQuestionsWarning(true)"
              @mouseleave.native="onQuestionsWarning(false)"
              :disabled="isDisabled"
              >Antrag f&uuml;r mich einreichen</ButtonComponent
            > -->
          </div>
          <Steps v-if="insurerSelected" :setCurrentView="setCurrentView" :currentView="currentView" :validateQuestionsStep="validateQuestionsStep" />
        </div>
      </div>
    </div>

    <div
      v-if="
        offer && !success && (currentView === 'marketcomparison' || isPrintMode)
      "
      class="container--content"
      :class="{
        container: useContainerClass,
        'calc-container': !useContainerClass,
        'container-xxl': !isPrintMode
      }"
    >
    
    <PrintCover
      v-if="offer && !success"
      mode="offer"
      :offer="offer"
      :selectedInsurer="selectedInsurer"
      :getSalutation="getSalutation"
      :getInsurerLogoPath="getInsurerLogoPath"
      :insurerSelected="insurerSelected"
      :transformDeductible="transformDeductible"
      :getReadableProperty="getReadableProperty"
      :paymentPeriod="paymentPeriod"
      :selectedInsurerKey="selectedInsurerKey"
      :inputs="inputs"
      class="d-none d-print-block"></PrintCover>

      <div class="row row-fit">
        <div
          class="col-12 comparison-wrapper"
          v-show="currentView === 'marketcomparison' || isPrintMode"
        >
          <div
            class="sticky-shadow"
            :class="{ 'sticky-shadow--show': showShadow }"
          >
            <div class="shadow"></div>
          </div>
          <!-- <h2>Leistungs&uuml;bersicht</h2> -->
          <market-comparison
            :mode="'offer'"
            @insurerCountChanged="insurerCountChanged"
            :offerSettings="{...this.offer, insurerSelected}"
          >
            <QuestionsPrint
              v-if="isPrintMode"
              :offer="offer"
              :success="success"
              :insurerSelected="insurerSelected"
              :selectedInsurer="selectedInsurer"
              :currentView="currentView"
              :isPrintMode="isPrintMode"
              :filteredQuestions="filteredQuestions"
              :inputs="inputs"
              :getForm="getForm"
              :getAnswers="getAnswers"
              :getFieldClass="getFieldClass"
              :isSelected="isSelected"
              :checkAnswers="checkAnswers"
              :allQuestions="allQuestions"
              :offerInsurers="offerInsurers"
              :serviceProviderString="serviceProviderString"
              :selectedServiceProvider="selectedServiceProvider"
              :serviceProvider="serviceProvider"
              :formData="formData"
              class="page-break-before"
            />
          </market-comparison>
        </div>
      </div>

    </div>
    <div v-if="!isPrintMode" class="tab-views container-xxl">
      <div class="row">
        <div class="col-12">
          <div v-if="offer && !success && !isPrintMode">
            <div class="row">
              <div
                class="col-12 insurerSelected mb-4 questions questions--insurer-selected"
                v-show="insurerSelected && (currentView === 'questions' || isPrintMode)"
              >
                <div class="legal-wrapper">
                  <div class="question mb-5 manualbg" id="legalNoticeWarning">
                    <div class="row">
                      <div class="col-12 col-sm-8 question__text-wrapper">
                        <strong>Hinweis zur Verletzung vorvertraglicher Anzeigepflichten gemäß <template v-if="isAonOffer">§§ 16 ff. VersVG</template>
                        <template v-else>§§ 19 ff. VVG</template></strong>
                        <br><br>
                        <p class="question__text">
                          Zur Pr&uuml;fung des zu versichernden Risikos ist es notwendig, dass nachfolgende Fragen wahrheitsgemäß und vollständig durch den/die Antragsteller/in (Kunde) beantwortet werden. Es sind auch solche Umstände anzugeben, denen nur geringe Bedeutung beigemessen wird. Der Versicherungsschutz wird gefährdet, wenn unrichtige oder unvollständige Angaben gemacht werden. Bitte beachten Sie den gesonderten Hinweis zur Belehrung &uuml;ber die Folgen einer Verletzung der vorvertraglichen Anzeigepflicht vor  <a :href="insurerVvgFile.link">{{ insurerVvgFile.name }}</a>. Sofern die Fragen durch den/die Versicherungsmakler/in f&uuml;r den Kunden beantwortet werden, ist diese/r verpflichtet, den Kunden auf die vorgenannten Rechtsfolgen hinzuweisen.
                        </p>
                        <p class="question__text">
                          Ich habe den Hinweis zu den Folgen der Verletzung vorvertraglicher Anzeigepflichten gem&auml;ß 
                          <template v-if="isAonOffer">§§ 16 ff. VersVG</template>
                          <template v-else>§§ 19 ff. VVG</template> des Versicherers gelesen und akzeptiert.
                        </p>
                      </div>
                      <div class="col-12 col-sm-4 d-flex justify-content-end question__answers-wrapper">
                        <div class="question__answers text-right d-flex align-items-end ">
                          <button
                              type="button" @click="legalNoticeAccepted = true"
                              :class="{ 'btn-primary': legalNoticeAccepted }" class="btn btn-default mr-2" :disabled="false"
                            >
                            Ja
                          </button>
                          <button
                              type="button" @click="showWarningModal = true"
                              class="btn btn-default mr-2" :disabled="false"
                            >
                            Nein
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="questions__title mb-4">
                  <h2>Risikofragen</h2>
                </div>
                <div
                  class="question"
                  v-for="(question, questionIndex) in filteredQuestions"
                  :key="question.id"
                >
                  <div class="row">
                    <div class="col-12 col-sm-8 question__text-wrapper">
                      <div class="question__text" v-if="question.title">
                        <strong>Frage {{ questionIndex + 1 }}:</strong>&nbsp;{{
                          question.title
                        }}
                        <div
                          class="mt-2 alert alert-info"
                          v-if="isPrintMode && question.tooltip"
                        >
                          <img src="@/assets/images/info.svg" class="circle-icon" />
                          <span v-html="question.tooltip"></span>
                        </div>
                        <img
                          src="@/assets/images/info.svg"
                          class="circle-icon"
                          alt="Info"
                          v-else-if="question.tooltip"
                          @click="openToolTip(question.tooltip)"
                        />
                      </div>
                      <p class="question__text" v-if="question.text">
                        {{ question.text }}
                      </p>
                      <ul class="question__list" v-if="question.list">
                        <li
                          v-for="(item, index) in question.list"
                          :key="index"
                          :class="{ 'question__list--subtext': item.subText }"
                        >
                          <span
                            v-if="item.text"
                            v-html="$sanitizeHtml(item.text)"
                          ></span>
                          <span
                            v-if="item.subText"
                            v-html="$sanitizeHtml(item.subText)"
                          ></span>
                          <div
                            class="mt-1 alert alert-info"
                            v-if="isPrintMode && item.tooltip"
                          >
                            <img
                              src="@/assets/images/info.svg"
                              class="circle-icon"
                              alt="Info"
                            />
                            <span v-html="item.tooltip"></span>
                          </div>
                          <img
                            src="@/assets/images/info.svg"
                            class="circle-icon"
                            alt="Info"
                            v-else-if="item.tooltip"
                            @click="openToolTip(item.tooltip)"
                          />
                        </li>
                      </ul>
                      <div
                        class="alert alert-info"
                        v-if="
                          question.warning &&
                          question.warning[inputs.answers[`q-${question.id}`]]
                        "
                        v-html="question.warning[inputs.answers[`q-${question.id}`]]"
                      ></div>
                      <div v-if="question.form">
                        <div
                          class="form-group"
                          v-for="(field, fieldIndex) in getForm(question)"
                          :key="fieldIndex"
                          :class="getFieldClass(field.name)"
                        >
                          <label>{{ field.label }}</label>
                          <input
                            class="form-control"
                            type="text"
                            v-if="field.type === 'text'"
                            :id="field.name"
                            :name="field.name"
                            v-validate="{ required: true }"
                            v-model="inputs.form[question.id][field.name]"
                          />
                          <textarea
                            class="form-control"
                            v-else-if="
                              field.type === 'textarea'
                            "
                            :id="field.name"
                            :name="field.name"
                            v-validate="{ required: true }"
                            v-model="inputs.form[question.id][field.name]"
                          ></textarea>
                          <form
                            class="address-repeater"
                            v-else-if="
                              field.type === 'address-repeater' &&
                              question.form[inputs.answers[`q-${question.id}`]]
                            "
                            autocomplete="off"
                          >
                            <div class="row">
                              <div class="col-12 col-sm-6">
                                <div class="form-group">
                                  <label for="spName">Unternehmen</label>
                                  <input
                                    class="form-control sp-input"
                                    type="text"
                                    name="spName"
                                    id="spName"
                                    v-model="serviceProviderString"
                                  />
                                  <typeahead
                                    v-model="selectedServiceProvider"
                                    target=".sp-input"
                                    :data="serviceProvider"
                                    item-key="name"
                                  >
                                    <template slot="item" slot-scope="props">
                                      <li v-for="(item, index) in props.items" :key="index">
                                        <a
                                          class="dropdown-item"
                                          role="button"
                                          @click="
                                            props.select(item)
                                            spSelected()
                                          "
                                        >
                                          <span v-html="props.highlight(item)"></span>
                                        </a>
                                      </li>
                                    </template>
                                  </typeahead>
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <div class="form-group">
                                  <label for="street">Straße und Hausnummer</label>
                                  <input
                                    class="form-control"
                                    type="text"
                                    name="street"
                                    id="street"
                                    v-model="formData.street"
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <div class="form-group">
                                  <label for="city">Plz und Ort</label>
                                  <input
                                    class="form-control"
                                    type="text"
                                    name="city"
                                    id="city"
                                    v-model="formData.city"
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <div class="form-group">
                                  <label for="country">Land</label>
                                  <input
                                    class="form-control"
                                    type="text"
                                    name="country"
                                    id="country"
                                    v-model="formData.country"
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              class="btn btn-primary mb-3"
                              type="button"
                              @click="addServiceProvider(question.id, field.name)"
                            >
                              hinzuf&uuml;gen
                            </button>
                            <table
                              class="table"
                              v-if="inputs.form[question.id][field.name]"
                            >
                              <thead>
                                <tr>
                                  <th>Dienstleister</th>
                                  <th>Adresse</th>
                                  <th class="text-center">Löschen</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(provider, index) in inputs.form[
                                    question.id
                                  ][field.name]"
                                  :key="index"
                                >
                                  <td>{{ provider.name }}</td>
                                  <td>
                                    {{ provider.street }}<br />{{ provider.city
                                    }}<br />{{ provider.country }}
                                  </td>
                                  <td class="text-center">
                                    <button
                                      class="btn btn-icon"
                                      type="button"
                                      @click="
                                        removeServiceProvider(
                                          question.id,
                                          field.name,
                                          index
                                        )
                                      "
                                    >
                                      <icon name="cancel" color="secondary"></icon>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                          <form
                            class="subsidiary-repeater"
                            v-else-if="
                              field.type === 'subsidiary-repeater' &&
                              question.form[inputs.answers[`q-${question.id}`]]
                            "
                            autocomplete="off"
                          >
                            <div class="row">
                              <div class="col-12 col-sm-6">
                                <div class="form-group">
                                  <label for="city">Land</label>
                                  <input
                                    class="form-control"
                                    type="text"
                                    name="country"
                                    id="country"
                                    required
                                    v-model="formSubsidiaryData.country"
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-sm-6">
                                <div class="form-group">
                                  <label for="country">Umsatz</label>
                                  <input
                                    class="form-control"
                                    type="text"
                                    name="revenue"
                                    id="revenue"
                                    required
                                    v-model="formSubsidiaryData.revenue"
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              class="btn btn-secondary mb-3"
                              type="button"
                              @click="addSubsidiary(question.id, field.name)"
                            >
                              hinzufügen
                            </button>
                            <table
                              class="table"
                              v-if="inputs.form[question.id][field.name].length > 0"
                            >
                              <thead>
                                <tr>
                                  <th>Land</th>
                                  <th>Umsatz</th>
                                  <th class="text-center">Löschen</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(subsidiary, index) in inputs.form[
                                    question.id
                                  ][field.name]"
                                  :key="subsidiary.country"
                                >
                                  <td>{{ subsidiary.country }}</td>
                                  <td>{{ subsidiary.revenue }}</td>
                                  <td class="text-center">
                                    <button
                                      class="btn btn-icon"
                                      type="button"
                                      @click="
                                        removeServiceProvider(
                                          question.id,
                                          field.name,
                                          index
                                        )
                                      "
                                    >
                                      <icon name="cancel" color="secondary"></icon>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 d-flex justify-content-end question__answers-wrapper">
                      <div class="question__answers text-right"
                        v-if="!(question.form && question.form.fields)"
                        >
                        <button
                          type="button"
                          @click="answerQuestion(question.id, answer.value)"
                          :class="{
                            'btn-primary': isSelected(question.id, answer.value),
                            'btn-danger': incorrectAnswers.find(a => a === question.id)
                          }"
                          v-for="(answer, index) in getAnswers(question)"
                          v-if="answer.label && answer.label !== ''"
                          class="btn btn-default mr-2"
                          :disabled="checkAnswers"
                          :key="index"
                        >
                          {{ answer.label }}
                        </button>
                        <img
                          v-if="incorrectAnswers.find(a => a === question.id)"
                          src="@/assets/images/info_red.svg"
                          class="circle-icon circle-icon--warning"
                          alt="warning"
                          v-tooltip.top-start="'Mit dieser Angabe erfüllen Sie nicht die IT-Mindestanforderungen des Versicherers. Der Antrag kann trotzdem eingereicht werden und wird vom Versicherer individuell geprüft.'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 mb-4"
                v-show="!insurerSelected && (currentView === 'questions' || isPrintMode)"
              >
                <div v-for="(insurerQuestions, index) in allQuestions" class="ins-questions" :key="index">
                  <h2 class="mb-2">Risikofragen - {{ insurerQuestions.insurer }}</h2>
                  <div
                    class="question"
                    v-for="(question, questionIndex) in insurerQuestions.questions"
                    :key="question.id"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="question__text" v-if="question.title">
                          <strong>Frage {{ questionIndex + 1 }}:</strong>&nbsp;{{
                            question.title
                          }}
                          <div
                            class="mt-2 alert alert-info"
                            v-if="isPrintMode && question.tooltip"
                          >
                            <img src="@/assets/images/info.svg" class="circle-icon" />
                            <span v-html="question.tooltip"></span>
                          </div>
                          <img
                            src="@/assets/images/info.svg"
                            class="circle-icon"
                            alt="Info"
                            v-else-if="question.tooltip"
                            @click="openToolTip(question.tooltip)"
                          />
                        </div>
                        <p class="question__text" v-if="question.text">
                          {{ question.text }}
                        </p>
                        <ul class="question__list" v-if="question.list">
                          <li
                            v-for="(item, index) in question.list"
                            :key="index"
                            :class="{ 'question__list--subtext': item.subText }"
                          >
                            <span
                              v-if="item.text"
                              v-html="$sanitizeHtml(item.text)"
                            ></span>
                            <span
                              v-if="item.subText"
                              v-html="$sanitizeHtml(item.subText)"
                            ></span>
                            <div
                              class="mt-1 alert alert-info"
                              v-if="isPrintMode && item.tooltip"
                            >
                              <img
                                src="@/assets/images/info.svg"
                                class="circle-icon"
                                alt="Info"
                              />
                              <span v-html="item.tooltip"></span>
                            </div>
                            <img
                              src="@/assets/images/info.svg"
                              class="circle-icon"
                              alt="Info"
                              v-else-if="item.tooltip"
                              @click="openToolTip(item.tooltip)"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex justify-content-end">
                <ButtonComponent
                  v-if="insurerSelected && currentView === 'questions'"
                  btn-style="accent"
                  @mouseover.native="onQuestionsWarning(true)"
                  @mouseleave.native="onQuestionsWarning(false)"
                  @click.native="handleNextStep"
                  >Zu den Vertragsdaten
                </ButtonComponent>
              </div>
              <div class="col-12 documents" v-show="(currentView === 'documents') & !isPrintMode">
                <h2 class="mb-4">
                  Versicherungsbedingungen
                </h2>
                <template v-for="(insurer, index) in offerInsurers">
                  <div v-if="insurer.filteredFiles && insurer.filteredFiles.length" :key="index"
                    class="insurer-documents">
                    <div class="insurer-documents__logo">
                      <img :src="insurer.logoUrl" />
                    </div>
                    <div class="insurer-documents__files">
                      <file-list :items="insurer.filteredFiles"></file-list>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            class="submition-view offer-bottom d-print-none"
            v-if="!success && offer && insurerSelected"
          >
            <form v-if="currentView === 'submition'">
              <div
                id="formAgreement"
                v-if="!success && insurerSelected"
                class="custom-control checkbox-accent custom-checkbox checkbox--vertical-start d-flex mb-3 d-print-none"
                :class="getFieldClass('agreedTermsConditions')"
              >
                <input
                  type="checkbox"
                  class="custom-control-input "
                  id="agreedTermsConditions"
                  name="agreedTermsConditions"
                  v-validate="'required:true'"
                  v-model="agreedTermsConditions"
                  required
                />
                <label class="custom-control-label" for="agreedTermsConditions">
                  Bitte geben Sie Ihre Zustimmung zu den
                  <ButtonComponent
                    behavior="externalLink"
                    link="https://www.cyberdirekt.de/datenschutzhinweise"
                    btn-style="link-primary"
                    >Datenschutzhinweisen</ButtonComponent
                  >, so dass wir Ihren Antrag verarbeiten können.
                </label>
              </div>

              <div class="form">

                <div class="form-top">
                  <div class="row gap-md d-print-none">
                    <div class="col-12 col-md-6 form-group cd-control mb-3">
                      <label>Versicherungsbeginn</label>
                      <datepicker
                        placeholder="heute"
                        format="dd.MM.yyyy"
                        input-class="form-control"
                        :language="de"
                        :disabled="datePickerDisable"
                        :monday-first="true"
                        :disabledDates="disabledDates"
                        v-model="inputs.startDate"
                      ></datepicker>
                    </div>
                    <div
                      v-if="inputs.dueDate"
                      class="col-12 col-md-6 form-group cd-control"
                    >
                      <label for="beginOfQuarter">Hauptfälligkeit</label>
                      <SelectComponent
                        id="beginOfQuarter"
                        v-model="beginOfQuarter"
                        :disabled="selectedDateDisabled"
                      >
                        <option
                          v-for="(possibleDueDate, index) in possibleDueDates"
                          :key="possibleDueDate.label + index"
                          :value="possibleDueDate.month"
                        >
                          {{ possibleDueDate.label }}
                        </option>
                      </SelectComponent>
                    </div>
                  </div>

                  <b-form-checkbox
                    v-if="
                      offer.insurer !== 'vhv' &&
                      offer.insurer !== 'axa-firmenflex' &&
                      offer.insurer !== 'zurich'
                    "
                    class="checkbox-accent checkbox--vertical-start mb-3 d-print-none"
                    id="dueData"
                    name="dueData"
                    :disabled="dueDateDisable"
                    v-model="inputs.dueDate"
                  >
                    <b>Abweichende Hauptfälligkeit gewünscht</b> <br />
                    (Rechnungsstellung zum Quartalsbeginn)
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-else-if="offer.insurer === 'vhv'"
                    class="checkbox-accent mb-3 d-print-none"
                    id="dueDataVHV"
                    name="dueDataVHV"
                    disabled
                    v-model="inputs.dueDate"
                  >
                    <b>Abweichende Hauptfälligkeit</b> <br />
                    Rechnungsstellung zum 01.01.
                  </b-form-checkbox>

                  <div class="form-divider form-divider--v2 d-print-none"></div>
                </div>

                <div class="form-main">
                  <!-- contract data -->
                  <div
                    v-if="
                      !success &&
                      offer &&
                      insurerSelected
                    "
                  >
                    <div class="row d-print-none m-0">
                      <ContractDataForm
                        class=" w-100 "
                        :isPhoneRequired="isPhoneRequired"
                        :isOffer="true"
                        :offer="offer"
                        @customerDataChange="customerDataChange"
                      />
                    </div>

                    <div class="form-divider d-print-none"></div>
                  </div>

                  <div class="form-row row payment-type gap-md d-print-none">
                    <div class="col-12 payment-method">
                      <div class="radio-title">Zahlung via</div>
                      <div class="d-flex">
                        <div v-if="invoiceVisible" class="custom-control cd-radio">
                          <label class="cd-label" for="radioInvoice">
                            <input
                              class="custom-control-input"
                              type="radio"
                              id="radioInvoice"
                              value="invoice"
                              name="paymentMethod"
                              :disabled="paymentMethodDisable"
                              v-model="inputs.paymentMethod"
                            /><span class="checkmark"></span>Rechnung</label
                          >
                        </div>

                        <div class="custom-control cd-radio">
                          <label class="cd-label" for="radioInvoice">
                            <input
                              class="custom-control-input"
                              type="radio"
                              id="radioDirectDebit"
                              value="directDebit"
                              name="paymentMethod"
                              :disabled="paymentMethodDisable"
                              v-model="inputs.paymentMethod"
                            /><span class="checkmark"></span>SEPA-Lastschrift</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <transition name="fade">
                    <SepaForm
                      v-if="inputs.paymentMethod === 'directDebit'"
                      :inputs="inputs"
                      :offer="offer"
                      :paymentMethodDisable="paymentMethodDisable"
                      :insurerSepaName="insurerSepaNames"
                      :insurerSepaId="insurerSepaIds"
                      :isAonOffer="isAonOffer"
                      @formChanged="updateSepaInputs"
                    />
                  </transition>

                  <div class="form-row row gap-md d-print-none">
                    <div class="col-12 col-lg-6 cd-control">
                      <label class="cd-label" for="">
                        <div class="mb-1">
                          Anmerkungen zum Antrag
                        </div>
                        <div class="font-weight-normal">
                          Hinweis: Die Inhalte werden individuell vom Risikoträger
                          geprüft und können zu einer Abweichung des beantragten
                          Versicherungsschutzes führen.
                        </div>
                      </label>
                      <textarea
                        class="form-control cd-textarea"
                        type="text"
                        id="additionalNotes"
                        name="additionalNotes"
                        rows="5"
                        v-model="inputs.additionalNotes"
                        :placeholder="addNotesPlaceholder"
                        @focus="($event) => $event.target.placeholder = ''"
                        @blur="($event) => $event.target.placeholder = addNotesPlaceholder"
                        @change="($event) => $event.target.value = $event.target.value.trim()"
                      />
                    </div>
                  </div>
                  
                  <ButtonComponent
                      @click.native="!isDisabled ? send() : ''"
                      :disabled="isDisabled"
                      type="button"
                      class="submit-btn"
                      btn-style="accent"
                      behavior="button"
                      >Antrag für mich einreichen</ButtonComponent
                    >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <transition name="modal">
      <modal v-if="showModal">
        <template slot="body">
          <div v-html="tooltipContent"></div>
        </template>
      </modal>
    </transition>

    <transition name="questionsModal">
      <modal :size="'sm'" v-if="showWarningModal" :closeable="false">
        <template slot="body">
          <div class="d-flex justify-content-center">
            <icon :width="'96'" name="warning" color="secondary"></icon>
          </div>
          <p class="text-center">
            Bitte beantworten Sie zunächst die Risikofragen und bestätigen Sie den Hinweis zur Verletzung vorvertraglicher Anzeigepflicht gemäß
            <template v-if="isAonOffer">§§ 16 ff. VersVG</template>
            <template v-else>§§ 19 ff. VVG</template>
          </p>
          <div class="d-flex justify-content-center">
            <button class="btn btn-secondary" @click="showWarningModal = false">
            OK
          </button>
          </div>
        </template>
      </modal>
    </transition>
    <transition name="modal">
      <ruck-frage-modal
        :show="showRuckfrageModal"
        @closeItself="showRuckfrageModal = false"
        @sendAnswer="sendFurtherQuestions"
      />
    </transition>

    <div class="loading-overlay" v-if="isLoadingOverlay" :class="{'loading-overlay--in': isLoadingOverlay}">
      <LoaderComponent></LoaderComponent>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus, { EVENT_TYPES } from '@/components/shared/EventBus.js'
import MarketComparison from '../checkout/src/components/Produktvergleich'
import Modal from '../checkout/src/Modal'
import ContractDataForm from '../checkout/src/components/ContractDataForm.vue'
import RuckFrageModal from '../components/modal/RuckFrageModal.vue'
import Datepicker from 'vuejs-datepicker'
import { de } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import holidays from '../checkout/src/holidays'
import { Typeahead } from 'uiv'
import Icon from '../checkout/src/Icon'
import store from '@/store/index'
import FileList from '@/checkout/src/FileList'
import SepaForm from '../components/offer/SepaForm.vue'
import OfferHeader from '../components/offer/OfferHeader.vue'
import ButtonComponent from '../components/ui/ButtonComponent.vue'
import SelectComponent from '@/components/ui/form/SelectComponent.vue'
import PrintCover from '../checkout/src/components/calculator-v2.0/parts/PrintCover.vue';
import QuestionsPrint from '@/components/offer/QuestionsPrint'
import OfferLogo from '@/components/offer/OfferLogo'
import Steps from '@/components/offer/Steps'
import LoaderComponent from '@/checkout/src/components/shared/LoaderComponent.vue';
import computedInsurers from '@/checkout/src/mixins/computedInsurers.js'
import { get as getValue } from 'lodash'

export default {
  // share validator with child component
  provide() {
    return { 
      parentValidator: this.$validator
    }
  },

  name: 'offer',
  components: {
    OfferHeader,
    OfferLogo,
    SepaForm,
    MarketComparison,
    ContractDataForm,
    Modal,
    Datepicker,
    Typeahead,
    Icon,
    FileList,
    RuckFrageModal,
    ButtonComponent,
    SelectComponent,
    Steps,
    PrintCover,
    QuestionsPrint,
    LoaderComponent
  },

  mixins: [computedInsurers],

  data() {
    return {
      mode: 'offer',
      legalNoticeAccepted: false,
      isQuestionsWarning: false,
      showWarningModal: false,
      showRuckfrageModal: false,
      de,
      showModal: false,
      agreedTermsConditions: false,
      isError: false,
      filteredQuestions: [],
      allQuestions: [],
      selectedInsurer: null,
      selectedInsurerKey: null,
      insurerCount: 0,
      containerMode: 'static',
      invoiceVisible: false,
      success: false,
      checkAnswers: false,
      datePickerDisable: false,
      paymentMethodDisable: false,
      dueDateDisable: false,
      selectedDate: 0,
      selectedDateDisabled: false,
      currentView: '',
      beginOfQuarter: true,
      possibleDueDates: [
        {
          month: 0,
          label: '01.01.'
        },
        {
          month: 3,
          label: '01.04.'
        },
        {
          month: 6,
          label: '01.07.'
        },
        {
          month: 9,
          label: '01.10.'
        }
      ],
      disabledDates: {
        to: new Date(),
        from: moment().add({months: 6}).toDate(), // disable any date after 6 months from now
        customPredictor: holidays.customPredictor
      },
      selectedServiceProvider: null,
      serviceProviderString: '',
      formData: {
        name: '',
        street: '',
        city: '',
        country: ''
      },
      formSubsidiaryData: {
        country: '',
        revenue: ''
      },
      contractFormData: {},
      useContainerClass: false,
      showShadow: false,
      isLoadingOverlay: false,
      addNotesPlaceholder: `Bitte beachten Sie, dass Anmerkungen kein automatischer Bestandteil des Antrags sind und lediglich durch CyberDirekt auf Vertragsrelevanz geprüft werden. Gegebenenfalls leiten wir Ihre Anmerkung weiter zur Bearbeitung an den Versicherer.
Bei Fragen kontaktieren Sie uns: makler@cyberdirekt.de`
    }
  },
  computed: {
    ...mapGetters(['offer']),
    isAonOffer () {
      const aonKeys = ['aon', 'aonaustriatest1', 'aonaustriatest2']
      const isAon = aonKeys.some(k => k === this.offer.key);
      return isAon
    },
    isPhoneRequired() {
      if (this.offer && this.offer.style) {
        return this.offer.style === 'synaxon'
      } else {
        return false
      }
    },
    insurerVvgFile() {
      const insurer = this.$store.state.checkout.application.insurers.find( i => i.key === this.offer.insurer)
      if(!insurer) {
        return []
      }
      return insurer.files.find(f => f.vvg)
    },
    checkoutPrices() {
      return this.$store.state.checkout.application.prices
    },
    insurerSepaNames() {
      if(this.checkoutPrices) {
        const names = {}
        Object.entries(this.checkoutPrices).forEach(([key, value]) => {
          if(value.sepa){
            names[key] = value.sepa.insurerSepaName
          } else {
            console.error('missing sepa NAME for: ', key)
            names[key] = ''
          }
        })
        return names
      }
      return {}
    },
    insurerSepaIds() {
      if(this.checkoutPrices) {
        const ids = {}
        Object.entries(this.checkoutPrices).forEach(([key, value]) => {
          if(value.sepa){
            ids[key] = value.sepa.insurerSepaId
          } else {
            console.error('missing sepa ID for: ', key)
            ids[key] = ''
          }
        })
        return ids
      }
      return {}
    },
    offerInsurers() {
      const insurers = [...this.insurers]
        .sort()
        .filter((insurer) => this.offer.selection.indexOf(insurer.key) > -1)

      insurers.forEach(insurer => {
          // filter files based on admin settings
          insurer.filteredFiles = insurer.files.filter(file => {
            return file.allowedLocations && file.allowedLocations.showInOffer
          })
        })
      return insurers
    },
    insurerSelected() {
      // see if insurer is among the TR20 dynamic data - if it is not it means it is unreleased and therefore we should not show it
      // as a recommendation at all      
      if(this.offer.insurer){
        const isInsurerReleased = this.$store.state.checkout.application.insurers.find(i => i.key === this.offer.insurer)
        if(!isInsurerReleased) {
          return false
        }
      }

      // check if selection is accepted by agency (e.g. exclusive list)
      const isSelectionAccepted = this.insurers.findIndex((insurerItem) => insurerItem.key === this.offer.insurer) > -1

      return this.offer.insurer && this.offer.insurer !== 'no-recommendation' && isSelectionAccepted
    },
    isPrintMode() {
      return this.$store.state.checkout.application.isPrintMode
    },
    inputs() {
      return this.$store.state.checkout
        ? this.$store.state.checkout.application.inputs
        : {}
    },
    specialOffer() {
      return (
        this.offer &&
        this.offer.style &&
        ['pma-2018'].includes(this.offer.style)
      )
    },
    isDisabled() {
      let lastLog = 'open'

      if (this.offer) {
        if (!this.offer.log || !this.offer.log.length || (this.selectedInsurer && this.selectedInsurer.disabled)) {
          lastLog = this.offer.state
        } else {
          lastLog = this.offer.log[this.offer.log.length - 1].state
        }
      }

      return ['open', 'sent'].indexOf(lastLog) === -1
    },
    paymentPeriod() {
      if (!this.offer) return ''
      const map = {
        yearly: 'Jährlich',
        halfyearly: 'Halbjährlich',
        quarterly: 'Vierteljährlich',
        monthly: 'Monatlich'
      }
      return map[this.offer.paymentPeriod]
    },
    serviceProvider() {
      return this.$store.state.checkout.application.serviceProvider
    },
    CLIENT_BASE() {
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE;
      return CLIENT_BASE.replace(/\/?$/, '/')
    },
    incorrectAnswers() {
      const qs = []
      
      this.filteredQuestions.forEach((question) => {
        if(question.expect) {
          const answer = this.inputs.answers[`q-${question.id}`]
          if(answer) {
            const isCorrect = question.expect.includes(answer)
            if (!isCorrect) {
              qs.push(question.id)
            }
          }
        }  
      })
      return qs
    },
    downloadLink() {
      const API_BASE = process.env.API_BASE || process.env.VUE_APP_API_BASE
      const date = moment().format('DD-MM-YYYY')
      const file = `CyberDirekt-Produktvergleich-${date}.pdf`
      let htmlSourceUrl = window.location.href.replace('/#/', '?print=true/#/')
      let agencyKey = ''
      // [DELETE] if we dont need link in footer
      let footerLinkTemplate = ''
      // hide/show duplicated header setted from backend 
      let showHeaderTemplate = false

      if (window.location.href.indexOf('/#/') > -1) {
        htmlSourceUrl = window.location.href.replace('/#/', '?print=true/#/')
      } else {
        htmlSourceUrl = window.location.href
      }
      // [DELETE] if we dont need link in footer
      footerLinkTemplate = '&footerLinkTemplate=' + encodeURIComponent(`<a href="https://www.cyberdirekt.de/methodik-vergleich" target="_blank" style="color: #31a9e0; font-weight: bold; text-decoration: none;">Weitere Informationen</a>`)
      

      if (getValue(this.$store, 'state.offer.offer.key')) {
        agencyKey = `&agencyKey=${encodeURIComponent(
          getValue(this.$store, 'state.offer.offer.key')
        )}`
      }

      return `${API_BASE}/pdf/generate-pdf?file=${encodeURIComponent(
        file
      )}${agencyKey}&format=download&url=${encodeURIComponent(htmlSourceUrl)}${footerLinkTemplate}&showHeaderTemplate=${showHeaderTemplate}`
      // [DELETE] ${footerLinkTemplate} if we dont need link in footer
    },
  },
  head: {
    title: function () {
      return {
        inner: 'Unverbindliches Angebot für eine Cyber-Versicherung'
      }
    }
  },
  async mounted() {
    if (!this.$store.state.checkout.application.isInitialDataLoaded) {
      this.isLoadingOverlay = true
      await this.$store.dispatch('GET_INITIAL_DATA')
      await this.$store.dispatch('CALCULATE_SCORES', this.offer.prices, true)
      this.isLoadingOverlay = false
    }
    this.$store.commit('RESET_INPUTS')
    this.$store.commit('RESET_INPUTS_WITH_MAP', this.offer)

    if (this.offer.answers) {
      this.$store.commit('SET_ANSWERS', this.offer.answers)
    } else {
      this.$store.commit('RESET_ANSWERS')
    }

    if (this.offer.billingDate) {
      let getBillingDate = new Date(this.offer.billingDate)
      let valueOfSelect =
        '0' +
        getBillingDate.getDate() +
        '.' +
        ('0' + (getBillingDate.getMonth() + 1)).slice(-2) +
        '.'

      this.possibleDueDates.forEach((data) => {
        if (data.label === valueOfSelect) {
          this.beginOfQuarter = data.month
          this.selectedDateDisabled = true
        }
      })
    }

    if (this.offer.startDate) {
      this.inputs.startDate = this.offer.startDate
      this.datePickerDisable = true
    }

    if (this.offer.paymentMethod) {
      this.inputs.paymentMethod = this.offer.paymentMethod
      this.paymentMethodDisable = true
    } else {
      // set default payment method
      this.inputs.paymentMethod = null
    }

    if (this.offer.dueDate) {
      this.inputs.dueDate = this.offer.dueDate
      this.dueDateDisable = true
    }

    window.addEventListener('scroll', this.scrollHandler, { passive: true })

    eventBus.$on(EVENT_TYPES.API_ERROR, ({ error }) => {
      this.isError = !!error
    })

    eventBus.$on('closeModalTooltip', (res) => {
      this.showModal = false
      this.showWarningModal = false
    })

    if (!this.offer) {
      return
    }

    this.getQuestions()
    this.selectedInsurer = this.$store.state.checkout.application.insurers.find(
      (insurer) => insurer.key === this.offer.insurer
    )
    this.inputs.sepa = this.offer.sepa || {}
    this.inputs.additionalNotes = this.offer.additionalNotes || ''

    if (this.selectedInsurer) {
      this.selectedInsurerKey = this.selectedInsurer.key
    }

    if (this.specialOffer) {
      this.currentView = 'questions'
      if (this.selectedInsurerKey) {
        this.offer.prices[this.selectedInsurerKey] = {
          net: this.offer.net,
          gross: this.offer.gross,
          tax: this.offer.gross - this.offer.net
        }
      }
      this.inputs.sepa.company = this.offer.company
      this.inputs.sepa.firstname = this.offer.firstname
      this.inputs.sepa.lastname = this.offer.lastname
    } else {
      this.currentView = 'marketcomparison'
    }

    if (
      ['basler', 'allianz'].indexOf(this.offer.insurer) > -1 &&
      ['yearly', 'halfyearly', 'quarterly'].indexOf(this.offer.paymentPeriod) >
        -1
    ) {
      this.invoiceVisible = true
    } else if (
      this.offer &&
      this.offer.paymentPeriod &&
      this.offer.paymentPeriod !== 'yearly'
    ) {
      this.invoiceVisible = false
      this.inputs.paymentMethod = 'directDebit'
    } else {
      this.invoiceVisible = true
    }
    if (this.offer.insurer === 'vhv') {
      this.selectedDate = 0
      this.selectedDateDisabled = true
      this.inputs.dueDate = true
    }

    if (this.offer && this.offer.id) {
      store.commit('SET_COUNTRY_INTERFACE', { agencyKey: this.offer.key, setFrom: 'offer' })
    }
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.scrollHandler, { passive: true })
  },
  watch: {
    'inputs.dueDate'(newValue) {
      if (newValue) {
        this.inputs.billingDate = moment()
          .add(1, 'year')
          .startOf('year')
          .toDate()
      } else {
        this.inputs.billingDate = moment().startOf('day').toDate()
      }
    },
    beginOfQuarter(newValue) {
      let billingDate = moment().month(newValue).date(1)

      if (billingDate.isBefore(moment())) {
        billingDate.add(1, 'year')
        this.inputs.billingDate = billingDate.toDate()
      } else {
        this.inputs.billingDate = billingDate.toDate()
      }
    }
  },
  beforeDestroy() {
    eventBus.$off(EVENT_TYPES.API_ERROR)
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch('getOffer', to.params.token)
      .then(() => {
        next()
      })
      .catch(() => {
        next()
      })
  },
  beforeRouteUpdate(to, from, next) {
    store
      .dispatch('getOffer', to.params.token)
      .then(() => {
        next()
      })
      .catch(() => {
        next()
      })
  },
  methods: {
    handleNextStep() {
      if (this.currentView === 'questions') {
        this.validateQuestionsStep()
        return

      } else if(this.currentView === 'submition') {
        // submit
      } else {
        this.setCurrentView('questions')
      }
    },
    handlePrevStep() {
      if (this.currentView === 'submition') {
        this.setCurrentView('questions')
      } else if(this.currentView === 'questions') {
        this.setCurrentView('marketcomparison')        
      } else {
        this.setCurrentView('marketcomparison')
      }
    },
    onQuestionsWarning(status) {
      const answerLength = Object.keys(this.inputs.answers).length
      const formLength = Object.keys(this.inputs.form).length
      if (answerLength + formLength < this.filteredQuestions.length) {
        this.isQuestionsWarning = status
      }
    },
    updateSepaInputs(sepa) {
      this.inputs.sepa.company = sepa.company
      this.inputs.sepa.firstname = sepa.firstname
      this.inputs.sepa.lastname = sepa.lastname
      this.inputs.sepa.street = sepa.street
      this.inputs.sepa.postal = sepa.postal
      this.inputs.sepa.city = sepa.city
      this.inputs.sepa.iban = sepa.iban
    },
    customerDataChange(val) {
      this.offer.company = val.company
      this.offer.firstname = val.firstname
      this.offer.lastname = val.lastname
      this.offer.title = val.title
      this.offer.salutation = val.salutation
      this.offer.email = val.email
      this.offer.phone = val.phone
      this.offer.street = val.street
      this.offer.city = val.city
      this.offer.postal = val.postal
    },
    getForm(question) {
      if (question.form && question.form.fields) {
        return question.form.fields
      } else if (
        question.form &&
        question.form[this.inputs.answers[`q-${question.id}`]]
      ) {
        return question.form[this.inputs.answers[`q-${question.id}`]]
      }
    },
    getInsurerName(insurerKey) {
      const insurer = this.$store.state.checkout.application.insurers.find(
        (i) => i.key === insurerKey
      )
      return insurer ? insurer.label : ''
    },
    transformDeductible(deductible) {
      if (this.offer.campaign === 'victor-fvdz') {
        if (deductible === 500) {
          return 250
        } else if (deductible === 1000) {
          return 500
        } else if (deductible === 2500) {
          return 1250
        } else if (deductible === 5000) {
          return 2500
        }
      }

      return deductible
    },
    insurerCountChanged(insurerCount) {
      if (insurerCount > 4) {
        this.containerMode = 'fluid'
      } else {
        this.containerMode = 'static'
      }
    },
    getValidationOpts(minLen) {
      let validation = ['required']

      if (this.specialOffer) {
        if (minLen) {
          validation.push('min:22')
        }

        return validation.join('|')
      }

      return ''
    },

    chekingBrokerAnswers(totalNumbers) {
      let getTotalAnswers = 0

      if (this.offer.answers != undefined) {
        for (const key in this.offer.answers) {
          getTotalAnswers++
        }
      }
      if (
        totalNumbers == getTotalAnswers &&
        this.offer.agreedTermsConditions == true
      )
        this.checkAnswers = true
    },

    getQuestions() {
      this.chekingBrokerAnswers(
        this.$store.getters.filteredQuestions(this.offer.insurer).length
      )
      if (this.insurerSelected) {
        this.filteredQuestions = this.$store.getters.filteredQuestions(
          this.offer.insurer
        )
        this.filteredQuestions.forEach((question) => {
          if (question.answers && question.answers.length === 1) {
            this.$set(
              this.inputs.answers,
              `q-${question.id}`,
              question.answers[0].value
            )
          }

          if (question.form) {
            this.inputs.form = this.inputs.form || {}

            if (!this.inputs.form[question.id]) {
              this.$set(this.inputs.form, question.id, {})
            }

            Object.keys(question.form).forEach((answer) => {
              if (answer === 'style') return
              const fields = question.form[answer] || question.form.fields

              if (fields && fields.length) {
                fields.forEach((field) => {
                  if (!this.inputs.form[question.id][field.name]) {
                    switch (field.type) {
                      case 'address-repeater':
                      case 'subsidiary-repeater':
                        this.$set(this.inputs.form[question.id], field.name, [])
                        break
                      default:
                        this.$set(
                          this.inputs.form[question.id],
                          field.name,
                          undefined
                        )
                        break
                    }
                  }
                })
              }
            })
          }
        })
      } else {
        this.offer.selection.sort().forEach((insurerKey) => {
          //check if insurer has a price object returned from API, therefore is currently available
          if(!this.offer.prices[insurerKey]) {
            console.error(`no prices element for ${insurerKey}, hiding the questions`)
            return
          }

          this.allQuestions.push({
            insurer: this.getInsurerName(insurerKey),
            questions: this.$store.getters.filteredQuestions(insurerKey)
          })
        })
      }
    },

    send() {
      let patch
      let errorEl
      const self = this

      this.$validator.validateAll().then((result) => {
        if (result) {
          patch = {
            company: self.offer.company || '',
            firstname: self.offer.firstname || '',
            lastname: self.offer.lastname || '',
            title: self.offer.title || '',
            salutation: self.offer.salutation || '',
            email: self.offer.email || '',
            phone: self.offer.phone || '',
            street: self.offer.street || '',
            city: self.offer.city || '',
            postal: self.offer.postal || '',
            selectedModules: self.offer.selectedModules,
            answers: self.inputs.answers,
            form: self.inputs.form,
            agreedTermsConditions: self.agreedTermsConditions,
            sepa: self.inputs.sepa,
            paymentMethod: self.inputs.paymentMethod,
            startDate: self.inputs.startDate,
            dueDate: self.inputs.dueDate,
            billingDate: self.inputs.billingDate,
            additionalNotes: self.inputs.additionalNotes || ''
          }

          if (self.specialOffer) {
            patch.paymentMethod = 'directDebit'
            patch.sepa = this.inputs.sepa
          }

          self.$store
            .dispatch('patchOffer', {
              id: self.offer.id,
              patch
            })
            .then(() => {
              this.success = true
            })
            .catch(() => {
              this.$toasted.show(
                'Die Daten konnten nicht gespeichert werden.',
                {
                  theme: 'primary',
                  position: 'bottom-left',
                  duration: 4000
                }
              )
            })
        } else {
          errorEl = document.getElementById(
            self.$validator.errors.items[0].field
          )
          const errorRule = this.$validator.errors.items[0].rule

          if (errorEl) {
            errorEl.scrollIntoView({ block: "center" })
            errorEl.focus()

            if((errorEl.name === 'companyName' || errorEl.name === 'sepaCompanyName') && errorRule === 'max') {
              // handles when user types more than 60 chars at company input
              this.$Simplert.open({
                type: 'error',
                message: 'Aufgrund Platzmangels in Police und Rechnung kann der Name des Versicherungsnehmers bei mehr als 60 Zeichen bei der automatischen Dokumentenerstellung nicht vollständig abgebildet werden, sondern wird nach dem 60. Zeichen abgeschnitten. Sie können den Vertrag dennoch problemlos einreichen.',
                customCloseBtnText: 'OK',
                customCloseBtnClass: 'btn btn-primary ',
              })
            }
          }
        }
      })
      
    },
    sendFurtherQuestions(form) {
      // close the modal
      this.showRuckfrageModal = false
      // prepare the data for store
      const data = {
        offerId: this.offer.id,
        action: 'furtherQuestion',
        senderQuestion: form.questionText,
        senderName: form.senderName,
        senderPhone: form.senderPhone,
        senderEmail: form.senderEmail
      }

      this.$store
        .dispatch('sendFurtherQuestions', data)
        .then(() => {
          this.$toasted.show('Ihr Makler wurde informiert.', {
            theme: 'bubble',
            position: 'bottom-left',
            duration: 4000
          })
        })
        .catch(() => {
          this.$toasted.show(
            'Leider konnte Ihr Makler nicht informiert werden, es ist ein Fehler aufgetreten.',
            {
              theme: 'bubble',
              position: 'bottom-left',
              duration: 4000
            }
          )
        })
    },
    getSalutation() {
      if (!this.offer) {
        return ''
      }

      const lastname = this.offer.lastname
      const salutation = this.offer.salutation

      if (this.offer.salutationOverwrite) {
        return this.offer.salutationOverwrite
      } else {
        if (!this.offer.lastname) {
          return `Sehr geehrte Damen und Herren,`
        } else if (salutation === 'Herr') {
          return `Sehr geehrter Herr ${lastname},`
        } else {
          return `Sehr geehrte Frau ${lastname},`
        }
      }
    },
    answerQuestion(questionId, answer) {
      this.$set(this.inputs.answers, 'q-' + questionId, answer)
      this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs)
      this.getQuestions()

      const question = this.filteredQuestions?.find(el => el.id === questionId)

      if (question.deselectModule && question?.deselectModule?.[answer]?.length) {

        question.deselectModule[answer].forEach((module) => {
          this.deselectModule(this.offer.insurer, module)
        });
      }

      this.$store
        .dispatch('CALCULATE_CYBER_PRICES', { withAnswers: true })
        .then((prices) => {
          this.offer.prices = prices
        })
    },
    deselectModule(insurer, module) {
      const moduleIndex = this.offer.selectedModules[insurer].indexOf(module);

      if (moduleIndex > -1) {
        this.offer.selectedModules[insurer].splice(moduleIndex, 1);
      }
    },
    isSelected(questionId, answer) {
      return this.inputs.answers['q-' + questionId] === answer
    },
    getInsurerLogoPath(insurer) {
      return insurer && insurer.logo
        ? insurer.logo
        : insurer.logoUrl
        ? insurer.logoUrl
        : ''
    },
    getReadableProperty(insurer, propertyKey) {
      return insurer &&
        insurer.properties &&
        insurer.properties[propertyKey] &&
        (insurer.properties[propertyKey].state === 'available' || insurer.properties[propertyKey].state === 'info')
        ? 'Ja'
        : 'Nein'
    },
    openToolTip(_content) {
      this.tooltipContent = _content
      this.showModal = true
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return
      }

      return {
        'is-danger': this.errors.has(fieldName),
        touched: this.fields[fieldName].dirty
      }
    },
    spSelected() {
      if (this.selectedServiceProvider) {
        this.formData = this.selectedServiceProvider
      }
    },
    removeServiceProvider(questionId, fieldName, index) {
      this.$delete(this.inputs.form[questionId][fieldName], index)
    },
    addServiceProvider(questionId, fieldName) {
      let { street, city, country } = this.formData

      if (typeof this.selectedServiceProvider === 'string') {
        this.inputs.form[questionId][fieldName].push({
          name: this.selectedServiceProvider,
          street,
          city,
          country
        })
      } else {
        this.inputs.form[questionId][fieldName].push({
          name: this.selectedServiceProvider.name,
          street,
          city,
          country
        })
      }

      this.formData = {
        name: '',
        street: '',
        city: '',
        country: ''
      }
      this.serviceProviderString = ''
      this.selectedServiceProvider = null
    },
    addSubsidiary(questionId, fieldName) {
      let { country, revenue } = this.formSubsidiaryData

      if (country && revenue) {
        this.inputs.form[questionId][fieldName].push({
          country,
          revenue
        })

        this.formSubsidiaryData = {
          country: '',
          revenue: ''
        }
      }
    },
    getAnswers(question) {
      return question.answers
        ? question.answers
        : [
            { label: 'Ja', value: 'yes' },
            { label: 'Nein', value: 'no' }
          ]
    },
    scrollHandler() {
      const doc = document.documentElement
      const left =
        (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)

      if (left > 50) {
        this.showShadow = true
      } else {
        this.showShadow = false
      }
    },
    setCurrentView(view) {
      this.currentView = view
    },
    validateQuestionsStep() {
      const self = this
        let errorEl
        const answerLength = Object.keys(this.inputs.answers).length

        // Why do we need this?
        const formLength = Object.keys(this.inputs.form).length
        
        if (!this.legalNoticeAccepted) {
          errorEl = document.getElementById('legalNoticeWarning')
          errorEl.scrollIntoView({ block: "center" })
          this.showWarningModal = true
          return
        } else if (answerLength + formLength < this.filteredQuestions.length) {
          this.showWarningModal = true
          return
        }

        this.$validator.validateAll().then(result => {
          if (result) {
            this.setCurrentView('submition')
            window.scrollTo(0,0)
          } else {
            
            errorEl = document.getElementById(
              self.$validator.errors.items[0].field
            )

            if (errorEl) {
              errorEl.scrollIntoView({ block: "center" })
              // window.scrollBy(0, -200)
              errorEl.focus()
            }
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/tooltip.scss';
@import '@/styles/print/print.scss';


.dropdown-menu {
  right: 0;
}

.dropdown {
  &.open {
    .dropdown-menu {
      display: block;
    }
  }
}
.sticky-header .desktop-controls.cl1 .form-group.row.d-print-none.mb-3 {
  display: none;
}
</style>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/form.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/styles/print/offer.scss';

.offer {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  // margin-right: -16px; // hardfix
  position: relative;
  padding-top: 2rem;
  .offer-frame {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    border: 1px solid #417e9b;
    border-radius: 0.25rem;
    max-width: 1370px;
    margin: 0 auto;

    @media print {
      display: none;
    }

    @media screen and (max-width: 1440px)  {
      left: 0.39rem;
      right: 0.39rem;
    }

    @include media-breakpoint-down(lg) {
    }

    @include media-breakpoint-down(md) {
      border-left: none;
      border-right: none;
      border-radius: 0;
      left: 0;
      right: 0;
      // display: none;
    }

    &__title  {
      position: absolute;
      top: -0.75rem;
      right: 200px;
      color: #417e9b;
      background: #fff;
      padding: 0 0.5rem;
      max-width: 1370px;
      margin: 0 auto;
      font-weight: bold;
      font-size: 1.1rem;

      &--left {
        display: inline-flex;
        position: sticky;
        left: 0;
        top: 200px;
        right: auto;
        left: 0;
        transform: rotate(-90deg);
        transform-origin: 9% 100%;
        z-index: 999;
        font-size: 1.1rem;

        @media screen and (max-width: 1399px) {
          left: 11px;
        }

        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }
    }
  }

  ::v-deep .filter-options {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      position: sticky;
      left: 0.5rem;
      width: fit-content;
    }
  }
  ::v-deep .filter {
    min-width: 200px;
    background: #417E9B;
    color: #fff;
    border-radius: 0 0 0.25rem 0.25rem;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    @media print {
      background: transparent;
      color: #000;
    }
  }

  .next-step {
    border: 2px solid $primary;
    box-shadow: none;
    background: #fff;

    &:hover {
      background: $secondary;
      border-color: $secondary;
      color: #fff;
    }
  }
  ::v-deep .filter-companies {
    width: 100%;

    @include media-breakpoint-down(md) {
      // width: auto;
    }
  }

  ::v-deep .filter-row-wrapper {
    position: sticky;
    top: -8px;
    background: #fff;
    transition: 0.3s;
    z-index: 99;

    @include media-breakpoint-down(xs) {
      .insurer__image--has-banner {
        margin-bottom: 0;
      }

      .badge {
        display: none;
      }

      .wrap-helper {
        width: fit-content;
        min-width: 100%;
      }
    }

    // @include media-breakpoint-between(sm, md) {
    //   overflow: auto;
    // }
  }

  ::v-deep div.filter-row-wrapper.filter-row-wrapper--sticky {
    top: 0;
    // why do we need this margin-top?
    // margin-top: 450px;
    margin-top: 0;

    .insurer__image {
      margin-left: 0.5rem;
    }
    
    .insured-sum-row {
      display: none;
    }

    .insured-sum-options {
      display: none;
    }

    .prices .price.insurer-recommended {
      .recommendation-frame {
        top: -2rem;
        bottom: 0;
      }
      // display: none;
    }
    
    .statistics-row--price-perform-winner {
      .prices .price.insurer-recommended {
        .recommendation-frame {
          bottom: -0.5rem;
        }
      }
    }
  }

  ::v-deep .filter-row {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }

  ::v-deep .th-cell {
    @include media-breakpoint-down(xs) {
      padding: 0.25rem 1.5rem;
      line-height: 1.2;
    }
  }

  ::v-deep .title-col {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  
  ::v-deep .insurer-price {
    @include media-breakpoint-down(xs) {
      &__title {
        display: block;
        margin-bottom: 0.25rem;
      }
      
      &__subtitle {
        display: block;
        margin-left: 0;
        font-size: 0.75rem;
      }
    }
  }

  ::v-deep .tb-cell-title-wrap.sticky-col {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  ::v-deep .acc-child__header {
    @include media-breakpoint-down(xs) {
      flex-direction: column;

      .trigger-wrap {
        width: 100%;
        max-width: calc(100vw - 2.6rem);
        min-width: 200px;
        left: 0;
      }

      .nested-lvl-3 .trigger-wrap {
        left: 0;
        width: 100%;
        max-width: calc(100vw - 2.6rem);
      }
    }
  }

  ::v-deep .insurers {
    width: 100%;
    margin-left: 0.25px;

    .insurer {
      gap: 1rem;

      &__badge {
        position: static;
      }
    }

    .insurer__image {
      // width: 100%;
      height: auto;

      img {
        min-width: auto;
        max-width: 100px;
        max-height: 60px;
        height: auto;

        @include media-breakpoint-down(md) {
          max-height: 50px;
          max-width: 65px;
        }

        @include media-breakpoint-down(xs) {
          max-height: 28px;
        }
      }
    }

    .insurer--1,
    .price--1 {
      width: 100%;
      min-width: 140px;
    }
    .insurer--2,
    .price--2 {
      width: 50%;
      min-width: 140px;
    }
    .insurer--3,
    .price--3 {
      width: 33%;
      min-width: 140px;
    }
    .insurer--4,
    .price--4 {
      width: 25%;
      min-width: 140px;
    }

    // @include media-breakpoint-between(sm, md) {
    //   .insurer--1,
    //   .insurer--2,
    //   .insurer--3,
    //   .insurer--4 {
    //     min-width: 131px;
    //   }
    // }
  }

  ::v-deep .insurers .insurer.company.company--offer {
    display: flex;
    justify-content: center;
    
    @include media-breakpoint-down(lg) {
      padding: 1rem;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 1rem;
      justify-content: flex-end;
    }
  }

  ::v-deep .prices {
    width: 100%;

    @include media-breakpoint-down(md) {
      // width: auto;
    }

    .price--1 {
      width: 100%;
      min-width: 140px;
    }
    .price--2 {
      width: 50%;
      min-width: 140px;
    }
    .price--3 {
      width: 33%;
      min-width: 140px;
    }
    .price--4 {
      width: 25%;
      min-width: 140px;
    }
    
    // @include media-breakpoint-between(sm, md) {
    //   .price--1,
    //   .price--2,
    //   .price--3,
    //   .price--4 {
    //     min-width: 131px;
    //   }
    // }
  }

  ::v-deep .acc-table {
    min-width: fit-content;

     &__header {
      @include media-breakpoint-down(xs) {
        max-width: calc(100vw - 2.6rem);
      }
     }

    .acc-child--nested-lvl-3 .acc-child__header {
      position: static;
      max-width: 100%;
    }

    .precentage-row,
    .tb-row {
      width: 100%;

      @include media-breakpoint-down(md) {
        // width: auto;
      }

      @include media-breakpoint-down(xs) {
        width: fit-content;
        min-width: 100%;

        .precentage-col {
          height: 40px;
        }
      }

      .precentage-col--1,
      .tb-cell--1 {
        width: 100%;
        min-width: 140px;
      }
      .precentage-col--2,
      .tb-cell--2 {
        width: 50%;
        min-width: 140px;
      }
      .precentage-col--3,
      .tb-cell--3 {
        width: 33%;
        min-width: 140px;
      }
      .precentage-col--4,
      .tb-cell--4 {
        width: 25%;
        min-width: 140px;
      }

      // @include media-breakpoint-between(sm, md) {
      //   .precentage-col--1, .tb-cell--1,
      //   .precentage-col--2, .tb-cell--2,
      //   .precentage-col--3, .tb-cell--3,
      //   .precentage-col--4, .tb-cell--4 {
      //     min-width: 131px;
      //   }
      // }
    }

    .precentage-row {
      @include media-breakpoint-down(xs) {
        height: fit-content;
        width: 99.8vw;
        overflow: auto;
        overflow-y: hidden;
        padding-left: 1.5rem;
        padding-right: 1.125rem;
        transform: translateX(-1.5rem);
        margin-right: -1.5rem;
      }
    }

    .precentage-score-0 {      
      @include media-breakpoint-down(xs) {
        //display: none;
      }
    }

    &__body, .acc-child, .accordion-list  {
      min-width: fit-content;
    }
  }

  .tab-content {
    width: 100%;
  }

  ::v-deep .tb-cell > *:not(.recommendation-frame) {
    z-index: 1;
  }

  ::v-deep .statistics-row {
    .tb-cell {
      height: auto;

      @include media-breakpoint-down(xs) {
        height: 70px;
      }
    }
  }

  ::v-deep .sticky-col.details {
    @include media-breakpoint-down(sm) {
      // width: 100%;
      // min-width: auto;
    }
  }

  ::v-deep .file-list {
    font-size: 0.85rem;
  }

  .container-xxl {
    ::v-deep .the-table,
    ::v-deep .the-table .form-wrapper {
      width: 100%;
    }
  }
  
  .tab-views {
    padding-top: 1.5rem;
  }

  &--insurer-selected {

    ::v-deep .statistics-row {
      &--price-perform-winner {
       & .prices .price.insurer-recommended .recommendation-frame {
        top: -2rem
       }
        
      }
    }
    ::v-deep .info__broker-logo {
      @include media-breakpoint-down(md) {
        height: 210px;
      }
    }

    &.offer-view-marketcomparison {
      .offer-header {
        z-index: 1005;
      }

      .toolbar {
        @include media-breakpoint-down(lg) {
          height: fit-content;
        }
      }
    }

    .offer-header {
      position: relative;
      z-index: 99;
    }
    .title-tabs-row {
      position: absolute;
      width: 99%;

      @include media-breakpoint-down(md) {
        position: static;
        width: auto;
      }
    }

    .title-tabs {
      display: grid;
      grid-template-rows: 48px;

      @include media-breakpoint-down(md) {
        margin-top: 0;
        display: flex;
      }
    }

    .toolbar {
      .btn + .btn {
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }

      @include media-breakpoint-down(lg) {
        order: 1;
        margin-top: 56px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
        background: #fff;
        gap: 0.5rem;
      }
    }

    .steps {
      position: absolute;
      width: calc(100% - 426px - 19px);
      left: 426px;
      
      @include media-breakpoint-down(lg) {
        width: calc(100% - 32px);
        left: 1rem;
        top: 0;
      }
    }

    .tab-views {
      padding-top: 72px;

      @include media-breakpoint-down(md) {
        padding-top: 1.5rem;
      }
    }
    .legal-wrapper {
      padding-left: 406px;

      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }

    .documents {
      padding-left: 420px;

      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }

    ::v-deep .insured-sum-row {
      display: none;
    }

    .container--content {
      margin-top: 3.5rem;

      @include media-breakpoint-down(md) {
        margin-top: 0.5rem;
      }

      @include media-breakpoint-down(xs) {
        margin-top: 0;
      }

      @media print {
        margin-top: 0;
      }
    }

    ::v-deep .filter {
      display: none;

      &:not(.filter--mobile) {
        @include media-breakpoint-down(sm) {
          display: block;
          background: #fff;
        }

        @include media-breakpoint-down(xs) {
          display: none;
        }

        @media print {
          display: block;
        }

        .details {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    ::v-deep .filter-companies {
      height: 242px;

      @include media-breakpoint-down(md) {
        height: 150px;
      }

      @include media-breakpoint-down(xs) {
        height: 60px;
      }

      @media print {
        height: auto;
      }
    }

    ::v-deep .filter-row-wrapper--sticky .filter-companies {
      height: 100%;

      .insurer {
        &::before {
          top: 15px;
          left: 1rem;
          transform: translateX(0);

          @include media-breakpoint-down(lg) {
            left: 0.5rem;
          }
        }
      }
    }

    ::v-deep .insurer {
      &--recommended {
        border-top: 2px solid #185470;
        border-right: 2px solid #185470;
        border-left: 2px solid #185470;
        border-radius: 0.25rem 0.25rem 0 0;
        z-index: 1;

        &::before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNS42MjUgMTIuNzYyLTMuODgtMy44ODEgMS43NjgtMS43NjkgMi4xMTIgMi4xMTlMMTEuOCAzLjA1bDEuNzcgMS43NjktNy45NDUgNy45NDNaIiBmaWxsPSIjNTFCQzdFIi8+PHBhdGggZD0ibTUuNjI1IDEyLjc2Mi0zLjg4LTMuODgxIDEuNzY4LTEuNzY5IDIuMTEyIDIuMTE5TDExLjggMy4wNWwxLjc3IDEuNzY5LTcuOTQ1IDcuOTQzWiIgZmlsbD0iIzUxQkM3RSIvPjwvc3ZnPg==");
          background-repeat: no-repeat;
          background-position: 45% 50%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #185470;
          opacity: 0.1;
        }

        .insurer__image {
          z-index: 1;
        }
      }
    }

    ::v-deep .prices .price.insurer-recommended,
    ::v-deep .precentage.insurer-recommended,
    ::v-deep .tb-cell.insurer-recommended,
    ::v-deep .insured-sum-options__insurer.insurer-recommended {
      position: relative;

      .recommendation-frame {
        display: block;
        position: absolute;
        top: -1rem;
        bottom: -0.5rem;
        left: 0;
        width: 100%;
        background: rgba(#185470, 0.1);
        border-right: 2px solid #185470;
        border-left: 2px solid #185470;
      }
    }

    ::v-deep .filter-row-wrapper:not(.filter-row-wrapper--sticky) .statistics-table.score-visible {
       .prices .price.insurer-recommended {
        .recommendation-frame {
          bottom: -1.5rem;
        }
       }
    }

    ::v-deep .filter-row-wrapper--sticky {
      .prices .price.insurer-recommended {
        .recommendation-frame {
          bottom: -0.5rem;
        }
       }
    }

    ::v-deep .precentage.insurer-recommended {
      .recommendation-frame {
        top: -0.5rem;
        bottom: -0.5rem;
      }
    }

    ::v-deep .tb-cell.insurer-recommended {
      .recommendation-frame {
        top: 0rem;
        bottom: -0.5rem;
      }
    }

    ::v-deep .insured-sum-options__insurer.insurer-recommended {
      .recommendation-frame {
        top: 0;
        bottom: 0;
      }
    }
  }
}

.offer:not(.offer--insurer-selected) {
  .insured-sum-options-show {
    ::v-deep .company.insurer {
      background: #cfe5ef;
      border-radius: 0.25rem 0.25rem 0 0;
    }
  }
}

.row-fit {
  min-width: fit-content;
  page-break-before: always;

  @include media-breakpoint-down(md) {
    // width: 100%;
    // overflow: scroll;
  }
}

::v-deep .sticky-col {
  position: static;
}

::v-deep .nav-btns {
  display: none;
}

.header-outer {
  margin-bottom: 2rem;
  box-shadow: 0px 8px 8px rgba(88, 89, 91, 0.1);
}

.page-break-before {
  page-break-before: always;
}

.page-break-after {
  page-break-after: always;
}

//
::v-deep thead {
  page-break-after: avoid;
  break-after: avoid;
}
::v-deep .prop-body {
  // page-break-before: avoid;
  // break-before: avoid;
  // page-break-inside: avoid;
  // break-inside: avoid;
}
::v-deep .group-table {  
  // page-break-inside: avoid;
  // break-inside: avoid;
}
// 

h1 {
  font-size: 1.4rem;
  font-weight: bold;
}

h2 {
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

::v-deep h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.35rem;
  }
}
::v-deep h3 {
  font-size: 1.35rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}

.form-group {
  label {
    font-weight: bold;
  }

  &.is-danger {
    label {
      color: $red;
    }

    input,
    textarea,
    select {
      color: $red;
      border: 1px solid $red;
    }
  }
}

.custom-control {
  &.is-danger {
    label {
      &:before {
        border: 1px solid $red;
      }

      strong:first-of-type {
        color: $red;
      }
    }
  }
}

.btn {
  border: 0;
}

.btn-secondary {
  color: #fff;
  background: $secondary;
  border: 2px solid $secondary;

  &--active {
    color: #fff !important;
    background: $dark-blue !important;
    border: 2px solid $dark-blue !important;
  }
}

.btn-outline-secondary {
  background: none;
  border: 2px solid $secondary;
  color: $secondary;
}

.company {
  margin-bottom: 0;
  font-size: 1.3rem;
  color: #666;
}

.header {
  padding: 16px 0;
  //margin-bottom: 8px;
}

.offer {
  margin-bottom: 80px;
  padding-bottom: 4rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
    padding-bottom: 4rem;
  }
  
  ::v-deep .acc-table__header {
    max-width: calc(100vw - 2.5rem);

    .btn {
      background: #417E9B;
    }
  }

  ::v-deep .acc-child--nested-lvl-3 {
    .trigger-wrap.w-full {
      @include media-breakpoint-between(sm, md) {
        max-width: calc(100vw - 2.5rem);
      }
    }
  }
  
  ::v-deep #step_form {
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 44px);
    }
  }

  ::v-deep .progress-circle {    
    @include media-breakpoint-down(xs) {
      transform: scale(0.5);
    }
  }

  ::v-deep .acc-table__header {
    left: 0;

    @include media-breakpoint-between(sm, md) {
      left: 0.5rem;
    }
  }

  ::v-deep .accordion-list {    
    @include media-breakpoint-down(xs) {
      transform: translateX(-1.5rem);
      padding-left: 1.5rem;
      margin-right: -1.5rem;
      overflow: auto;
      width: 100vw;
    }
  }

  .submition-view {
    padding-left: 412px;
    margin-top: 0.25rem;

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
}

.circle-icon {
  width: 18px;
  cursor: pointer;

  &__tooltip {
    position: absolute;
    z-index: $z-index-tooltip;
    top: calc(50% - 15px);
    left: calc(50% + 10px);
  }

  &--with-tooltip {
    cursor: pointer;
  }

  &--warning {
    position: absolute;
    right: 9px;
    top: -22px;
  }
}


.question {
  padding: 16px;
  display: inline-block;
  margin-right: 10px;
  min-height: 220px;
  width: 100%;
  &:nth-child(even), &.manualbg {
    background: #f5f5f5;
  }

  .button {
    margin-right: 0;
  }

  &__list {
    margin-bottom: 0;

    li {
      position: relative;
      padding-right: 24px;
    }
    &--subtext {
      margin-left: 30px;
      list-style-type: circle;
    }
  }

  &__text-wrapper + &__answers-wrapper {
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
  }

  &__text {
    position: relative;
  }

  &__answers {
    .btn {
      margin-bottom: 8px;
      min-width: 107px;
      border-radius: 8px;
      box-shadow: none;
    }
    .btn:not(.btn-primary) {
      background: #ddd;
      outline-color: #ddd;
    }
  }
}

.question__answers {
  .risknote {
    color: $redTone;
  }
}

.toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .btn {
    font-size: 1rem;
    height: 48px;
    width: 100%;
    padding: 0.75rem;
  }

  .btn + .btn {
    margin-top: 8px;
    margin-left: 0;

    @include media-breakpoint-up(lg) {
      margin-left: 0.5rem;
      margin-top: 0;
    }
  }

  &--ins-selected {
    flex-direction: column;
    width: 402px !important;
    min-width: 402px;
    background: $greyBlueDark;
    padding: 2rem 1rem;
    gap: 1rem 1.5rem;
    border-radius: 0.25rem;
    height: 299px;
    justify-content: flex-start;

    @include media-breakpoint-down(md) {
      height: fit-content;
    }

    @include media-breakpoint-down(md) {
      width: 100% !important;
      min-width: 100%;

      .btn + .btn {
        margin-top: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    .btn + .btn {
      margin-left: 0;
    }
  }
}

.alert {
  padding: 28px;
  background: #f0f0f0;
  border-color: #cecece;
}

.recommendation {
  font-weight: bold;
  color: $blue;
}

.contract-details {
  padding: 0;

  &__item {
    padding: 0px 28px;
    min-height: 200px;
    border-top: 0;
    flex: 1 auto;

    @include media-breakpoint-down(xs) {
      width: 100%
      // width: 33.333%;
    }

    // + .contract-details__item {
    //   @include media-breakpoint-down(xs) {
    //     border-left: 2px solid #fff;
    //   }
    // }

    &:first-child {
      margin-left: -28px;

      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }

      .contract-detail__header {
        margin-left: -28px;
        margin-right: -56px;

        @include media-breakpoint-down(xs) {
          margin-right: -28px;
        }
      }
    }

    &:nth-child(2) {
      .contract-detail__header {
        margin-left: -56px;
        margin-right: -56px;

        @include media-breakpoint-down(xs) {
          margin-left: -28px;
          margin-right: -28px;
        }
      }
    }

    &:last-child {
      margin-right: -28px;

      .contract-detail__header {
        margin-left: -56px;
        margin-right: -28px;

        @include media-breakpoint-down(xs) {
          margin-left: -28px;
          margin-right: -28px;
        }
      }
    }
  }
}

.detail-list {
  padding-left: 16px;
}

.contract-detail {
  &__header {
    padding: 8px 28px;
    margin-bottom: 16px;
    font-weight: bold;
    background: #9598a1;
    color: #fff;
  }

  &__content {
    flex: 1;
  }

  &__price {
    > div {
      &:first-child {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__logo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 auto;
      max-width: 125px;
    }
  }

  &__row {
    div {
      font-size: 0.9rem;
      width: 50%;

      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }
}

#formAgreement {
  margin-bottom: 45px;
  margin-left: 2px;

  .form-group {
    a {
      color: $blue;
    }
  }
}

.insurer-documents {
  display: flex;
  padding: 16px 0;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding: 16px 16px;
  }

  &:nth-child(even) {
    background: #f5f5f5;
  }

  &__logo {
    width: 30%;
    height: 135px;
    text-align: center;
    padding-top: 1rem;

    @include media-breakpoint-down(sm) {
      text-align: left;
      height: auto;
      margin-bottom: 1.5rem;
    }

    img {
      width: 100%;
      max-width: 120px;
    }
  }

  &__documents {
    width: 70%;
  }
}

.offer .container--content {
  position: relative;
  min-height: 500px;
  padding-bottom: 100px;

  @media print {
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    padding-top: 1rem;
  }
}

.offer-header {
  padding-top: 1.5rem;
}

.calc-container {
  position: static !important;
  min-height: 500px;
  width: auto;
  padding: 0 15px;
}

.sticky-shadow {
  display: none;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  width: 402px;
  height: 0;
  z-index: 20;

  &--show {
    display: block;
  }

  .shadow {
    height: 100vh;
    // height: 0;
    box-shadow: 13px 0 27px -2px rgba(50, 50, 93, 0.15);
    z-index: 50;
    pointer-events: none;
  }
}

.offer-bottom {
  position: relative;
  background: #fff;
  z-index: 21;
}

.form {
  background: $greyBlueDark;
  // padding: 2.5rem 2.5rem 0 0;
  border-radius: 0.25rem;

  @include media-breakpoint-down(sm) {
    padding: 2.5rem 1.75rem;
  }
}

::v-deep .form-divider {
  width: 100%;
  height: 2px;
  background: #dfe7ea;
  margin: 2.5rem 0;

  &--v2 {
    margin-bottom: 0;
  }
}

.payment-method {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem 4rem;
}

::v-deep .radio-title {
  font-weight: bold;
  margin-right: 4rem;
}

::v-deep .cd-radio + .cd-radio {
  margin-left: 40px;
}

::v-deep .form-row + .form-row {
  margin-top: 2rem;
}

::v-deep .info {
  position: relative;
  margin-bottom: 1rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 0.25rem;
  }
}

.payment-type {
  margin-bottom: 2rem;
}

.submit-btn {
  margin-top: 2.5rem;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.ins-questions {
   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  & + & {
    margin-top: 3rem;
  }
}

.comparison-wrapper {
  background: #fff;
}

@page {
  margin: 2rem 2rem 2.5cm;
}

.disclaimer-wrapper {
  min-width: fit-content;
}

.disclaimer {
  position: sticky;
  left: 0;
  max-width: calc(100vw - 1.25rem);

  & > div {
    margin-top: 1.5rem;

    @media print {
      margin-top: 0;
    }
  }
  
  @include media-breakpoint-down(md) {
    // max-width: 100%;
  }

  @include media-breakpoint-down(xs) {
    max-width: calc(100vw - 2.85rem);
  }
}

.title-tabs {
  display: flex;
  gap: 0.5rem;
  width: 100% !important;

  @include media-breakpoint-down(md) {
    margin-top: -106px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 0;
    flex-direction: column-reverse;
  }

  .title {
    width: 402px;
    min-width: 402px;
    height: fit-content;
    margin-top: auto;

    @include media-breakpoint-down(sm) {
      width: 290px;
      min-width: 290px;
    }

    @include media-breakpoint-down(xs) {
      width: 100%
    }

    &:not(.btn) {
      background: #185470;
      border-radius: 0.25rem 0.25rem 0 0;
      padding: 1rem 0 1.5rem;
      color: #fff;
      font-size: 1.1875rem;
      font-weight: 800;
      line-height: 1.5rem;
      text-align: center;
      
      @include media-breakpoint-down(md) {
        margin-bottom: -1rem;
      }
    }

    &.btn {

    }
  }

  .toolbar {
    width: 100%;
  }
}

::v-deep .the-table {
  
  & > thead {
    @media screen {
      display: none;
    }
  }
}

.download-pdf {
  ::v-deep svg > g {
    stroke: #fff;
  }
}

.next-step[disabled] {
  opacity: 0.4;
}

.form-top {
  padding: 2.5rem 2.5rem 0;
  min-height: 244px;

  @include media-breakpoint-down(md) {
    padding: 2.5rem 1.5rem 0 1.5rem;
  }
}

.form-main {
  margin-left: -412px;
  background: #ebf0f2;
  padding: 2.5rem 1.5rem;
  border-radius: 0.25rem;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  }
}
</style>
